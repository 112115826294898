import React, { useState } from 'react';
import { FaCheckCircle, FaTimesCircle, FaCoins } from 'react-icons/fa';
import './PreviousBets.css';

const betsData = [
  { id: 1, date: '23.02', type: 'Singles', players: 1, invested: '100$', return: '185$', result: '3/5' },
  { id: 2, date: '23.02', type: 'Parlay', players: 4, invested: '100$', return: '621$', result: 'win' },
  { id: 3, date: '23.02', type: 'Parlay', players: 3, invested: '100$', return: '450$', result: 'loss' },
  { id: 4, date: '23.02', type: 'Singles', players: 5, invested: '100$', return: '345$', result: '1/5' },
  { id: 5, date: '24.02', type: 'Parlay', players: 2, invested: '150$', return: '780$', result: 'win' },
  { id: 6, date: '24.02', type: 'Singles', players: 4, invested: '200$', return: '500$', result: '4/5' },
  { id: 7, date: '25.02', type: 'Parlay', players: 6, invested: '100$', return: '950$', result: 'loss' },
  { id: 8, date: '25.02', type: 'Singles', players: 5, invested: '100$', return: '345$', result: '2/5' },
];

// Generate random percentages around 53% ± 5%
const getRandomPercentage = () => (Math.random() * 10 + 48).toFixed(2);

const playerDetails = {
  1: [
    { player: 'S. Curry', team: 'GSW', vs: 'DEN', prop: 'PTS', odds: '1.85', line: '25.5', pick: 'Over', modelPick: 'Under', result: '21' },
  ],
  2: [
    { player: 'K. Durant', team: 'PHX', vs: 'LAL', prop: 'PTS', odds: '1.9', line: '30.5', pick: 'Over', modelPick: 'Over', result: '31' },
    { player: 'L. James', team: 'LAL', vs: 'MEM', prop: 'PTS', odds: '1.85', line: '24.5', pick: 'Under', modelPick: 'Under', result: '22' },
    { player: 'J. Harden', team: 'PHI', vs: 'BOS', prop: 'AST', odds: '1.75', line: '8.5', pick: 'Over', modelPick: 'Over', result: '10' },
  ],
  // Add more details for other bets
};

const PreviousBets = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const getResultIcon = (result, line) => {
    return parseFloat(result) > parseFloat(line) ? (
      <FaCheckCircle className="icon green" />
    ) : (
      <FaTimesCircle className="icon red" />
    );
  };

  const getResultForBet = (bet) => {
    if (bet.type === 'Singles') {
      const resultValue = bet.result.split('/').map(Number);
      const iconColor = resultValue[0] >= resultValue[1] / 2 ? 'green' : 'red';
      return <FaCoins className={`icon coin ${iconColor}`} />;
    } else if (bet.type === 'Parlay') {
      return bet.result === 'win' ? <FaCheckCircle className="icon green" /> : <FaTimesCircle className="icon red" />;
    }
    return null;
  };

  return (
    <div className="previous-bets-container">
      <h2 className="table-title">Previous Bets</h2>
      <p className="table-subtitle">30 done this month</p>

      <table className="bets-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Players</th>
            <th>Invested</th>
            <th>Potential Return</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {betsData.map((bet, index) => (
            <React.Fragment key={bet.id}>
              <tr className="bet-row" onClick={() => handleRowClick(index)}>
                <td>{bet.date}</td>
                <td>{bet.type}</td>
                <td>{bet.players}</td>
                <td>{bet.invested}</td>
                <td>{bet.return}</td>
                <td className="ticket-result">
                    {bet.result}
                <span>{getResultForBet(bet)}</span>
                </td>

              </tr>
              {expandedRow === index && (
                <tr className="expanded-row">
                  <td colSpan="6">
                    <table className="player-details-table">
                      <thead>
                        <tr>
                          <th>Player</th>
                          <th>Team</th>
                          <th>VS</th>
                          <th>Prop</th>
                          <th>Odds</th> {/* Changed the order */}
                          <th>Line</th>
                          <th>Pick</th>
                          <th>Model Pick</th>
                          <th>Result</th>
                          <th>Model Probability</th>
                          <th>Model Accuracy</th>
                        </tr>
                      </thead>
                      <tbody>
            {playerDetails[bet.id]?.map((player, pIndex) => (
                <tr key={pIndex}>
                <td className="player-name">{player.player}</td> {/* Add className here */}
                <td>{player.team}</td>
                <td>{player.vs}</td>
                <td>{player.prop}</td>
                <td>{player.odds}</td>
                <td>{player.line}</td>
                <td>{player.pick}</td>
                <td>{player.modelPick}</td>
                <td className="result">
                    {player.result}
                    <span>{getResultIcon(player.result, player.line)}</span>
                </td>

                <td>{getRandomPercentage()}%</td> {/* Random model probability */}
                <td>{getRandomPercentage()}%</td> {/* Random model accuracy */}
                </tr>
            ))}
            </tbody>

                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PreviousBets;
