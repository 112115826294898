import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../api';
import MatchupPlayerInfo from './MatchupPlayerInfo';
import ProjectedStatsTable from './ProjectedStatsTable';
import DefenceVsPositionTable from './DefenceVsPositionTable';
import { tonightMatchups } from './data/TonightMatchups';
import { teamNameToAbbreviation, abbreviationToTeamName } from '../components/TeamDictionary';
import { teamsDict } from '../components/TeamInfo';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import './StartingLineup.css';
import { useKeycloak } from '../KeycloakProvider';
import playerInfoData from '../components/player_info.json';  // Import player_info.json

const defaultPlayer = {
  fgPercentage: 'N/A',
  home_or_away: 1,
  imageSrc: 'player_logos/default.png',
  mpg: '0.0',
  name: 'No Player',
  number: 0,
  opponentLogoSrc: 'team_logos/default.png',
  opponentName: 'N/A',
  playerId: 0,
  position: 'N/A',
  ppg: '0.0',
  apg: '0.0',
  rpg: '0.0',
};

const positions = ['C', 'PF', 'SF', 'SG', 'PG'];

const getStarters = (players) => players.slice(0, 5);

const shuffleArray = (array) => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

const assignPositions = (starters) => {
  const assigned = {
    C: null,
    PF: null,
    SF: null,
    SG: null,
    PG: null,
  };

  const availablePlayers = [...starters];

  const centers = availablePlayers.filter((player) => player.position === 'C');
  if (centers.length > 0) {
    assigned.C = centers[0];
    availablePlayers.splice(availablePlayers.indexOf(centers[0]), 1);
  } else {
    const pfOrF = availablePlayers.filter((player) => player.position === 'PF' || player.position === 'F');
    if (pfOrF.length > 0) {
      assigned.C = pfOrF[0];
      availablePlayers.splice(availablePlayers.indexOf(pfOrF[0]), 1);
    }
  }

  const powerForwards = availablePlayers.filter((player) => player.position === 'PF' || player.position === 'F');
  if (powerForwards.length > 0) {
    assigned.PF = powerForwards[0];
    availablePlayers.splice(availablePlayers.indexOf(powerForwards[0]), 1);
  }

  const smallForwards = availablePlayers.filter((player) => player.position === 'SF' || player.position === 'F');
  if (smallForwards.length > 0) {
    assigned.SF = smallForwards[0];
    availablePlayers.splice(availablePlayers.indexOf(smallForwards[0]), 1);
  }

  const guards = availablePlayers.filter((player) => player.position === 'SG' || player.position === 'PG' || player.position === 'G');
  const shuffledGuards = shuffleArray(guards);

  if (shuffledGuards.length > 0) {
    assigned.SG = shuffledGuards[0];
    availablePlayers.splice(availablePlayers.indexOf(shuffledGuards[0]), 1);
  }

  if (shuffledGuards.length > 1) {
    assigned.PG = shuffledGuards[1];
    availablePlayers.splice(availablePlayers.indexOf(shuffledGuards[1]), 1);
  }

  positions.forEach((position) => {
    if (!assigned[position]) {
      if (availablePlayers.length > 0) {
        assigned[position] = availablePlayers.shift();
      } else {
        assigned[position] = defaultPlayer;
      }
    }
  });

  return assigned;
};

const StartingLineup = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const [homePlayers, setHomePlayers] = useState([]);
  const [awayPlayers, setAwayPlayers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setSelectedPlayer } = usePlayerColors();
  const { token } = useKeycloak();
  const navigate = useNavigate();

  const fetchAndEnrichPlayers = async (teamAbbr, setPlayers) => {
    try {
      const teamName = abbreviationToTeamName[teamAbbr];
      const teamInfo = teamsDict[teamName];
      const team_id = teamInfo.team_id;
      const endpoint = `players_overall/get_team_players/?team_id=${team_id}`;

      const response = await fetchData(endpoint, token);
      const sortedPlayers = response.sort((a, b) => a.minutes_rank - b.minutes_rank);
      const starters = getStarters(sortedPlayers);

      const enrichedPlayers = starters.map((player) => {
        const playerJsonData = playerInfoData.find(
          (p) => p.player_id === player.player_id
        );

        return {
          ...player,
          playerId: player.player_id,
          name: player.player_name,
          imageSrc: `player_logos/${player.player_id}.png`,
          position: playerJsonData?.position || player.position,
          number: playerJsonData?.player_number || player.number,
          fgPercentage: player.fg_percentage
            ? `${(player.fg_percentage * 100).toFixed(1)}%`
            : 'N/A',
          mpg: player.mpg ? player.mpg.toFixed(1) : '0.0',
          valueChange: player.pct_change
            ? `${(player.pct_change * 100).toFixed(2)}%`
            : '0.00%',
          ppg: player.pts_mean_rolling_30_games
            ? player.pts_mean_rolling_30_games.toFixed(1)
            : '0.0',
          apg: player.ast_mean_rolling_30_games
            ? player.ast_mean_rolling_30_games.toFixed(1)
            : '0.0',
          rpg: player.reb_mean_rolling_30_games
            ? player.reb_mean_rolling_30_games.toFixed(1)
            : '0.0',
        };
      });

      setPlayers(enrichedPlayers);
    } catch (err) {
      setError(`Failed to fetch players for team ${teamAbbr}`);
      setPlayers([]);
    }
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      setLoading(true);
      await fetchAndEnrichPlayers(homeTeamAbbr, setHomePlayers);
      await fetchAndEnrichPlayers(awayTeamAbbr, setAwayPlayers);
      setLoading(false);
    };

    fetchPlayers();
  }, [homeTeamAbbr, awayTeamAbbr]);

  const homeAssigned = assignPositions(homePlayers);
  const awayAssigned = assignPositions(awayPlayers);

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player.playerId);
    navigate('/PlayerDashboard');
  };

  const handleTeamLogoClick = (players) => {
    const randomPlayer = players[Math.floor(Math.random() * players.length)];
    setSelectedPlayer(randomPlayer.playerId);
    navigate('/TeamDashboard');
  };

  return (
    <div className="starting-lineup-container">
      <h2 className="starting-lineup-title">Starting Line-up</h2>

      <div className="starting-lineup-content">
        <div className="starting-lineup-court">
          {/* Home Team */}
          <div className="starting-lineup-team starting-lineup-home-team">
            {positions.map((position) => (
              <div
                key={`home-${position}`}
                className={`starting-lineup-player-position starting-lineup-${position.toLowerCase()}`}
                onClick={() => handlePlayerClick(homeAssigned[position])}
              >
                <MatchupPlayerInfo
                  playerData={homeAssigned[position] || defaultPlayer}
                  color1={homeAssigned[position]?.teamColors?.[0] || teamsDict[abbreviationToTeamName[homeTeamAbbr]]?.colors?.[0]}
                  color2={homeAssigned[position]?.teamColors?.[1] || teamsDict[abbreviationToTeamName[homeTeamAbbr]]?.colors?.[1]}
                  color3={homeAssigned[position]?.teamColors?.[1] || teamsDict[abbreviationToTeamName[homeTeamAbbr]]?.colors?.[1]}
                />
              </div>
            ))}
          </div>

          {/* Court Center: Team Logos */}
          <div className="starting-lineup-court-center">
            <img
              src={`/${teamsDict[abbreviationToTeamName[homeTeamAbbr]].logo}`}
              alt={`${abbreviationToTeamName[homeTeamAbbr]} Logo`}
              className="starting-lineup-team-logo starting-lineup-home-logo"
              onClick={() => handleTeamLogoClick(homePlayers)}
            />
            <img
              src={`/${teamsDict[abbreviationToTeamName[awayTeamAbbr]].logo}`}
              alt={`${abbreviationToTeamName[awayTeamAbbr]} Logo`}
              className="starting-lineup-team-logo starting-lineup-away-logo"
              onClick={() => handleTeamLogoClick(awayPlayers)}
            />
          </div>

          {/* Away Team */}
          <div className="starting-lineup-team starting-lineup-away-team">
            {positions.map((position) => (
              <div
                key={`away-${position}`}
                className={`starting-lineup-player-position starting-lineup-${position.toLowerCase()}`}
                onClick={() => handlePlayerClick(awayAssigned[position])}
              >
                <MatchupPlayerInfo
                  playerData={awayAssigned[position] || defaultPlayer}
                  color1={awayAssigned[position]?.teamColors?.[0] || teamsDict[abbreviationToTeamName[awayTeamAbbr]]?.colors?.[0]}
                  color2={awayAssigned[position]?.teamColors?.[1] || teamsDict[abbreviationToTeamName[awayTeamAbbr]]?.colors?.[1]}
                  color3={awayAssigned[position]?.teamColors?.[1] || teamsDict[abbreviationToTeamName[awayTeamAbbr]]?.colors?.[1]}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Stats Tables */}
        <div className="starting-lineup-tables">
          <ProjectedStatsTable homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
          <DefenceVsPositionTable homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
        </div>
      </div>
    </div>
  );
};

export default StartingLineup;
