import { players } from '../../components/PlayerInfo';

const generatePropData = (playerPosition) => {
  const isGuard = ['PG', 'SG'].includes(playerPosition);
  const isForward = ['SF', 'PF'].includes(playerPosition);
  const isCenter = playerPosition === 'C';

  const pointsLine = isGuard ? 20.5 : (isForward ? 18.5 : 16.5);
  const reboundsLine = isCenter ? 10.5 : (isForward ? 7.5 : 4.5);
  const assistsLine = isGuard ? 6.5 : (isForward ? 4.5 : 2.5);

  const pointsPred = (Math.random() * 10 + pointsLine - 5).toFixed(1);
  const reboundsPred = (Math.random() * 5 + reboundsLine - 2.5).toFixed(1);
  const assistsPred = (Math.random() * 4 + assistsLine - 2).toFixed(1);

  return {
    points: {
      line: pointsLine,
      prediction: pointsPred,
      pick: Number(pointsPred) > pointsLine ? 'over' : 'under'
    },
    rebounds: {
      line: reboundsLine,
      prediction: reboundsPred,
      pick: Number(reboundsPred) > reboundsLine ? 'over' : 'under'
    },
    assists: {
      line: assistsLine,
      prediction: assistsPred,
      pick: Number(assistsPred) > assistsLine ? 'over' : 'under'
    }
  };
};

export const topPicksData = Object.values(players).map(player => {
  const propData = generatePropData(player.position);
  const probability = (Math.random() * 0.3 + 0.5).toFixed(2);
  const oddsValue = Math.floor(Math.random() * 50) + 100;
  const oddsSign = Math.random() > 0.5 ? '+' : '-';
  const odds = `${oddsSign}${oddsValue}`;

  return {
    playerName: player.name,
    team: player.teamName,
    position: player.position,
    props: propData,
    probability: probability,
    odds: odds,
    player_id: player.playerId
  };
});

// Log the first few entries to verify the structure
