import React, { useMemo } from 'react';
import './TopPerformingPlayers.css';
import fakeBetData from './data/fakeBetData'; // Import the fake data

const TopPerformingPlayers = ({ filters }) => {
  // Calculate top performing players based on filters
  const topPlayers = useMemo(() => {
    // Filter bets by the selected filters (date range, sportsbook, team)
    const filteredData = fakeBetData.filter((bet) => {
      const betDate = new Date(bet.date);
      const startDate = filters.dateRange.startDate ? new Date(filters.dateRange.startDate) : new Date('2024-03-01');
      const endDate = filters.dateRange.endDate ? new Date(filters.dateRange.endDate) : new Date('2024-08-31');

      const isWithinDateRange = betDate >= startDate && betDate <= endDate;
      const matchesSportsbook = !filters.sportsbook || bet.sportsbook === filters.sportsbook;
      const matchesTeam = !filters.team || bet.team === filters.team;

      return isWithinDateRange && matchesSportsbook && matchesTeam;
    });

    // Group bets by player and calculate their accuracy
    const playerMap = {};
    filteredData.forEach((bet) => {
      if (!playerMap[bet.player]) {
        playerMap[bet.player] = {
          player: bet.player,
          prop_type: bet.prop_type,
          totalBets: 0,
          wins: 0,
        };
      }
      playerMap[bet.player].totalBets += 1;
      if (bet.result === 'win') {
        playerMap[bet.player].wins += 1;
      }
    });

    // Calculate accuracy for each player
    const players = Object.values(playerMap).map((player) => ({
      ...player,
      accuracy: (player.wins / player.totalBets) * 100,
    }));

    // Sort players by accuracy and return the top 6
    return players.sort((a, b) => b.accuracy - a.accuracy).slice(0, 6);
  }, [filters]);

  return (
    <div className="model-performance-top-performing-players-container">
      <h2 className="model-performance-top-performing-players-title">Top Performing Players</h2>
      <div className="model-performance-top-performing-players-header">
        <span className="model-performance-top-performing-players-header-item">Player</span>
        <span className="model-performance-top-performing-players-header-item">Most Played Prop</span>
        <span className="model-performance-top-performing-players-header-item">Accuracy</span>
      </div>
      <div className="model-performance-top-performing-players-list">
        {topPlayers.map((player, index) => (
          <div key={index} className="model-performance-top-performing-players-item">
            <img
              src={`player_logos/${player.player.toLowerCase().split(' ').pop()}.png`} // Get the last name for image
              alt={player.player}
              className="model-performance-top-performing-players-image"
            />
            <span className="model-performance-top-performing-players-name">{player.player}</span>
            <span className="model-performance-top-performing-players-prop">{player.prop_type}</span>
            <span className="model-performance-top-performing-players-accuracy">
              {player.wins}/{player.totalBets} ({player.accuracy.toFixed(1)}%)
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPerformingPlayers;
