import React from 'react';
import './MatchupPlayerPredictions.css'

const PlayerPredictions = ({ color1, playerData }) => {
  // Default stats when playerData is incomplete or missing
  const defaultStats = '10.0';

  // Helper function to generate a random number within a range
  const randomInRange = (min, max) => Math.random() * (max - min) + min;

  // Helper function to generate prediction and probability
  const generatePredProb = (value) => {
    const numericValue = parseFloat(value) || 0;
    const pred = numericValue + randomInRange(-2, 2);
    const prob = randomInRange(0.4, 0.8);
    return { pred: pred.toFixed(1), prob: (prob * 100).toFixed(0) + '%' };
  };

  // Generate predictions and probabilities
  const predictions = {
    Pts: generatePredProb(playerData?.ppg || defaultStats),
    Reb: generatePredProb(playerData?.rpg || defaultStats),
    Ast: generatePredProb(playerData?.apg || defaultStats),
    '3pt': generatePredProb((parseFloat(playerData?.ppg || defaultStats) / 3).toFixed(1)), // Assuming 3pt is roughly 1/3 of points
  };

  return (
    <div className="matchup-players-predictions">
      <div className="matchup-players-predictions-title">Predictions</div>
      <table className="matchup-players-predictions-table">
        <thead>
          <tr>
            <th>Prop</th>
            <th>Pred</th>
            <th>Prob</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(predictions).map(([prop, values]) => (
            <tr key={prop}>
              <td>{prop}</td>
              <td>{values.pred}</td>
              <td>{values.prob}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerPredictions;