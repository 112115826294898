import { useState, useEffect } from "react";
import { usePlayerColors } from "../PlayerColorContext"; // Import the context
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak for authentication
import { fetchData } from '../../api';  // Assuming fetchData is your API utility function
import { teamNameToAbbreviation } from "../../components/TeamDictionary";
const useFetchVersusTeamChartData = (selectedPlayer, x = 5) => {
  const { opponentName: selectedOpponent } = usePlayerColors(); // Get selectedOpponent from context
  const { token } = useKeycloak();  // Get Keycloak token for authenticated API calls
  const [chartData, setChartData] = useState({
    FT: [],
    PT2: [],
    PT3: [],
    Total: [],
    MIN: [],
    AST: [],
    REB: [],
    BLK: [],
    STL: [],
    Dates: [],
  });

  

  useEffect(() => {
    
    if (selectedPlayer) {
      const metrics = [
        "ftm",
        "fg2m",
        "fg3m",
        "game_date",
        "min",
        "ast",
        "reb",
        "blk",
        "stl",
      ]; // Specify the metrics you want to fetch
      const queryString = metrics
        .map((metric) => `metrics=${metric}`)
        .join("&");
      const opponentAbbreviation = selectedOpponent
        ? teamNameToAbbreviation[selectedOpponent]
        : "";
      const url = `players/filter_by_player/?player_id=${selectedPlayer}&${queryString}&x=5${
        opponentAbbreviation ? `&opponent=${opponentAbbreviation}` : ""
      }`;


      // Use fetchData to fetch data with authentication token
      fetchData(url, token)
        .then((data) => {
  
          if (Array.isArray(data)) {
            // Sort data by game_date (assuming it's in the correct format)
            const sortedData = data.sort(
              (a, b) => new Date(a.game_date) - new Date(b.game_date)
            );

            // Create arrays for chart data
            const FT = sortedData.map((d) => d.ftm); // Update to match model fields
            const PT2 = sortedData.map((d) => d.fg2m); // Update to match model fields
            const PT3 = sortedData.map((d) => d.fg3m); // Update to match model fields
            const MIN = sortedData.map((d) => d.min); // Update to match model fields
            const AST = sortedData.map((d) => d.ast);
            const REB = sortedData.map((d) => d.reb);
            const BLK = sortedData.map((d) => d.blk);
            const STL = sortedData.map((d) => d.stl);
            const Dates = sortedData.map((d) => d.game_date);
            const Total = sortedData.map((d) => d.fg2m + d.fg3m + d.ftm); // Update to match model fields

            setChartData({
              FT,
              PT2,
              PT3,
              Total,
              MIN,
              AST,
              REB,
              BLK,
              STL,
              Dates,
            });
          } else {
            console.error("Expected array but received:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedPlayer, selectedOpponent, x, token]);

  return chartData;
};

export default useFetchVersusTeamChartData;
