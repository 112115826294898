import React, { createContext, useContext, useState } from "react";

const TeamColorContext = createContext();

export const useTeamColors = () => {
  return useContext(TeamColorContext);
};

export const TeamColorProvider = ({ children }) => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamColors, setTeamColors] = useState([]);
  const [teamLogoSrc, setTeamLogoSrc] = useState("");

  const value = {
    selectedTeam,
    setSelectedTeam,
    teamColors,
    setTeamColors,
    teamLogoSrc,
    setTeamLogoSrc,
  };

  return (
    <TeamColorContext.Provider value={value}>
      {children}
    </TeamColorContext.Provider>
  );
};
