import React, { useState, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import './PropAccuracyChart.css';

const PropAccuracyChart = ({ filters, betData }) => {
  const [isAccuracyView, setIsAccuracyView] = useState(true);

  // Function to toggle between ROI and Accuracy views
  const toggleView = () => {
    setIsAccuracyView(!isAccuracyView);
  };

  // Helper to format prop labels for summation features
  const formatPropLabel = (prop) => {
    switch (prop) {
      case 'Pts+Ast':
        return 'P+A';
      case 'Pts+Reb':
        return 'P+R';
      case 'Ast+Reb':
        return 'A+R';
      case 'Pts+Reb+Ast':
        return 'P+R+A';
      default:
        return prop; // Leave normal props as is
    }
  };

  // Filter and calculate prop accuracy based on the filters
  const propsData = useMemo(() => {
    if (!betData || betData.length === 0) return []; // Handle empty data

    const filteredData = betData.filter((bet) => {
      const betDate = new Date(bet.date);
      const startDate = filters.dateRange.startDate ? new Date(filters.dateRange.startDate) : new Date('2024-03-01');
      const endDate = filters.dateRange.endDate ? new Date(filters.dateRange.endDate) : new Date('2024-08-31');

      const isWithinDateRange = betDate >= startDate && betDate <= endDate;
      const matchesSportsbook = !filters.sportsbook || bet.sportsbook === filters.sportsbook;
      const matchesTeam = !filters.team || bet.team === filters.team;
      const matchesView = filters.view === 'both' || bet.bet_type === filters.view;

      return isWithinDateRange && matchesSportsbook && matchesTeam && matchesView;
    });

    // Group by prop type and calculate accuracy and ROI
    const propTypes = ['Pts', 'Ast', 'Reb', 'Blk', 'Stl', 'Pts+Reb', 'Pts+Ast', 'Ast+Reb', 'Pts+Reb+Ast'];

    return propTypes.map((prop) => {
      const propBets = filteredData.filter((bet) => bet.prop_type === prop);
      const totalBets = propBets.length;
      const overBets = propBets.filter((bet) => bet.bet_direction === 'over').length;
      const underBets = propBets.filter((bet) => bet.bet_direction === 'under').length;
      const wonBets = propBets.filter((bet) => bet.result === 'win').length;

      const overallProfit = propBets.reduce((acc, bet) => acc + (bet.result === 'win' ? bet.odds * bet.amount_bet - bet.amount_bet : -bet.amount_bet), 0);
      const overProfit = propBets.reduce((acc, bet) => (bet.bet_direction === 'over' ? acc + (bet.result === 'win' ? bet.odds * bet.amount_bet - bet.amount_bet : -bet.amount_bet) : acc), 0);
      const underProfit = propBets.reduce((acc, bet) => (bet.bet_direction === 'under' ? acc + (bet.result === 'win' ? bet.odds * bet.amount_bet - bet.amount_bet : -bet.amount_bet) : acc), 0);

      return {
        prop: formatPropLabel(prop), // Format the prop label for summation features
        overallAccuracy: totalBets > 0 ? (wonBets / totalBets) * 100 : 0,
        overAccuracy: overBets > 0 ? (overBets / totalBets) * 100 : 0,
        underAccuracy: underBets > 0 ? (underBets / totalBets) * 100 : 0,
        overallROI: totalBets > 0 ? (overallProfit / (totalBets * 10)) * 100 : 0, // ROI as percentage
        overROI: overBets > 0 ? (overProfit / (overBets * 10)) * 100 : 0,
        underROI: underBets > 0 ? (underProfit / (underBets * 10)) * 100 : 0,
      };
    });
  }, [betData, filters]);

  // ECharts configuration based on the selected view (Accuracy or ROI)
  const option = isAccuracyView
    ? {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['Overall Accuracy', 'Over Accuracy', 'Under Accuracy'],
        },
        xAxis: {
          type: 'category',
          data: propsData.map((p) => p.prop),
        },
        yAxis: {
          type: 'value',
          name: 'Accuracy (%)',
          axisLabel: {
            formatter: '{value}%',
          },
          min: 0, // Start from 0 for accuracy
        },
        series: [
          {
            name: 'Overall Accuracy',
            type: 'bar',
            data: propsData.map((p) => p.overallAccuracy),
            itemStyle: { color: '#4CAF50' }, // Green for overall accuracy
          },
          {
            name: 'Over Accuracy',
            type: 'bar',
            data: propsData.map((p) => p.overAccuracy),
            itemStyle: { color: '#2196F3' }, // Blue for over accuracy
          },
          {
            name: 'Under Accuracy',
            type: 'bar',
            data: propsData.map((p) => p.underAccuracy),
            itemStyle: { color: '#f44336' }, // Red for under accuracy
          },
        ],
      }
    : {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['Overall ROI', 'Over ROI', 'Under ROI'],
        },
        xAxis: {
          type: 'category',
          data: propsData.map((p) => p.prop),
        },
        yAxis: {
          type: 'value',
          name: 'ROI (%)',
          axisLabel: {
            formatter: '{value}%',
          },
          min: -15, // Allows for negative ROI values
        },
        series: [
          {
            name: 'Overall ROI',
            type: 'bar',
            data: propsData.map((p) => p.overallROI),
            itemStyle: { color: '#FFC107' }, // Yellow for overall ROI
          },
          {
            name: 'Over ROI',
            type: 'bar',
            data: propsData.map((p) => p.overROI),
            itemStyle: { color: '#2196F3' }, // Blue for over ROI
          },
          {
            name: 'Under ROI',
            type: 'bar',
            data: propsData.map((p) => p.underROI),
            itemStyle: { color: '#f44336' }, // Red for under ROI
          },
        ],
      };

  return (
    <div className="prop-accuracy-wrapper">
      <div className="prop-accuracy-header">
        <h3>Prop Accuracy and ROI</h3>
        <button className="prop-accuracy-toggle-btn" onClick={toggleView}>
          Toggle {isAccuracyView ? 'ROI' : 'Accuracy'} View
        </button>
      </div>
      <div className="prop-accuracy-chart-container">
        <ReactECharts
          option={option}
          style={{ height: '285px', width: '108%' }} // Increased height for better visibility
          opts={{ renderer: 'svg', devicePixelRatio: 2 }} // High-definition rendering
        />
      </div>
    </div>
  );
};

export default PropAccuracyChart;
