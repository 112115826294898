import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import './NavigationMenu.css';

const NavigationMenu = () => {
  const [isAuthenticated, keycloak, token, username, roles, loading] = useAuth(); // Get data from useAuth hook
  const { colorBlind, setColorBlind } = usePlayerColors();
  const [colorBlindMode, setColorBlindMode] = useState(colorBlind);

  const handleLogout = async () => {
    if (keycloak) {
      try {
        
        await keycloak.logout({
          redirectUri: process.env.REACT_APP_BASE_URL // redirect to your app's homepage or another URL
        });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    } else {
      console.error("Keycloak instance is not available.");
    }
  };

  const handleToggle = () => {
    const newMode = !colorBlindMode;
    setColorBlindMode(newMode);
    setColorBlind(newMode);
  };

  // Avoid rendering the navigation while still loading the authentication status
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <nav className="nav-menu-navigation-menu">
      <div className="nav-menu-container">
        <div className="nav-menu-brand">
          <img src="/showstone_logo.png" alt="Logo" className="nav-menu-logo" />
        </div>
        <div className="nav-menu-links">
          {isAuthenticated ? (
            <>
              <Link to="/TeamDashboard" className="nav-menu-link">Team Dashboard</Link>
              <Link to="/PlayerDashboard" className="nav-menu-link">Player Dashboard</Link>
              <div className="nav-menu-link nav-menu-dropdown">
                Ticket Stuff
                <div className="nav-menu-dropdown-content">
                  <Link to="/TicketAnalyzer" className="nav-menu-dropdown-item">Ticket Analyzer</Link>
                  <Link to="/TicketWatcher" className="nav-menu-dropdown-item">Ticket Watcher</Link>
                  <Link to="/SelectedPlayers" className="nav-menu-dropdown-item">Selected Players</Link>
                </div>
              </div>
              <Link to="/" className="nav-menu-link">Home Page</Link>
              <div className="nav-menu-link nav-menu-user-menu">
                Hello, {username}
                <div className="nav-menu-dropdown-content">
                  <div className="nav-menu-user-info">
                    <span className="nav-menu-user-role">Role: {Array.isArray(roles) ? roles.join(', ') : 'N/A'}</span>
                    <span onClick={handleLogout} className="nav-menu-logout-link">Sign Out</span>
                  </div>
                </div>
              </div>
              <div className="nav-menu-colorblind-switch">
                <label className="nav-menu-switch">
                  <input type="checkbox" checked={colorBlindMode} onChange={handleToggle} />
                  <span className="nav-menu-slider nav-menu-round"></span>
                </label>
                <span>Color Blind Mode</span>
              </div>
            </>
          ) : (
            <>
              <Link to="/login" className="nav-menu-link">Login</Link>
              <Link to="/signup" className="nav-menu-link">Register</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavigationMenu;
