import React, { useState, useEffect, useRef } from 'react';
import './MatchupInjuryReportTable.css';
import { injuryReport, teamsDict } from '../../components/TeamInfo';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { fetchData } from "../../api"; // Import your utility function
import { useKeycloak } from '../../KeycloakProvider'; // Import the Keycloak context
import teamNameToFullName from '../../components/TeamNameToFullName';
import teamAbbreviationToFullName from '../../components/TeamFullNameDictionary';

const MatchupInjuryReportTable = ({ homeTeam, awayTeam }) => {
  const { token } = useKeycloak(); // Get the token from Keycloak
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [filteredData, setFilteredData] = useState([]);
  const [teamOptions, setTeamOptions] = useState([homeTeam, awayTeam]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const wrapperRef = useRef(null);

  // Reverse the teamNameToAbbreviation dictionary to map abbreviations back to team names
  const abbreviationToTeamName = Object.fromEntries(
    Object.entries(teamNameToAbbreviation).map(([teamName, abbr]) => [abbr, teamName])
  );

  const homeTeamName = abbreviationToTeamName[homeTeam];
  const awayTeamName = abbreviationToTeamName[awayTeam];

  // Update injury report and selected team logo when homeTeam or awayTeam changes
  useEffect(() => {
    setSelectedTeam(homeTeam); // Set the selected team to the home team when the matchup changes
    setTeamOptions([homeTeam, awayTeam]); // Update team options when the matchup changes
  }, [homeTeam, awayTeam]);

  useEffect(() => {
    if (selectedTeam && token) {
      // Clear the existing data before fetching new data
      setFilteredData([]);
      setSelectedTeamLogo('/default-logo.png'); // Reset logo temporarily

      const fetchInjuryReport = async () => {
        try {
          const teamFullName = teamAbbreviationToFullName[selectedTeam];
          const endpoint = `teams/injury_report_view/?team_name=${teamFullName}`;
          const data = await fetchData(endpoint, token); // Use fetchData with token
    
          setFilteredData(Array.isArray(data) ? data : [data]); // Ensure data is an array

          // Update the team logo from teamsDict

          setSelectedTeamLogo(teamsDict[abbreviationToTeamName[selectedTeam]]?.logo || '/default-logo.png');
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  return (
    <div className="matchup-injury-table-container" style={{ backgroundColor: 'white' }}>
      <div className="matchup-injury-table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="matchup-injury-table-logo" />
        <div className="matchup-injury-table-title">Injury Report</div>
        <div className="matchup-injury-table-selected-team-container" onClick={() => setIsEditing(!isEditing)}>
          <div className="matchup-injury-table-selected-team">{selectedTeam || 'Select team...'}</div>
          <div className="matchup-injury-table-dropdown-arrow">▼</div>
        </div>
        {isEditing && (
          <div className="matchup-injury-table-autocomplete-container">
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="matchup-injury-table-autocomplete-item"
                onClick={() => handleTeamChange(team)}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="matchup-injury-table-content">
        <table className="matchup-injury-table-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Impact</th>
        
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.player_id || 'N/A'}</td>
                  <td>
                    <span
                      className={`matchup-injury-table-status-indicator ${
                        item.status === 'Day-To-Day'
                          ? 'matchup-injury-table-status-day-to-day'
                          : item.status === 'Out'
                          ? 'matchup-injury-table-status-out'
                          : ''
                      }`}
                    ></span>
                    {item.status || 'N/A'}
                  </td>
                  <td>{item.position || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No injury data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatchupInjuryReportTable;
