import { useState, useEffect } from 'react';
import { fetchData } from '../../api';  // Import your utility function for fetching data
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak for secure API calls

// Define mapping for selected feature to market field in the API
const featureToFieldMap = {
    'Total': 'player_points',
    'AST': 'player_assists',
    'REB': 'player_rebounds',
    'STL': 'player_steals',
    'BLK': 'player_blocks',
    'Pts+Ast': 'player_points_assists',
    'Pts+Reb': 'player_points_rebounds',
    'Ast+Reb': 'player_assists_rebounds',
    'Pts+Ast+Reb': 'player_points_rebounds_assists',
    // Add other mappings as necessary
};

const useFetchPlayerProps = (selectedFeature, playerName) => {
  const [playerProps, setPlayerProps] = useState([]);
  const { token } = useKeycloak();

  useEffect(() => {
    // Map selected feature to market field in API
    const market = featureToFieldMap[selectedFeature] || 'player_points';  // Default to 'player_points' if not mapped

    // Update endpoint to match the new filter_by_player action
    const endpoint = `player_props/get_props/?player_name=${playerName}&market=${market}`;


    if (playerName && market && token) {
      fetchData(endpoint, token)
        .then(data => setPlayerProps(data))
        .catch(error => {
          console.error("Error fetching player props:", error);
          setPlayerProps([]);
        });
    }
  }, [playerName, token, selectedFeature]);

  return playerProps;
};

export default useFetchPlayerProps;
