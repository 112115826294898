import React, { useState, useMemo } from 'react';
import './ModelPerformance.css';
import FilterSelector from '../model_performance_components/FilterSelector';
import PerformanceTracker from '../model_performance_components/PerformanceTracker';
import BettingTracker from '../model_performance_components/BettingTracker';
import TopPerformingPlayers from '../model_performance_components/TopPerformingPlayers';
import PropAccuracyChart from '../model_performance_components/PropAccuracyChart';
import SeasonPerformance from '../model_performance_components/SeasonPerformance';
import RecentTrends from '../model_performance_components/RecentTrends';
import fakeBetData from '../model_performance_components/data/fakeBetData'; // Import fake data

// Function to filter and aggregate based on filters
const filterBetData = (data, filters) => {
  return data.filter((bet) => {
    const betDate = new Date(bet.date);
    const startDate = filters.dateRange.startDate ? new Date(filters.dateRange.startDate) : new Date('2024-03-01');
    const endDate = filters.dateRange.endDate ? new Date(filters.dateRange.endDate) : new Date('2024-08-31');

    const isWithinDateRange = betDate >= startDate && betDate <= endDate;
    const matchesSportsbook = !filters.sportsbook || bet.sportsbook === filters.sportsbook;
    const matchesTeam = !filters.team || bet.team === filters.team;

    return isWithinDateRange && matchesSportsbook && matchesTeam;
  });
};

// Function to calculate aggregates for RecentTrends and SeasonPerformance (always both "you" and "model")
const calculateAggregates = (data, filters) => {
  const filteredData = filterBetData(data, filters);

  let modelTotal = 0, modelWins = 0, modelProfit = 0;
  let youTotal = 0, youWins = 0, youProfit = 0;

  filteredData.forEach((bet) => {
    if (bet.bet_type === 'model') {
      modelTotal += 1;
      modelProfit += bet.result === 'win' ? bet.odds * bet.amount_bet - bet.amount_bet : -bet.amount_bet;
      if (bet.result === 'win') modelWins += 1;
    } else if (bet.bet_type === 'you') {
      youTotal += 1;
      youProfit += bet.result === 'win' ? bet.odds * bet.amount_bet - bet.amount_bet : -bet.amount_bet;
      if (bet.result === 'win') youWins += 1;
    }
  });

  return {
    modelROI: modelTotal > 0 ? (modelProfit / (modelTotal * 10)) * 100 : 0, // Model always bets $10
    youROI: youTotal > 0 ? (youProfit / (youTotal * 10)) * 100 : 0,         // Assuming you also bet $10
    modelWinRate: modelTotal > 0 ? ((modelWins / modelTotal) * 100).toFixed(1) : 0,
    youWinRate: youTotal > 0 ? ((youWins / youTotal) * 100).toFixed(1) : 0,
  };
};

const ModelPerformance = () => {
  const [filters, setFilters] = useState({ sportsbook: '', team: '', dateRange: { startDate: '', endDate: '' }, view: 'both' });

  // Memoizing filtered data and aggregates to avoid unnecessary re-renders
  const filteredBetData = useMemo(() => filterBetData(fakeBetData, filters), [filters]);
  const aggregates = useMemo(() => calculateAggregates(fakeBetData, filters), [filters]);

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  return (
    <div className="model-performance-container">
      <div className="model-performance-dashboard">
        <div className="model-performance-row model-performance-top-row">
          <div className="model-performance-left-column">
            <FilterSelector onFilterChange={handleFilterChange} />
          </div>
          {/* Apply view filter only to PerformanceTracker */}
          <PerformanceTracker filters={filters} />
        </div>

        <div className="model-performance-row model-performance-top-row">
          <div className="model-performance-left-column">
            {/* Always show both you and model data */}
            <RecentTrends aggregates={aggregates} />
            <SeasonPerformance aggregates={aggregates} />
          </div>
          <div className="model-prop-accuracy">
            {/* Apply view filter to PropAccuracyChart */}
            <PropAccuracyChart filters={filters} betData={filteredBetData} />
          </div>
        </div>

        <div className="model-performance-row model-performance-bottom-row">
          <BettingTracker filters={filters} />
          <div className="model-performance-top-performing-players">
            <TopPerformingPlayers filters={filters} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelPerformance;
