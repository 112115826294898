import React, { useMemo } from 'react';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import useFetchTeamL15TableData from './data/TeamLast15TableData';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './TeamStatsTable.css';

const getMetricName = (metric) => {
  const metricMappings = {
    team_pts: 'PTS',
    team_min: 'MIN',
    team_fgm: 'FGM',
    team_fga: 'FGA',
    team_fg3m: '3PM',
    team_fg3a: '3PTA',
    team_ftm: 'FTM',
    team_fta: 'FTA',
    team_fg_percent: 'FG%',
    team_fg3_percent: '3PT%',
    team_ft_percent: 'FT%',
    team_TS_percent: 'TS%',
    team_ast: 'AST',
    team_secondaryassists: '2nd AST',
    team_freethrowassists: 'FT AST',
    team_passes: 'PASS',
    team_touches: 'TOUCH',
    team_reb: 'REB',
    team_oreb: 'OREB',
    team_dreb: 'DREB',
    team_reboundchancesdefensive: 'DREB Chances',
    team_reboundchancesoffensive: 'OREB Chances',
    team_reboundchancestotal: 'REB Chances',
    team_blk: 'BLK',
    team_blka: 'BLKA',
    team_stl: 'STL',
  };

  return metricMappings[metric.replace('_mean_rolling_5_games', '').replace('_mean_rolling_15_games', '')] || metric;
};

const TeamLast15Table = ({ selectedFeature }) => {
  const { selectedTeam, teamColors } = usePlayerColors();
  const teamAbbreviation = teamNameToAbbreviation[selectedTeam];
  const rollingData = useFetchTeamL15TableData(teamAbbreviation, selectedFeature);

  const data = useMemo(() => {
    const metrics = Object.keys(rollingData).filter(key => key.includes('_mean_rolling_5_games'));
    
    let processedData = metrics.map(metric => {
      const key5 = metric;
      const key15 = metric.replace('_5_games', '_15_games');
      return {
        metric: getMetricName(metric),
        l5: parseFloat(rollingData[key5] || 0).toFixed(2),
        l15: parseFloat(rollingData[key15] || 0).toFixed(2)
      };
    });

    if (['Pts+Ast', 'Ast+Reb', 'Pts+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
      // Calculate combined stats
      const stats = selectedFeature.split('+');
      const combinedValue5 = stats.reduce((sum, stat) => sum + parseFloat(rollingData[`team_${stat.toLowerCase()}_mean_rolling_5_games`] || 0), 0);
      const combinedValue15 = stats.reduce((sum, stat) => sum + parseFloat(rollingData[`team_${stat.toLowerCase()}_mean_rolling_15_games`] || 0), 0);
      
      processedData = [
        {
          metric: selectedFeature,
          l5: combinedValue5.toFixed(2),
          l15: combinedValue15.toFixed(2)
        },
        ...stats.map(stat => ({
          metric: getMetricName(`team_${stat.toLowerCase()}`),
          l5: parseFloat(rollingData[`team_${stat.toLowerCase()}_mean_rolling_5_games`] || 0).toFixed(2),
          l15: parseFloat(rollingData[`team_${stat.toLowerCase()}_mean_rolling_15_games`] || 0).toFixed(2)
        })),
        {
          metric: 'MIN',
          l5: parseFloat(rollingData['team_min_mean_rolling_5_games'] || 0).toFixed(2),
          l15: parseFloat(rollingData['team_min_mean_rolling_15_games'] || 0).toFixed(2)
        }
      ];

      // Add shooting percentages for features including PTS
      if (selectedFeature.includes('Pts')) {
        ['fg', 'fg3', 'ft'].forEach(shotType => {
          processedData.push({
            metric: `${shotType.toUpperCase()}%`,
            l5: rollingData[`team_${shotType}_percent_mean_rolling_5_games`],
            l15: rollingData[`team_${shotType}_percent_mean_rolling_15_games`]
          });
        });
      }

      // Add additional relevant stats to reach 11 features
      const additionalStats = ['oreb', 'dreb', 'secondaryassists', 'passes', 'touches', 'reboundchancestotal'];
      let i = 0;
      while (processedData.length < 11 && i < additionalStats.length) {
        const stat = additionalStats[i];
        if (rollingData[`team_${stat}_mean_rolling_5_games`]) {
          processedData.push({
            metric: getMetricName(`team_${stat}`),
            l5: parseFloat(rollingData[`team_${stat}_mean_rolling_5_games`] || 0).toFixed(2),
            l15: parseFloat(rollingData[`team_${stat}_mean_rolling_15_games`] || 0).toFixed(2)
          });
        }
        i++;
      }

      // Limit to 11 features
      processedData = processedData.slice(0, 11);
    }

    return processedData;
  }, [rollingData, selectedFeature]);

  const calculateDiff = (l15, l5) => {
    const diff = ((parseFloat(l5) - parseFloat(l15)) / parseFloat(l15)) * 100;
    return diff.toFixed(0);
  };

  const teamColor = teamColors[0] || '#003366';
  const highlightColor = teamColors[1] || '#FFD700';

  return (
    <div className="l15-stats-container" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="l15-table-header" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="l15-header-title">{selectedFeature} Stats</div>
        <div className="l15-header-icon"></div>
      </div>
      <table className="l15-stats-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Last 15</th>
            <th>Last 5</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? 'l15-even-row' : 'l15-odd-row'}
              style={{ backgroundColor: index % 2 === 0 ? 'white' : 'white' }}
            >
              <td>{item.metric}</td>
              <td>{item.l15}</td>
              <td dangerouslySetInnerHTML={{
                __html: `${item.l5} ${
                  parseFloat(item.l5) > parseFloat(item.l15)
                    ? '<span class="l15-arrow-up">↑</span>'
                    : '<span class="l15-arrow-down">↓</span>'
                } <span class="l15-percentage-diff">${calculateDiff(item.l15, item.l5)}%</span>`
              }}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamLast15Table;