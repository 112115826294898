// MatchupDashboard.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './MatchupDashboard.css';
import MatchupHeader from '../matchup_dashboard_components/MatchupHeader';
import { teamsDict } from '../components/TeamInfo';
import {teamNameToAbbreviation} from '../components/TeamDictionary';
import SpreadBar from '../matchup_dashboard_components/matchup_stats_components/SpreadBar';
import MatchupInformation from './matchup_views/MatchupInformation';
import StartingLineup from '../matchup_dashboard_components/StartingLineup';
import TeamComparisonChart from '../matchup_dashboard_components/matchup_stats_components/TeamComparisonChart';
import TeamComparisonLineChart from '../matchup_dashboard_components/matchup_stats_components/TeamComparisonBarChart';
import MatchupPlayerRoster from '../matchup_dashboard_components/MatchupPlayerRoster';

const MatchupDashboard = () => {
  const location = useLocation();
  const { matchup } = location.state || {};

  const [activeView, setActiveView] = useState('matchupInfo');

  if (!matchup) {
    return <div>Error: No matchup data available.</div>;
  }

  const { awayTeamAbbr, homeTeamAbbr, time, picks, topPicks } = matchup;

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const awayTeamFullName = reverseTeamAbbreviation[awayTeamAbbr];
  const homeTeamFullName = reverseTeamAbbreviation[homeTeamAbbr];
  const homeTeamVenue = teamsDict[homeTeamFullName]?.venue;

  const homeTeamId = teamsDict[homeTeamFullName]?.team_id;
  const awayTeamId = teamsDict[awayTeamFullName]?.team_id;

  return (
    <div className="layout-container">
      <div className="matchup-header">
        <MatchupHeader
          awayTeam={awayTeamAbbr}
          homeTeam={homeTeamAbbr}
          time={time}
          venue={`${homeTeamVenue}, ${teamsDict[homeTeamFullName]?.location}`}
        />
      </div>

      <div className="spread-bar-container">
        <SpreadBar
          homeTeam={homeTeamAbbr}
          awayTeam={awayTeamAbbr}
          spread={7.5}
        />
      </div>

      <div className="view-buttons">
        <button
          className={activeView === 'matchupInfo' ? 'active' : ''}
          onClick={() => setActiveView('matchupInfo')}
        >
          Matchup Info
        </button>
        <button
          className={activeView === 'matchupStats' ? 'active' : ''}
          onClick={() => setActiveView('matchupStats')}
        >
          Matchup Stats
        </button>
        <button
          className={activeView === 'startingFive' ? 'active' : ''}
          onClick={() => setActiveView('startingFive')}
        >
          Starting Five
        </button>
      </div>

      {activeView === 'matchupInfo' && (
        <MatchupInformation
          matchup={matchup}
          homeTeamAbbr={homeTeamAbbr}
          awayTeamAbbr={awayTeamAbbr}
        />
      )}

      {activeView === 'matchupStats' && (
        <div className="matchup-stats-content">
          <TeamComparisonChart
            homeTeamAbbr={homeTeamAbbr}
            awayTeamAbbr={awayTeamAbbr}
          />
          <TeamComparisonLineChart
            homeTeamAbbr={homeTeamAbbr}
            awayTeamAbbr={awayTeamAbbr}
          />
        </div>
      )}

      {activeView === 'startingFive' && (
        <div className="starting-five-content">
          <StartingLineup
            homeTeamAbbr={homeTeamAbbr}
            awayTeamAbbr={awayTeamAbbr}
          />
          <MatchupPlayerRoster
            homeTeamId={homeTeamId}
            awayTeamId={awayTeamId}
          />
        </div>
      )}
    </div>
  );
};

export default MatchupDashboard;