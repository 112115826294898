const teamNameToFullName = {
    'Bucks': 'Milwaukee Bucks',
    'Nuggets': 'Denver Nuggets',
    'Lakers': 'Los Angeles Lakers',
    'Warriors': 'Golden State Warriors',
    'Nets': 'Brooklyn Nets',
    'Suns': 'Phoenix Suns',
    'Clippers': 'Los Angeles Clippers',
    'Heat': 'Miami Heat',
    'Bulls': 'Chicago Bulls',
    'Hawks': 'Atlanta Hawks',
    'Timberwolves': 'Minnesota Timberwolves',
    'Celtics': 'Boston Celtics',
    '76ers': 'Philadelphia 76ers',
    'Mavericks': 'Dallas Mavericks',
    'Raptors': 'Toronto Raptors',
    'Jazz': 'Utah Jazz',
    'Blazers': 'Portland Trail Blazers',
    'Grizzlies': 'Memphis Grizzlies',
    'Pelicans': 'New Orleans Pelicans',
    'Kings': 'Sacramento Kings',
    'Hornets': 'Charlotte Hornets',
    'Pacers': 'Indiana Pacers',
    'Spurs': 'San Antonio Spurs',
    'Thunder': 'Oklahoma City Thunder',
    'Rockets': 'Houston Rockets',
    'Pistons': 'Detroit Pistons',
    'Wizards': 'Washington Wizards',
    'Magic': 'Orlando Magic',
    'Knicks': 'New York Knicks',
    'Cavaliers': 'Cleveland Cavaliers'
  };
export default teamNameToFullName;