import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { fetchDataTeams, fetchTeamPlayers } from './data/TeamVersusTeamChartData';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './TeamVersusTeamChart.css';
import teamAbbreviationToFullName from '../components/TeamFullNameDictionary.js';
import { useKeycloak } from '../KeycloakProvider';

const TeamVersusTeamChart = ({ selectedFeature }) => {
  const { teamColors, selectedTeam, opponentName } = usePlayerColors();
  const chartRef = useRef(null);
  const [cachedTeamsData, setCachedTeamsData] = useState({});
  const [inDrillDown, setInDrillDown] = useState(false);
  const [currentGameDate, setCurrentGameDate] = useState(null);
  const [showTotal, setShowTotal] = useState(true);
  const [currentTeamAbbreviation, setCurrentTeamAbbreviation] = useState(null);
  const { token } = useKeycloak();  // Get the token from Keycloak
  const opponentAbbreviation = teamNameToAbbreviation[opponentName];

  useEffect(() => {
    if (!token) {
      console.warn('Token is null in TeamVersusTeamChart. Ensure that the Keycloak provider is set up correctly.');
      return;
    }
  }, [token]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = String(d.getFullYear()).slice(-2);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getPlayerInitials = (fullName) => {
    return fullName ? fullName.split(' ').map(name => name[0]).join('') : '';
  };

  const destroyChart = () => {
    if (chartRef.current) {
      const existingChart = echarts.getInstanceByDom(chartRef.current);
      if (existingChart) {
        existingChart.dispose();
      }
    }
  };

  const goBackToTeams = useCallback(async () => {
    if (!token) {
      console.warn('Token is null when trying to go back to teams.');
      return;
    }

    setInDrillDown(false);
    setCurrentGameDate(null);

    let updatedTeamsData;

    if (cachedTeamsData[currentTeamAbbreviation]) {
      updatedTeamsData = cachedTeamsData[currentTeamAbbreviation];
    } else {
      updatedTeamsData = await fetchDataTeams(currentTeamAbbreviation, opponentAbbreviation, token);
      updatedTeamsData = updatedTeamsData.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
      setCachedTeamsData(prevCache => ({
        ...prevCache,
        [currentTeamAbbreviation]: updatedTeamsData,
      }));
    }

    const featureMap = {
      'Total': 'team_points',
      'AST': 'team_ast',
      'REB': 'team_reb',
      'BLK': 'team_blk',
      'STL': 'team_stl',
      'Pts+Ast': ['team_points', 'team_ast'],
      'Pts+Reb': ['team_points', 'team_reb'],
      'Ast+Reb': ['team_ast', 'team_reb'],
      'Pts+Ast+Reb': ['team_points', 'team_ast', 'team_reb'],
    };

    const feature = featureMap[selectedFeature] || 'team_points';

    const updatedTeamOption = {
      xAxis: {
        type: 'category',
        data: updatedTeamsData.map(item => `${formatDate(item.game_date)}\n${item.opponent}`),
        axisLabel: {
          rotate: 0,
          align: 'center',
          fontSize: 11.5,
        }
      },
      yAxis: {
        type: 'value',
        minInterval: 1
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '10%',
        top: '20%',
        containLabel: true
      },
      legend: {
        data: Array.isArray(feature) ? feature.map(f => f.toUpperCase()) : [selectedFeature.toUpperCase()],
        top: '12%',
        left: 'center',
        textStyle: {
          color: '#000',
        },
        z: 10
      },
      series: createSeries(updatedTeamsData, feature)
    };

    const myChart = echarts.getInstanceByDom(chartRef.current);
    if (updatedTeamOption && updatedTeamOption.series && updatedTeamOption.series.length > 0) {
      myChart.setOption(updatedTeamOption);
    }
    enableChartClick(myChart);
  }, [cachedTeamsData, selectedFeature, teamColors, currentTeamAbbreviation, opponentAbbreviation, token]);

  const createSeries = (data, feature, isPlayerData = false) => {
    const series = [];

    const filteredData = data.filter(item => {
      if (Array.isArray(feature)) {
        return feature.some(f => item[f] >= 2);
      }
      return item[feature] >= 2;
    });

    if (showTotal && !inDrillDown) {
      if (Array.isArray(feature)) {
        series.push({
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: '{c}'
          },
          data: filteredData.map(item => ({
            value: feature.reduce((sum, f) => sum + item[f], 0),
            name: item.game_date || item.player_name,
            opponent: item.opponent
          })),
          universalTransition: {
            enabled: true,
            divideShape: 'clone'
          },
          itemStyle: {
            color: teamColors[0]
          }
        });
      } else {
        series.push({
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: '{c}'
          },
          data: filteredData.map(item => ({
            value: isPlayerData ? item[feature] : item[feature],
            name: item.game_date || item.player_name,
            opponent: item.opponent
          })),
          universalTransition: {
            enabled: true,
            divideShape: 'clone'
          },
          itemStyle: {
            color: teamColors[0]
          }
        });
      }
    } else if (Array.isArray(feature)) {
      feature.forEach((feat, index) => {
        series.push({
          name: feat.toUpperCase().replace('TEAM_', ''),
          type: 'bar',
          stack: 'total',
          data: filteredData.map(item => item[feat]),
          itemStyle: { color: teamColors[index % teamColors.length] },
          label: { show: true, position: 'insideTop', formatter: '{c}' }
        });
      });
    } else {
      series.push({
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          formatter: '{c}'
        },
        data: filteredData.map(item => ({
          value: isPlayerData ? item[feature] : item[feature],
          name: item.game_date || item.player_name,
          opponent: item.opponent
        })),
        universalTransition: {
          enabled: true,
          divideShape: 'clone'
        },
        itemStyle: {
          color: teamColors[0]
        }
      });
    }

    return series;
  };

  const initializeChart = useCallback(async (teamAbbreviation, data = null, isPlayerData = false, gameDate = null) => {
    if (!token) {
      console.warn('Token is null during chart initialization.');
      return;
    }

    destroyChart();

    const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

    let teamsData = data || cachedTeamsData[teamAbbreviation];

    if (!teamsData) {
      teamsData = await fetchDataTeams(teamAbbreviation, opponentAbbreviation, token);
      teamsData = teamsData.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
      setCachedTeamsData(prevCache => ({
        ...prevCache,
        [teamAbbreviation]: teamsData
      }));
    }

    const featureMap = {
      'Total': 'team_points',
      'AST': 'team_ast',
      'REB': 'team_reb',
      'BLK': 'team_blk',
      'STL': 'team_stl',
      'Pts+Ast': ['team_points', 'team_ast'],
      'Pts+Reb': ['team_points', 'team_reb'],
      'Ast+Reb': ['team_ast', 'team_reb'],
      'Pts+Ast+Reb': ['team_points', 'team_ast', 'team_reb'],
    };

    const feature = featureMap[selectedFeature] || 'team_points';

    const xAxisData = isPlayerData
      ? teamsData.filter(item => item.game_date === gameDate).map(item => item.player_name)
      : teamsData.map(item => `${formatDate(item.game_date)}\n${item.opponent}`);

    const teamOption = {
      id: 'teams',
      title: {
        text: `${selectedFeature} vs ${teamAbbreviationToFullName[opponentAbbreviation] || 'Unknown'}`,
        left: '2%',
        top: '5%'
      },
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          const item = teamsData[params[0].dataIndex];
          let tooltipContent = `${item.game_date}<br>${item.opponent}<br>`;
          params.forEach(param => {
            tooltipContent += `${param.seriesName}: ${param.value}<br>`;
          });
          return tooltipContent;
        }
      },
      legend: {
        data: Array.isArray(feature) ? feature.map(f => f.toUpperCase().replace('TEAM_', '')) : [selectedFeature.toUpperCase()],
        top: '12%',
        left: 'center',
        textStyle: {
          color: '#000',
        },
        z: 10
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          rotate: 0,
          align: 'center',
          fontSize: 11.5,
          formatter: isPlayerData ? (value) => getPlayerInitials(value) : undefined
        }
      },
      yAxis: {
        type: 'value',
        minInterval: 1
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '10%',
        top: '20%',
        containLabel: true
      },
      series: createSeries(teamsData, feature, isPlayerData),
    };

    if (teamOption && teamOption.series && teamOption.series.length > 0) {
      myChart.setOption(teamOption);
    }
    enableChartClick(myChart);
    setCurrentTeamAbbreviation(teamAbbreviation);
  }, [cachedTeamsData, selectedFeature, teamColors, currentTeamAbbreviation, showTotal, token]);

  const handleChartClick = useCallback(async (params) => {
    if (!token) {
      console.warn('Token is null when handling chart click.');
      return;
    }

    const dataItem = params.data;
    if (!dataItem || !dataItem.name) {
      console.error("Data item is missing or undefined:", dataItem);
      return;
    }

    setInDrillDown(true);
    setCurrentGameDate(dataItem.name);

    const teamPlayers = await fetchTeamPlayers(currentTeamAbbreviation, dataItem.name, opponentAbbreviation, token);

    const featureMap = {
      'Total': 'pts',
      'AST': 'ast',
      'REB': 'reb',
      'BLK': 'blk',
      'STL': 'stl',
      'Pts+Ast': ['pts', 'ast'],
      'Pts+Reb': ['pts', 'reb'],
      'Ast+Reb': ['ast', 'reb'],
      'Pts+Ast+Reb': ['pts', 'ast', 'reb'],
      'Points': ['fg2m', 'fg3m', 'ftm']
    };

    const feature = featureMap[selectedFeature];

    const createPlayerSeries = () => {
      const series = [];

      const filteredPlayers = teamPlayers.filter(item => {
        if (Array.isArray(feature)) {
          return feature.some(feat => item[feat] >= 2);
        }
        return item[feature] >= 2;
      });

      if (Array.isArray(feature)) {
        feature.forEach((feat, index) => {
          series.push({
            name: feat.toUpperCase().replace('TEAM_', ''),
            type: 'bar',
            stack: 'total',
            data: filteredPlayers.map(item => item[feat]),
            itemStyle: { color: teamColors[index % teamColors.length] },
            label: {
              show: true,
              position: 'insideTop',
              formatter: (params) => {
                return params.value > 2 ? params.value : '';
              }
            }
          });
        });
      } else {
        series.push({
          name: selectedFeature,
          type: 'bar',
          data: filteredPlayers.map(item => item[feature]),
          itemStyle: { color: teamColors[0] },
          label: {
            show: true,
            position: 'top',
            formatter: '{c}'
          }
        });
      }

      return series;
    };

    destroyChart();

    const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

    const playerOption = {
      id: `team_${currentTeamAbbreviation}_${dataItem.name}`,
      title: {
        text: `${dataItem.name} vs ${teamAbbreviationToFullName[opponentAbbreviation] || 'Unknown'}`,
        left: '3%',
        top: '5%'
      },
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          const playerName = teamPlayers[params[0].dataIndex].player_name;
          let tooltipContent = `${playerName}<br>${dataItem.name}<br>`;
          params.forEach(param => {
            tooltipContent += `${param.seriesName}: ${param.value}<br>`;
          });
          return tooltipContent;
        }
      },
      legend: {
        data: Array.isArray(feature) ? feature.map(f => f.toUpperCase().replace('TEAM_', '')) : [selectedFeature.toUpperCase()],
        top: '12%',
        left: '5%',
        textStyle: {
          color: '#000',
        },
        z: 20
      },
      xAxis: {
        type: 'category',
        data: teamPlayers.map(item => item.player_name),
        axisLabel: {
          fontSize: 14,
          rotate: 0,
          interval: 0,
          formatter: (value) => getPlayerInitials(value)
        }
      },
      yAxis: {
        type: 'value',
        minInterval: 1
      },
      grid: {
        left: '10%',
        right: '1%',
        bottom: '5%',
        top: '25%',
        containLabel: true
      },
      series: createPlayerSeries(),
    };

    if (playerOption && playerOption.series && playerOption.series.length > 0) {
      myChart.setOption(playerOption);
    } else {
      console.error("Chart option is invalid:", playerOption);
    }
  }, [currentTeamAbbreviation, selectedFeature, teamColors, opponentAbbreviation, token]);

  const enableChartClick = (myChart) => {
    if (myChart) {
      myChart.on('click', 'series', handleChartClick);
    }
  };

  useEffect(() => {
    if (selectedTeam) {
      setInDrillDown(false);
      setCurrentGameDate(null);
      const selectedTeamAbbreviation = teamNameToAbbreviation[selectedTeam];
      initializeChart(selectedTeamAbbreviation, null, false, null);
    }
  }, [selectedTeam, selectedFeature, teamColors, token]);

  useEffect(() => {
    if (chartRef.current && currentTeamAbbreviation) {
      const cachedData = cachedTeamsData[currentTeamAbbreviation];
      if (cachedData && Object.keys(cachedData).length >= 1) {
        destroyChart();
        if (inDrillDown) {
          initializeChart(currentTeamAbbreviation, cachedData, true, currentGameDate);
        } else {
          initializeChart(currentTeamAbbreviation, cachedData);
        }
      }
    }
  }, [showTotal, initializeChart, currentTeamAbbreviation, inDrillDown, cachedTeamsData, currentGameDate, token]);

  return (
    <div style={{ position: 'relative', width: '90%', height: '100%' }}>
      {!inDrillDown && (
        <div className="team-vs-toggle-container" style={{ zIndex: 10, position: 'absolute', top: 0, left: '370px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontFamily: 'var(--font-main)' }}>{showTotal ? 'Total' : 'Stacked'}</span>
          <label className="switch" style={{ '--primary-color': teamColors[0] }}>
            <input
              type="checkbox"
              onChange={() => setShowTotal(prev => !prev)}
              checked={showTotal}
            />
            <span className="slider round" style={{ zIndex: 11 }}></span>
          </label>
        </div>
      )}
      {inDrillDown && (
        <button 
          onClick={goBackToTeams}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            zIndex: 20,
            background: 'none',
            border: 'none',
            color: 'grey',
            fontSize: '18px',
            cursor: 'pointer'
          }}
        >
          Back
        </button>
      )}
      <div ref={chartRef} className="team-versus-team-chart" style={{ width: '100%', height: '100%', zIndex: 1 }} />
    </div>
  );
};

export default TeamVersusTeamChart;