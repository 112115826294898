import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { useState, useEffect, useRef } from 'react';
import { fetchData } from '../../api';  // Import fetchData
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak context

const useFetchTeamChartData = (teamName, metrics = ['pts', 'reb', 'ast', 'blk', 'stl', 'min'], x = 15) => {
  const [chartData, setChartData] = useState({
    players: [],
    dates: [],
    seriesData: {}
  });
  const [loading, setLoading] = useState(true);
  const dataCache = useRef({});
  const isInitialMount = useRef(true);

  const { token } = useKeycloak();  // Get the token from Keycloak

  const processData = (data, metrics) => {
    const players = Array.from(new Set(data.map(d => d.player_name)));
    const dates = Array.from(new Set(data.map(d => d.game_date))).sort((a, b) => new Date(a) - new Date(b));

    const playerMetricsMap = {};
    players.forEach(player => {
      playerMetricsMap[player] = dates.reduce((metricsSum, date) => {
        const playerGame = data.find(d => d.player_name === player && d.game_date === date);
        metrics.forEach(metric => {
          metricsSum[metric] = (metricsSum[metric] || 0) + (playerGame ? playerGame[metric] : 0);
        });
        return metricsSum;
      }, {});
    });

    const sortedPlayers = Object.keys(playerMetricsMap).sort((a, b) => playerMetricsMap[b]['min'] - playerMetricsMap[a]['min']);
    const topPlayers = sortedPlayers.slice(0, 8);
    const otherPlayers = sortedPlayers.slice(8);

    const seriesData = {};
    metrics.forEach(metric => {
      seriesData[metric] = topPlayers.map(player => ({
        name: player,
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: { focus: 'series' },
        data: dates.map(date => {
          const playerGame = data.find(d => d.player_name === player && d.game_date === date);
          return playerGame ? playerGame[metric] : 0;
        })
      }));

      seriesData[metric].push({
        name: 'Other',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: { focus: 'series' },
        data: dates.map(date => {
          return otherPlayers.reduce((sum, player) => {
            const playerGame = data.find(d => d.player_name === player && d.game_date === date);
            return sum + (playerGame ? playerGame[metric] : 0);
          }, 0);
        })
      });
    });

    const summationFeatures = {
      'pts+ast': ['pts', 'ast'],
      'pts+reb': ['pts', 'reb'],
      'ast+reb': ['ast', 'reb'],
      'pts+ast+reb': ['pts', 'ast', 'reb']
    };

    Object.entries(summationFeatures).forEach(([sumFeature, featureMetrics]) => {
      seriesData[sumFeature] = topPlayers.map(player => ({
        name: player,
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: { focus: 'series' },
        data: dates.map(date => {
          return featureMetrics.reduce((sum, metric) => {
            const playerGame = data.find(d => d.player_name === player && d.game_date === date);
            return sum + (playerGame ? playerGame[metric] : 0);
          }, 0);
        })
      }));

      seriesData[sumFeature].push({
        name: 'Other',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: { focus: 'series' },
        data: dates.map(date => {
          return otherPlayers.reduce((sum, player) => {
            return sum + featureMetrics.reduce((metricSum, metric) => {
              const playerGame = data.find(d => d.player_name === player && d.game_date === date);
              return metricSum + (playerGame ? playerGame[metric] : 0);
            }, 0);
          }, 0);
        })
      });
    });

    return { players: [...topPlayers, 'Other'], dates, seriesData };
  };

  useEffect(() => {
    const fetchDataForTeam = async () => {
      if (!teamName || !token) return;

      if (dataCache.current[teamName]) {
        setChartData(dataCache.current[teamName]);
        setLoading(false);
        return;
      }


      setLoading(true);

      const metricsQueryString = metrics.map(metric => `metrics=${metric}`).join('&');
      const endpoint = `players/filter_by_team/?team_name=${teamNameToAbbreviation[teamName]}&${metricsQueryString}&x=${x}`;

      try {
        const data = await fetchData(endpoint, token);  // Use fetchData with token
        if (Array.isArray(data)) {
          const newChartData = processData(data, metrics);
          dataCache.current[teamName] = newChartData;
          setChartData(newChartData);
        } else {
          console.error('Error fetching data:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isInitialMount.current) {
      fetchDataForTeam();
      isInitialMount.current = false;
    } else if (!dataCache.current[teamName]) {
      fetchDataForTeam();
    } else {
      setChartData(dataCache.current[teamName]);
      setLoading(false);
    }
  }, [teamName, metrics, x, token]);

  return { chartData, loading };
};

export default useFetchTeamChartData;
