import React, { useEffect, useState } from 'react';
import { fetchData } from '../api';
import './AvailablePlayers.css';
import { matchups } from '../components/MatchupInfo';
import { teamsDict } from '../components/TeamInfo';
import { useKeycloak } from '../KeycloakProvider';

const AvailablePlayers = ({ setPlayerIds }) => {
  const [loading, setLoading] = useState(false);
  const [parsedMatchups, setParsedMatchups] = useState([]);
  const { token } = useKeycloak();

  useEffect(() => {
    const parsed = matchups.map((matchup) => {
      const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
      return { ...matchup, awayTeam, homeTeam };
    });
    setParsedMatchups(parsed);
  }, []);

  // Load saved players from localStorage when "Selected Players" button is clicked
  const loadSelectedPlayers = () => {
    const cachedPlayers = localStorage.getItem('savedPlayers');
    if (cachedPlayers) {
      const parsedPlayers = JSON.parse(cachedPlayers);
      setPlayerIds(parsedPlayers.map((player) => player.playerId));
    }
  };

  const handleMatchupClick = async (awayTeamFullName, homeTeamFullName) => {
    setLoading(true);
    try {
      const homeTeamId = teamsDict[homeTeamFullName]?.team_id;
      const awayTeamId = teamsDict[awayTeamFullName]?.team_id;

      if (homeTeamId && awayTeamId) {
        const [homeTeamPlayersResponse, awayTeamPlayersResponse] = await Promise.all([
          fetchData(`players_overall/get_team_players/?team_id=${homeTeamId}`, token),
          fetchData(`players_overall/get_team_players/?team_id=${awayTeamId}`, token),
        ]);

        const homeTeamPlayers = homeTeamPlayersResponse.map((player) => player.player_id);
        const awayTeamPlayers = awayTeamPlayersResponse.map((player) => player.player_id);

        setPlayerIds([...homeTeamPlayers, ...awayTeamPlayers]);
      } else {
        console.error('Could not find team IDs for selected matchup');
      }
    } catch (error) {
      console.error('Failed to fetch players:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="available-players-buttons">
      <button
        key="default"
        onClick={loadSelectedPlayers}
        className="available-players-matchup-btn default-btn"
      >
        <span>⭐</span>
        <span>Selected Players</span>
      </button>

      {parsedMatchups.map((matchup, index) => (
        <button
          key={index}
          onClick={() => handleMatchupClick(matchup.awayTeam, matchup.homeTeam)}
          className="available-players-matchup-btn"
        >
          <img src={`/team_logos/${matchup.awayTeam.toLowerCase()}.png`} alt={matchup.awayTeam} />
          <span className="matchup-time">
            <span className="matchup-at">@</span>
            <span>{matchup.label}</span>
          </span>
          <img src={`/team_logos/${matchup.homeTeam.toLowerCase()}.png`} alt={matchup.homeTeam} />
        </button>
      ))}
      {loading && <p>Loading players...</p>}
    </div>
  );
};

export default AvailablePlayers;
