import React, { useState, useEffect, useRef } from 'react';
import './InjuryReportTable.css';
import { usePlayerColors } from './PlayerColorContext';
import { combinedInfo } from '../components/CombinedInfo';
import { fetchData } from "../api";
import { useKeycloak } from '../KeycloakProvider';
import teamNameToFullName from '../components/TeamNameToFullName';

const InjuryReportTable = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const { token } = useKeycloak();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [homeTeamData, setHomeTeamData] = useState([]);
  const [opponentTeamData, setOpponentTeamData] = useState([]);
  const [homeImpactData, setHomeImpactData] = useState({});
  const [opponentImpactData, setOpponentImpactData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (selectedPlayer) {
      const player = combinedInfo[selectedPlayer];
      if (player) {
        const teamName = player.teamName;
        const opponentName = player.opponentName;
        setTeamOptions([teamName, opponentName]);
        setSelectedTeam(teamName);
        setSelectedTeamLogo(player.teamLogoSrc);
      }
    }
  }, [selectedPlayer]);

  useEffect(() => {
    if (selectedTeam && token) {
      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo.opponentName;
      setSelectedTeamLogo('/default-logo.png');

      const fetchInjuryReport = async () => {
        try {
          const endpoint = `teams/injury_report_view/?team_name=${teamNameToFullName[selectedTeam]}`;
          const data = await fetchData(endpoint, token);

          if (isOpponentSelected) {
            setOpponentTeamData(data.filter(player => player.team_name === teamNameToFullName[selectedTeam]));
            setHomeTeamData([]);
          } else {
            setHomeTeamData(data.filter(player => player.team_name === teamNameToFullName[selectedTeam]));
            setOpponentTeamData([]);
          }

          const teamInfo = Object.values(combinedInfo).find(info => info.teamName === selectedTeam);
          if (teamInfo) {
            setSelectedTeamLogo(teamInfo.teamLogoSrc);
          }
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token, selectedPlayer]);

  const featureMap = {
    "Total": "pts",
    "AST": "ast",
    "REB": "reb",
    "BLK": "blk",
    "STL": "stl",
    "Pts+Ast": "Pts+Ast",
    "Pts+Reb": "Pts+Reb",
    "Ast+Reb": "Ast+Reb",
    "Pts+Ast+Reb": "Pts+Ast+Reb"
  };

  const visualFeatureMap = {
    "Total": "Points",
    "AST": "Assists",
    "REB": "Rebounds",
    "BLK": "Blocks",
    "STL": "Steals",
    "Pts+Ast": "Pts+Ast",
    "Pts+Reb": "Pts+Reb",
    "Ast+Reb": "Ast+Reb",
    "Pts+Ast+Reb": "Pts+Ast+Reb"
  };

  useEffect(() => {
    const fetchImpactData = async () => {
      if (selectedFeature && token) {
        const playerInfo = combinedInfo[selectedPlayer];
        const isOpponentSelected = selectedTeam === playerInfo.opponentName;
        
        const homeImpactResults = {};
        const opponentImpactResults = {};

        if (!isOpponentSelected && homeTeamData.length > 0) {
          for (const item of homeTeamData) {
            try {
              const endpoint = `player-impact/get_player_impact/`;
              const params = new URLSearchParams({
                main_player_id: selectedPlayer,
                impact_player: item.player_id,
                team_name: teamNameToFullName[playerInfo.teamName],
                feature: featureMap[selectedFeature]
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              homeImpactResults[item.player_id] = data.impact_value !== undefined
                ? Math.round(data.impact_value * 10) / 10
                : null;
            } catch (error) {
              console.error(`Error fetching impact for player ${item.player_id}:`, error);
            }
          }
          setHomeImpactData(homeImpactResults);
        }

        if (isOpponentSelected && opponentTeamData.length > 0) {
          for (const item of opponentTeamData) {
            try {
              const endpoint = `team-impact/get_team_impact/`;
              const params = new URLSearchParams({
                player_name: item.player_id, // Use player_id here as specified
                team_name: teamNameToFullName[selectedTeam],
                feature: featureMap[selectedFeature]
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              opponentImpactResults[item.player_id] = data.impact_allowed_value !== undefined
                ? Math.round(Math.abs(data.impact_allowed_value) * 10) / 10
                : null;
            } catch (error) {
              console.error(`Error fetching impact for player ${item.player_id}:`, error);
            }
          }
          setOpponentImpactData(opponentImpactResults);
        }
      }
    };

    fetchImpactData();
  }, [homeTeamData, opponentTeamData, selectedFeature, selectedPlayer, selectedTeam, token]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  const teamColor = teamColors[0] || '#000';
  const highlightColor = teamColors[1] || '#fff';

  const renderImpactText = (impactValue) => {
    if (impactValue === null || impactValue === 0 || isNaN(impactValue)) return 'No impact';
    return impactValue > 0
      ? `Allows ${impactValue} more ${visualFeatureMap[selectedFeature]}`
      : `Allows ${Math.abs(impactValue)} less ${visualFeatureMap[selectedFeature]}`;
  };

  return (
    <div className="injury-report-container" style={{ backgroundColor: 'white' }}>
      <div className="table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="injury-logo" />
        <div className="title">Injury Report</div>
        <div className="selected-team-container" onClick={() => setIsEditing(!isEditing)}>
          <div className="selected-team">{selectedTeam || "Select team..."}</div>
          <div className="dropdown-arrow-injury-report">▼</div>
        </div>
        {isEditing && (
          <div className="autocomplete-injury-table-container" style={{ backgroundColor: teamColor }}>
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="autocomplete-injury-table-item"
                onClick={() => handleTeamChange(team)}
                style={{ backgroundColor: teamColor, color: 'white' }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = highlightColor}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = teamColor}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Home Team Impact Table */}
      {selectedTeam === combinedInfo[selectedPlayer]?.teamName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {homeTeamData.length > 0 ? (
                homeTeamData.map((item, index) => {
                  const impactValue = homeImpactData[item.player_id];
                  const impactText = impactValue === null || impactValue === 0 || isNaN(impactValue)
                    ? 'No impact'
                    : impactValue > 0
                      ? `Gets ${impactValue} more ${visualFeatureMap[selectedFeature]}`
                      : `Gets ${Math.abs(impactValue)} less ${visualFeatureMap[selectedFeature]}`;

                  return (
                    <tr key={index}>
                      <td>{item.player_id || 'N/A'}</td>
                      <td>
                        <span className={`status-indicator ${item.status === 'Day-To-Day' ? 'status-day-to-day' : item.status === 'Out' ? 'status-out' : ''}`}></span>
                        {item.status || 'N/A'}
                      </td>
                      <td>{impactText}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Opponent Team Impact Table */}
      {selectedTeam === combinedInfo[selectedPlayer]?.opponentName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {opponentTeamData.length > 0 ? (
                opponentTeamData.map((item, index) => {
                  const impactAllowedValue = opponentImpactData[item.player_id]; // Access impact using player_id
                  const impactText = renderImpactText(impactAllowedValue);

                  return (
                    <tr key={index}>
                      <td>{item.player_id || 'N/A'}</td>
                      <td>
                        <span className={`status-indicator ${item.status === 'Day-To-Day' ? 'status-day-to-day' : item.status === 'Out' ? 'status-out' : ''}`}></span>
                        {item.status || 'N/A'}
                      </td>
                      <td>{impactText}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InjuryReportTable;
