// TicketAnalyzer.js
import React, { useState, useEffect } from 'react';
import AvailablePlayers from '../ticket_analyzer_components/AvailablePlayers';
import SelectedPlayers from '../ticket_analyzer_components/SelectedPlayers';
import ParlayBuilder from '../ticket_analyzer_components/ParlayBuilder';
import { useKeycloak } from '../KeycloakProvider';
import { v4 as uuidv4 } from 'uuid';
import './TicketAnalyzer.css';

const TicketAnalyzer = () => {
  const { token, keycloakId } = useKeycloak();
  const [playerIds, setPlayerIds] = useState(() => {
    const cachedSelectedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
    console.log("Loaded player IDs from savedPlayers cache:", cachedSelectedPlayers);
    return cachedSelectedPlayers.map(player => player.playerId);
  });

  const [parlayPlayers, setParlayPlayers] = useState(() => {
    const cachedParlayPlayers = JSON.parse(localStorage.getItem('parlayPlayers')) || [];
    console.log("Loaded parlay players from cache:", cachedParlayPlayers);
    return cachedParlayPlayers;
  });

  const monthMapping = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const formatDateString = (dateStr) => {
    const [monthName, day] = dateStr.split(" ");
    const month = monthMapping[monthName];
    const year = new Date().getFullYear(); // Current year
    return `${year}-${month}-${day.padStart(2, '0')}`; // Format as YYYY-MM-DD
  };

  const updatePlayerDetails = (playerId, field, value) => {
    const updatedPlayers = parlayPlayers.map(player => 
      player.playerId === playerId ? { ...player, [field]: value } : player
    );
    console.log(`Updating ${field} for playerId ${playerId} to value:`, value);
    setParlayPlayers(updatedPlayers);
    localStorage.setItem('parlayPlayers', JSON.stringify(updatedPlayers));
  };

  const addToParlayBuilder = (player) => {
    console.log("Player data received in addToParlayBuilder:", player);
    const playerExists = parlayPlayers.some(p => p.playerId === player.playerId);

    if (!playerExists) {
      const formattedDate = formatDateString(player.date);
      console.log("Formatted date for storage:", formattedDate);

      const playerWithFormattedDate = {
        ...player,
        date: formattedDate,
      };

      console.log("Adding new player to parlay with formatted date:", playerWithFormattedDate);
      const updatedParlayPlayers = [...parlayPlayers, playerWithFormattedDate];
      setParlayPlayers(updatedParlayPlayers);
      localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlayPlayers));
    } else {
      console.log("Player already exists in parlay, skipping add:", player);
    }
  };

  const removeFromSelectedPlayers = (playerId) => {
    const updatedPlayerIds = playerIds.filter(id => id !== playerId);
    console.log("Removing playerId from selected players:", playerId);
    setPlayerIds(updatedPlayerIds);

    const updatedPlayers = JSON.parse(localStorage.getItem('savedPlayers')).filter(p => p.playerId !== playerId);
    localStorage.setItem('savedPlayers', JSON.stringify(updatedPlayers));
    console.log("Updated savedPlayers cache after removal:", updatedPlayers);
  };

  const clearParlay = () => {
    console.log("Clearing parlay players.");
    setParlayPlayers([]);
    localStorage.removeItem('parlayPlayers');
  };

  const [ticketId] = useState(() => uuidv4()); // Generate ticketId once on mount
  console.log("Generated ticketId:", ticketId);

  return (
    <div className="ticket-analyzer-wrapper">
      <div className="ticket-analyzer-layout">
        <AvailablePlayers setPlayerIds={setPlayerIds} />
        <div className="main-content">
          <SelectedPlayers 
            playerIds={playerIds} 
            addToParlayBuilder={(player) => {
              console.log("Passing player to addToParlayBuilder with date and time:", player.date, player.time);
              addToParlayBuilder(player);
            }}
            removeFromSelectedPlayers={removeFromSelectedPlayers}
            updatePlayerDetails={updatePlayerDetails}
          />
          <ParlayBuilder 
            parlayPlayers={parlayPlayers} 
            removeFromParlay={(name) => {
              console.log("Removing player from parlay by name:", name);
              setParlayPlayers(parlayPlayers.filter(p => p.name !== name));
              const updatedParlay = parlayPlayers.filter(p => p.name !== name);
              localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlay));
              console.log("Updated parlayPlayers after removal:", updatedParlay);
            }}
            clearParlay={clearParlay} // Clear both state and localStorage
            keycloakId={keycloakId}
            ticketId={ticketId}
            token={token}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketAnalyzer;
