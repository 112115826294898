import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { teamsDict } from '../../components/TeamInfo';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import './TeamComparisonBarChart.css';
import useFetchTeamChartData from '../../team_dashboard_components/data/TeamPointsDrilldownData';
import { useKeycloak } from '../../KeycloakProvider'; // Import the Keycloak context

// Function to calculate contrast ratio
const getContrastRatio = (color1, color2) => {
  const getLuminance = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

// Function to select the most distinct away team color
const selectMostDistinctColor = (homeColor, awayColors) => {
  let maxContrast = 0;
  let distinctColor = awayColors[0];

  awayColors.forEach((color) => {
    const contrast = getContrastRatio(homeColor, color);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      distinctColor = color;
    }
  });

  return distinctColor;
};

const features = [
  { key: 'team_points', label: 'PPG' },
  { key: 'team_ast', label: 'APG' },
  { key: 'team_reb', label: 'RPG' },
  { key: 'team_stl', label: 'STL' },
  { key: 'team_fg3_pct', label: '3PT%' },
  { key: 'team_ft_pct', label: 'FT%' },
  { key: 'team_fg_pct', label: 'FG%' },
  { key: 'team_blk', label: 'BLK' },
  { key: 'team_tov', label: 'TOV' },
];

const TeamComparisonBarChart = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const [selectedFeature, setSelectedFeature] = useState('team_points');
  const [options, setOptions] = useState({});
  const { token } = useKeycloak(); // Get the token from Keycloak
  const homeTeamData = useFetchTeamChartData(homeTeamAbbr, token, 15);
  const awayTeamData = useFetchTeamChartData(awayTeamAbbr, token, 15);

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const homeTeamFullName = reverseTeamAbbreviation[homeTeamAbbr];
  const awayTeamFullName = reverseTeamAbbreviation[awayTeamAbbr];

  const homeTeamInfo = teamsDict[homeTeamFullName];
  const awayTeamInfo = teamsDict[awayTeamFullName];

  const homeTeamColor = homeTeamInfo.colors[0] || '#ff7f0e';
  const awayTeamColor = selectMostDistinctColor(homeTeamColor, awayTeamInfo.colors);

  useEffect(() => {
    if (homeTeamData[selectedFeature] && awayTeamData[selectedFeature]) {
      const homeData = homeTeamData[selectedFeature];
      const awayData = awayTeamData[selectedFeature];
      const allValues = [...homeData, ...awayData];
      const maxValue = Math.max(...allValues);
      const minValue = 0;

      const newOptions = {
        title: {
          text: 'Team Comparison - Last 15 Games',
          left: 'center',
          top: 0
        },
        legend: {
          data: [homeTeamFullName, awayTeamFullName],
          top: 30,
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: homeTeamData.Dates.map((date, index) => `Game ${index + 1}`),
          axisLabel: {
            interval: 0,
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          max: maxValue + (maxValue * 0.1),
          min: Math.max(0, minValue - (minValue * 0.1))
        },
        series: [
          {
            name: homeTeamFullName,
            type: 'bar',
            data: homeData,
            itemStyle: {
              color: homeTeamColor
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}',
              fontSize: 10,
              color: '#333'
            }
          },
          {
            name: awayTeamFullName,
            type: 'bar',
            data: awayData,
            itemStyle: {
              color: awayTeamColor
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}',
              fontSize: 10,
              color: '#333'
            }
          }
        ]
      };
      setOptions(newOptions);
    }
  }, [selectedFeature, homeTeamData, awayTeamData, homeTeamFullName, awayTeamFullName, homeTeamColor, awayTeamColor]);

  return (
    <div className="team-comparison-bar-chart-container">
      <div className="team-comparison-bar-feature-button-container">
        {features.map((feature) => (
          <button
            key={feature.key}
            onClick={() => setSelectedFeature(feature.key)}
            className={`team-comparison-bar-feature-button ${
              selectedFeature === feature.key ? 'selected' : ''
            }`}
          >
            {feature.label}
          </button>
        ))}
      </div>
      {homeTeamData[selectedFeature] && awayTeamData[selectedFeature] ? (
        <ReactECharts option={options} style={{ height: '400px' }} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default TeamComparisonBarChart;
