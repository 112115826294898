import React, { useState, useEffect } from 'react';
import { fetchData } from '../../api';
import { teamsDict } from '../../components/TeamInfo';
import './SpreadBar.css';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { useKeycloak } from '../../KeycloakProvider';
import teamNameToFullName from '../../components/TeamNameToFullName';

const getContrastRatio = (color1, color2) => {
  const getLuminance = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

const selectMostDistinctColor = (homeColor, awayColors) => {
  let maxContrast = 0;
  let distinctColor = awayColors[0];

  awayColors.forEach((color) => {
    const contrast = getContrastRatio(homeColor, color);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      distinctColor = color;
    }
  });

  return distinctColor;
};

const SpreadBar = ({ awayTeam, homeTeam }) => {
  const [spread, setSpread] = useState(null);
  const { token } = useKeycloak();

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const homeTeamFullName = reverseTeamAbbreviation[homeTeam];
  const awayTeamFullName = reverseTeamAbbreviation[awayTeam];

  const homeTeamInfo = teamsDict[homeTeamFullName];
  const awayTeamInfo = teamsDict[awayTeamFullName];

  const homeColor = homeTeamInfo.colors[0] || '#FF6347';
  const awayColor = selectMostDistinctColor(homeColor, awayTeamInfo.colors);

  useEffect(() => {
    const fetchSpreadData = async () => {
      const endpoint = `spread-odds/filter_by_team_and_date/?team=${teamNameToFullName[homeTeamFullName]}`;
      try {
        const data = await fetchData(endpoint, token);
        if (data && data.spread_points !== undefined) {
          setSpread(data.spread_points);
        }
      } catch (error) {
        console.error('Error fetching spread data:', error);
      }
    };

    fetchSpreadData();
  }, [homeTeamFullName, token]);

  if (!homeTeamInfo || !awayTeamInfo) {
    return <div>Loading...</div>;
  }

  // Calculate bar widths based on spread points, defaulting to 50% each if spread is null
  let homeWidth = 50;
  let awayWidth = 50;
  let formattedSpread = "0";

  if (spread !== null) {
    formattedSpread = spread > 0 ? `+${spread}` : spread.toString();
    const spreadAdjustment = Math.min(Math.abs(spread) * 5, 100); // Scale factor to control width difference

    if (spread > 0) {
      homeWidth = 50 + spreadAdjustment / 2;
      awayWidth = 100 - homeWidth;
    } else {
      awayWidth = 50 + spreadAdjustment / 2;
      homeWidth = 100 - awayWidth;
    }
  }

  return (
    <div className="spread-bar-container">
      <div className="spread-bar-wrapper">
        <div className="spread-bar">
          <div className="spread-home" style={{ backgroundColor: homeColor, width: `${homeWidth}%` }}></div>
          <div className="spread-away" style={{ backgroundColor: awayColor, width: `${awayWidth}%` }}></div>
          <div className="spread-text">{formattedSpread} Spread</div>
        </div>
      </div>
    </div>
  );
};

export default SpreadBar;
