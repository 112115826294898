import { fetchData } from '../../api';  // Assuming you have a fetchData utility
import { teamNameToAbbreviation } from '../../components/TeamDictionary';

export const fetchDataTeams = async (teamAbbreviation, opponent = "", token) => {
  try {
   
    const response = await fetchData(
      `teams/filter_by_team/?team_abbreviation=${teamAbbreviation}&opponent=${opponent}&x=5`,
      token
    );
   
    // Ensure data is valid
    if (response && Array.isArray(response) && response.length > 0) {
      return response.map((item) => ({
        game_date: item.game_date,
        team_points: item.team_pts,
        team_abbreviation: item.team_abbreviation,
        opponent: item.opponent,
        team_ast: item.team_ast,
        team_reb: item.team_reb,
        team_blk: item.team_blk,
        team_stl: item.team_stl,
        team_ftm: item.team_ftm,
        team_fg3m: item.team_fg3m,
        team_fg2m: item.team_fg2m,
      }));
    } else {
      console.warn("No team data found or data is empty.");
      return [];  // Return an empty array if response data is empty
    }
  } catch (error) {
    console.error("Error fetching team data:", error);
    return [];  // Return an empty array in case of error
  }
};

export const fetchTeamPlayers = async (teamAbbreviation, gameDate, opponent = "", token) => {
  try {

    const response = await fetchData(
      `teams/filter_by_team_and_date/?team_abbreviation=${teamAbbreviation}&game_date=${gameDate}&opponent=${opponent}`,
      token
    );
    // Ensure data is valid
    if (response && Array.isArray(response) && response.length > 0) {
      return response.map((item) => ({
        player_name: item.player_name,
        pts: item.pts,
        opponent: item.opponent,
        ast: item.ast,
        reb: item.reb,
        blk: item.blk,
        stl: item.stl,
        ftm: item.ftm,
        fg2m: item.fg2m,
        fg3m: item.fg3m,
        team_abbreviation: `team_${teamAbbreviation}`,
      }));
    } else {
      console.warn("No player data found or data is empty.");
      return [];  // Return an empty array if response data is empty
    }
  } catch (error) {
    console.error("Error fetching team players data:", error);
    return [];  // Return an empty array in case of error
  }
};
