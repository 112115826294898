// ParlayBuilder.js
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import './ParlayBuilder.css';

const ParlayBuilder = ({ parlayPlayers, removeFromParlay, clearParlay, keycloakId, ticketId, token }) => {

  const confirmParlay = async () => {
    if (parlayPlayers.length === 0) {
      alert("Add players to parlay before confirming.");
      return;
    }

    try {
      await fetchData('user-picks/', token, 'POST', { 
        keycloak_id: keycloakId,
        ticket_id: ticketId 
      });

      await Promise.all(parlayPlayers.map(player => {
        const payload = {
          ticket_id: ticketId,
          keycloak_id: keycloakId,
          player_bet_on: player.name,
          opponent: player.opponentName,
          game_date: player.date,
          odds: player.odds,
          threshold: player.line,
          over_under: player.pick,
          prediction: player.prediction || 23.5,
          probability: player.probability,
          bet_type: 'parlay',
        };

        console.log("BetDetail Payload:", payload); // Confirm payload details
        return fetchData('bet-details/', token, 'POST', payload);
      }));

      alert("Parlay confirmed and saved.");
      clearParlay();

    } catch (error) {
      console.error("Error saving parlay:", error);
      alert("An error occurred while saving your parlay. Please try again.");
    }
  };

  return (
    <div className="parlay-builder-container">
      <div className="parlay-builder-header">
        <h3>Parlay Builder</h3>
        <div className="parlay-builder-top-buttons">
          <button className="parlay-builder-refresh-btn" onClick={clearParlay}>🔄</button>
        </div>
      </div>

      <span className="parlay-builder-legs">{parlayPlayers.length} Legs</span>

      {!parlayPlayers.length ? (
        <div className="parlay-builder-empty-message">Add players to parlay</div>
      ) : (
        <table className="parlay-builder-table">
          <thead>
            <tr>
              <th>Player</th>
              <th>Pick</th>
              <th>Odds</th>
              <th>Threshold</th>
              <th>Probability</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {parlayPlayers.map((player, index) => (
              <tr key={index}>
                <td>{player.name}</td>
                <td>{player.pick}</td>
                <td>{player.odds}</td>
                <td>{player.line}</td>
                <td>{player.probability}%</td>
                <td>
                  <button onClick={() => removeFromParlay(player.name)} className="parlay-builder-remove-btn">
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="parlay-builder-bottom">
        <button className="parlay-builder-confirm-btn" onClick={confirmParlay}>Confirm</button>
      </div>
    </div>
  );
};

export default ParlayBuilder;
