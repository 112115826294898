// SelectedPlayers.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlayerColors } from './main_dashboard_components/PlayerColorContext';
import { players } from './components/PlayerInfo';
import './SelectedPlayers.css';

const SelectedPlayers = () => {
  const { cart, removeFromCart, setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState('L1'); // Default to L1
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedPlayerForPopup, setSelectedPlayerForPopup] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');

  // Load saved players from localStorage
  const [savedPlayers, setSavedPlayers] = useState(() => {
    const cachedPlayers = localStorage.getItem('savedPlayers');
    return cachedPlayers ? JSON.parse(cachedPlayers) : [];
  });

  useEffect(() => {
    // Save the savedPlayers to localStorage whenever it changes
    localStorage.setItem('savedPlayers', JSON.stringify(savedPlayers));
  }, [savedPlayers]);

  const handlePlayerSelect = (playerId) => {
    setSelectedPlayer(playerId);
    navigate('/PlayerDashboard');
  };

  // Helper function to convert month names to numbers
  const monthToNumber = {
    January: '01', February: '02', March: '03', April: '04',
    May: '05', June: '06', July: '07', August: '08',
    September: '09', October: '10', November: '11', December: '12'
  };

  // Helper function to format date for storage (YYYY-MM-DD)
  const formatDateForStorage = (displayDate) => {
    if (!displayDate) return null;

    // Split displayDate into parts and remove ordinal suffix
    const [month, dayWithSuffix] = displayDate.split(' ');
    const day = dayWithSuffix.replace(/(st|nd|rd|th)/, ''); // Remove suffixes
    const monthNumber = monthToNumber[month];
    const currentYear = new Date().getFullYear();

    return `${currentYear}-${monthNumber}-${day.padStart(2, '0')}`; // Format as YYYY-MM-DD
  };

  // Save player to cache with pick and line, ensuring no duplicates
  const saveToCache = (player) => {
    const isDuplicate = savedPlayers.some(
      (p) =>
        p.playerId === player.playerId &&
        p.feature === player.feature &&
        p.lineType === player.lineType &&
        p.line === player.line &&
        p.propType === player.propType 
    );

    if (isDuplicate) {
      setPopupMessage('This player and pick combination is already saved.');
      setPopupVisible(true);
      return;
    }

    // Format date for storage
    const formattedDate = formatDateForStorage(player.date);

    const updatedSavedPlayers = [
      ...savedPlayers,
      {
        ...player,
        opponentName: players[player.playerId]?.opponentName || 'Unknown Opponent',
        date: formattedDate, // Save as YYYY-MM-DD
      },
    ];
    
    setSavedPlayers(updatedSavedPlayers);
    setPopupMessage('Player successfully added!');
    setPopupVisible(true);

    // Remove from cart after adding to cache
    removeFromCart(player.playerId);
  };

  const showAddConfirmation = (player) => {
    setSelectedPlayerForPopup(player);
    setPopupVisible(true);
  };

  const handlePopupAction = (confirm) => {
    if (confirm && selectedPlayerForPopup) {
      saveToCache(selectedPlayerForPopup);
    }
    setPopupVisible(false);
    setPopupMessage('');
    setSelectedPlayerForPopup(null);
  };

  const renderBar = (value, max, line, color, isPositive) => {
    const widthPercentage = (value / max) * 100;
    const linePosition = (line / max) * 100;
    const circleColor = isPositive ? '#00ff00' : '#ff0000';

    return (
      <div className="select-player-bar-container">
        <div className="select-player-bar" style={{ width: `${widthPercentage}%`, backgroundColor: color }}>
          <div className="select-player-line-dot" style={{ left: `calc(${linePosition}% - 5px)`, backgroundColor: circleColor }}></div>
        </div>
        <span className={`select-player-bar-value ${isPositive ? 'positive' : 'negative'}`}>{value}</span>
      </div>
    );
  };

  const renderFeatureName = (name) => {
    return `${name} (${selectedPeriod})`;
  };

  const renderPlayerCard = (player, index) => {
    const playerInfo = players[player.playerId];
    if (!playerInfo) {
      console.error('No player info found for playerId:', player.playerId);
      return <div key={index} className="select-player-card">Player info not found</div>;
    }

    const teamColor = playerInfo.teamColors[0];
    const mutedColor = `${teamColor}99`; // Adding 99 for a muted color (slightly transparent)

    const stats = {
      L1: { points: 26, homeAway: 23.5, vsComp: 25.2, lastVsTeam: 24 },
      L3: { points: 28, homeAway: 24.7, vsComp: 28.7, lastVsTeam: 24.7 },
      L7: { points: 27, homeAway: 26.3, vsComp: 27.9, lastVsTeam: 25.1 },
      L15: { points: 29, homeAway: 25.5, vsComp: 29.0, lastVsTeam: 26.5 },
    };
    const currentStats = stats[selectedPeriod];

    return (  
      <div key={index} className="select-player-card">
        <div className="select-player-card-left">
          <div
            className="select-player-info-header"
            style={{
              background: `linear-gradient(to top, rgba(30, 30, 30, 0.8), ${teamColor})`,
            }}
          >
            <img src={playerInfo.imageSrc} alt={`${playerInfo.name}`} className="select-player-image" />
            <img src={playerInfo.teamLogoSrc} alt="Team Logo" className="select-player-team-logo" />
          </div>
          <div className="select-player-details">
            <h2>{playerInfo.name}</h2>
            <p className="select-player-matchup">
              {playerInfo.home_or_away === 1 ? '@' : 'vs'} {playerInfo.opponentName}
              <span className="select-player-game-time">
                {player.date} {player.time}
              </span>
            </p>
            <p className="select-player-referee">
              <span className="select-player-scott-foster">Scott Foster</span>
              <br />
              <span className="select-player-referee-label">Referee</span>
            </p>
            <hr />
            <div className="select-player-prediction-pick-container">
              <div>
                <div className="select-player-prediction">Prop</div>
                <div className="select-player-prediction">Prediction</div>
                <div className="select-player-line">Line</div>
                <div className="select-player-pick">Pick</div>
              </div>
              <div>
                <div className="select-player-prop">{player.propType}</div>
                <div className="select-player-prediction-value" style={{ color: teamColor }}>
                  {currentStats.points.toFixed(1)}
                </div>
                <div className="select-player-line-value" style={{ color: teamColor }}>
                  {player.line}
                </div>
                <div className="select-player-pick-value" style={{ color: teamColor }}>
                  {player.lineType.toUpperCase()}
                </div>
              </div>
            </div>
            <hr />
            <div className="select-player-comment">
              <strong>Comment:</strong> <span>{player.comment || 'No comment available'}</span>
            </div>
          </div>
        </div>
        <div className="select-player-card-right">
          <div className="select-player-button-container">
            {['L1', 'L3', 'L7', 'L15'].map((period) => (
              <button
                key={period}
                className={`select-player-top-button ${selectedPeriod === period ? 'active' : ''}`}
                style={{
                  backgroundColor: selectedPeriod === period ? teamColor : mutedColor,
                }}
                onClick={() => setSelectedPeriod(period)}
              >
                {period}
              </button>
            ))}
          </div>
          <div className="select-player-feature-section">
            <h3>{renderFeatureName('Points')}</h3>
            {renderBar(currentStats.points, 30, player.line, teamColor, true)}
          </div>
          <hr />
          <div className="select-player-feature-section">
            <h3>{renderFeatureName('Home/Away')}</h3>
            {renderBar(currentStats.homeAway, 30, player.line, teamColor, false)}
          </div>
          <hr />
          <div className="select-player-feature-section">
            <h3>{renderFeatureName('Vs Team Comp')}</h3>
            {renderBar(currentStats.vsComp, 30, player.line, teamColor, true)}
          </div>
          <hr />
          <div className="select-player-feature-section">
            <h3>{renderFeatureName('Last vs Team')}</h3>
            {renderBar(currentStats.lastVsTeam, 30, player.line, teamColor, true)}
          </div>
        </div>
        <button onClick={() => showAddConfirmation(player)} className="select-player-add-btn">
          +
        </button>
        <button onClick={() => removeFromCart(player.playerId)} className="select-player-remove-btn">
          X
        </button>
      </div>
    );
  };

  const renderPlayerRows = () => {
    const rows = [];
    for (let i = 0; i < cart.length; i += 2) {
      rows.push(
        <div className="select-player-row-container" key={i}>
          {renderPlayerCard(cart[i], i)}
          {cart[i + 1] && renderPlayerCard(cart[i + 1], i + 1)}
        </div>
      );
    }
    return rows;
  };

  return (
    <div className="select-player-parent-container">
      {renderPlayerRows()}
      {popupVisible && (
        <>
          <div className="popup-overlay" />
          <div className="popup">
            <p>{popupMessage || 'Do you want to add this player?'}</p>
            {selectedPlayerForPopup ? (
              <div className="popup-buttons">
                <button onClick={() => handlePopupAction(true)}>Yes</button>
                <button onClick={() => handlePopupAction(false)}>No</button>
              </div>
            ) : (
              <button onClick={() => setPopupVisible(false)}>OK</button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedPlayers;
