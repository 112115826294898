import React from 'react';
import PreviousBetsTable from '../ticket_watcher_components/PreviousBets';
import './TicketWatcher.css';

function TicketWatcher() {
  return (
    <div className="ticket-watcher-container">
      <PreviousBetsTable />
    </div>
    
  );
}

export default TicketWatcher;
