import React, { useState, useMemo } from 'react';
import useFetchPlayerProps from './data/PlayerPropData';  // Custom hook to fetch player prop data
import { usePlayerColors } from './PlayerColorContext';  // Context for getting selected player
import { players } from '../components/PlayerInfo';  // Import the players object for player name lookup
import './PlayerPropsTable.css';  // Import the CSS file

const PlayerPropsTable = ({ selectedFeature }) => {
  const { selectedPlayer } = usePlayerColors();  // Get selected player ID from context

  // Lookup the full player name using the selectedPlayer ID
  const playerName = players[selectedPlayer]?.name || "Unknown Player";
  const playerProps = useFetchPlayerProps(selectedFeature, playerName);  // Fetch data based on selection

  // State to manage sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Function to handle sorting
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to get sort indicator class
  const getSortClass = (name) => {
    if (sortConfig.key === name) {
      return sortConfig.direction === 'ascending' ? 'sort-ascending' : 'sort-descending';
    }
    return '';
  };

  // Memoized sorted data
  const sortedPlayerProps = useMemo(() => {
    if (playerProps && playerProps.length > 0 && sortConfig.key) {
      return [...playerProps].sort((a, b) => {
        // Handle date sorting separately
        if (sortConfig.key === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return sortConfig.direction === 'ascending'
            ? dateA - dateB
            : dateB - dateA;
        }

        // For other fields
        const valueA = a[sortConfig.key];
        const valueB = b[sortConfig.key];

        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return playerProps;
  }, [playerProps, sortConfig]);

  return (
    <div className="player-props-container">
      <table className="player-props-table">
        <thead>
          <tr>
            <th
              onClick={() => requestSort('sportsbook')}
              className={getSortClass('sportsbook')}
            >
              Sportsbook
            </th>
            <th
              onClick={() => requestSort('over_threshold')}
              className={getSortClass('over_threshold')}
            >
              Over Threshold
            </th>
            <th
              onClick={() => requestSort('over_odds')}
              className={getSortClass('over_odds')}
            >
              Over Odds
            </th>
            <th
              onClick={() => requestSort('under_threshold')}
              className={getSortClass('under_threshold')}
            >
              Under Threshold
            </th>
            <th
              onClick={() => requestSort('under_odds')}
              className={getSortClass('under_odds')}
            >
              Under Odds
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayerProps && sortedPlayerProps.map((prop, index) => (
            <tr key={index}>
              <td>{prop.sportsbook}</td>
              <td>{prop.over_threshold}</td>
              <td>{prop.over_odds}</td>
              <td>{prop.under_threshold}</td>
              <td>{prop.under_odds}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerPropsTable;
