// utils/playerLookup.js
import playerData from './player_info.json'; // Adjust the path to your JSON file

const createPlayerLookup = () => {
  const lookup = {};
  playerData.forEach(player => {
    lookup[player.player_name] = player.player_id;
  });
  return lookup;
};

const playerLookup = createPlayerLookup();

export const getPlayerId = (playerName) => {
  return playerLookup[playerName];
};
