import React, { useState, useEffect } from 'react';
import { fetchData } from '../api'; // Import the API function
import { teamsDict } from '../components/TeamInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './MatchupHeader.css';
import { useKeycloak } from '../KeycloakProvider'; // Import the Keycloak context

// Function to calculate contrast ratio
const getContrastRatio = (color1, color2) => {
  const getLuminance = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

// Function to select the most distinct away team color
const selectMostDistinctColor = (homeColor, awayColors) => {
  let maxContrast = 0;
  let distinctColor = awayColors[0];

  awayColors.forEach((color) => {
    const contrast = getContrastRatio(homeColor, color);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      distinctColor = color;
    }
  });

  return distinctColor;
};

// New function to get ordinal suffix
const getOrdinalSuffix = (number) => {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

const MatchupHeader = ({ awayTeam, homeTeam, time, venue }) => {
  const [homeTeamStats, setHomeTeamStats] = useState({});
  const [awayTeamStats, setAwayTeamStats] = useState({});
  const { token } = useKeycloak(); // Get the token from Keycloak
  const [venueName, location] = venue.split(',');

  // Convert team abbreviations to full team names
  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const homeTeamFullName = reverseTeamAbbreviation[homeTeam];
  const awayTeamFullName = reverseTeamAbbreviation[awayTeam];

  const homeTeamInfo = teamsDict[homeTeamFullName];
  const awayTeamInfo = teamsDict[awayTeamFullName];

  useEffect(() => {
    const fetchTeamStats = async (teamId) => {
      const metrics = ['standings', 'w', 'l', 'last_5_wl'];
      const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
      const endpoint = `team_overall_stats/?team_id=${teamId}&${queryString}`;

      try {
        const data = await fetchData(endpoint, token);
        return data;
      } catch (error) {
        console.error('Error fetching team stats:', error);
        return {};
      }
    };

    const fetchDataForTeams = async () => {
      const homeTeamId = teamsDict[homeTeamFullName].team_id;
      const awayTeamId = teamsDict[awayTeamFullName].team_id;

      const [homeData, awayData] = await Promise.all([
        fetchTeamStats(homeTeamId),
        fetchTeamStats(awayTeamId)
      ]);

      setHomeTeamStats(homeData);
      setAwayTeamStats(awayData);
    };

    fetchDataForTeams();
  }, [homeTeamFullName, awayTeamFullName, token]);

  if (!homeTeamInfo || !awayTeamInfo) {
    return <div>Error: Team information not found.</div>;
  }

  // Select colors
  const homePrimaryColor = homeTeamInfo.colors[0];
  const awayPrimaryColor = selectMostDistinctColor(homePrimaryColor, awayTeamInfo.colors);
  const homeSecondaryColor = homeTeamInfo.colors[1] || homePrimaryColor;
  const awaySecondaryColor = awayTeamInfo.colors.find(c => c !== awayPrimaryColor) || awayPrimaryColor;

  return (
    <div className="matchup-header-wrapper">
      <div className="matchup-header-container">
        
        {/* Left Side: Outer Border Container (Home Team) */}
        <div className="matchup-header-left-border" style={{ backgroundColor: homeSecondaryColor }}>
          <div
            className="matchup-header-left-side"
            style={{ backgroundColor: homePrimaryColor }}
          >
            <img src={homeTeamInfo.logo} alt={`${homeTeamFullName} logo`} className="matchup-header-left-logo" />
            <div className="matchup-header-left-info">
              <div className="matchup-header-left-name">{homeTeamFullName}</div>
              <div className="matchup-header-left-record">{homeTeamStats.w}-{homeTeamStats.l}</div>
              <div className="matchup-header-left-rank">
                {homeTeamStats.standings}{getOrdinalSuffix(homeTeamStats.standings)}
              </div>
              <div className="matchup-header-left-streak">
                {homeTeamStats.last_5_wl?.split('').map((result, index) => (
                  <span key={index} className={`matchup-header-left-${result.toLowerCase()}`}>
                    {result}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Middle Section */}
        <div className="matchup-header-info">
          <div className="matchup-header-date">{time}</div>
          <div className="matchup-header-venue">
            <div>{venueName}</div>
            <div>{location?.trim()}</div>
          </div>
        </div>

        {/* Right Side: Outer Border Container (Away Team) */}
        <div className="matchup-header-right-border" style={{ backgroundColor: awaySecondaryColor }}>
          <div
            className="matchup-header-right-side"
            style={{ backgroundColor: awayPrimaryColor }}
          >
            <div className="matchup-header-right-info">
              <div className="matchup-header-right-name">{awayTeamFullName}</div>
              <div className="matchup-header-right-record">{awayTeamStats.w}-{awayTeamStats.l}</div>
              <div className="matchup-header-right-rank">
                {awayTeamStats.standings}{getOrdinalSuffix(awayTeamStats.standings)}
              </div>
              <div className="matchup-header-right-streak">
                {awayTeamStats.last_5_wl?.split('').map((result, index) => (
                  <span key={index} className={`matchup-header-right-${result.toLowerCase()}`}>
                    {result}
                  </span>
                ))}
              </div>
            </div>
            <img src={awayTeamInfo.logo} alt={`${awayTeamFullName} logo`} className="matchup-header-right-logo right-aligned-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchupHeader;