// Import NBA schedule data directly from the JSON file
import nbaSchedule from './nba_schedule.json'; // Adjust the path as needed
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { fetchData } from '../../api'; // Import your API function

const teamNameToAbbr = (teamName) => {
  return teamNameToAbbreviation[teamName] || teamName;
};

const generateTeamSchedule = async (teamAbbr, token) => {
  let schedule = [];
  const currentDate = new Date();
  const startDate = '2024-10-10'; // Season start date

  try {
    const endpoint = `teams/filter_by_team_date_range/?start_date=${startDate}&team_abbreviation=${teamAbbr}`;
    const resultsData = await fetchData(endpoint, token);
    const resultsMap = {};

    if (resultsData && resultsData.length > 0) {
      resultsData.forEach(result => {
        const gameDate = new Date(result.game_date);
        resultsMap[gameDate.toISOString().split('T')[0]] = result;
      });
    }

    nbaSchedule.forEach(game => {
      const gameDate = new Date(game.Date);
      const formattedGameDate = gameDate.toISOString().split('T')[0];
      const homeTeam = teamNameToAbbr(game["Home Team"]);
      const awayTeam = teamNameToAbbr(game["Away Team"]);

      if (homeTeam === teamAbbr || awayTeam === teamAbbr) {
        const opponent = homeTeam === teamAbbr ? awayTeam : homeTeam;
        const location = homeTeam === teamAbbr ? "Home" : "Away";
        let teamPts = "-";
        let opponentPts = "-";
        let pointsTotal = "-";
        let won = "-";

        if (resultsMap[formattedGameDate]) {
          const gameResult = resultsMap[formattedGameDate];
          teamPts = gameResult.team_pts;
          opponentPts = gameResult.team_pts_allowed;
          pointsTotal = teamPts + opponentPts;
          won = teamPts > opponentPts;
        }

        schedule.push({
          date: `${gameDate.getDate().toString().padStart(2, '0')}/${(gameDate.getMonth() + 1).toString().padStart(2, '0')}`,
          opponent,
          location,
          score: `${teamPts}-${opponentPts}`,
          pointsTotal,
          won,
          teamPts,
          opponentPts
        });
      }
    });

  } catch (error) {
    console.error('Error fetching game results:', error);
  }

  return schedule;
};

export const scheduleData = async (selectedTeam, token) => {
  if (!selectedTeam || !token) {
    console.error('Selected team and token must be provided.');
    return null;
  }

  const teamSchedule = await generateTeamSchedule(selectedTeam, token);
  return { [selectedTeam]: teamSchedule };
};
