// SelectedPlayers.js
import React, { useState, useEffect } from 'react';
import './SelectedPlayers.css';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { players } from '../components/PlayerInfo';

const SelectedPlayers = ({ playerIds = [], addToParlayBuilder, removeFromSelectedPlayers }) => {
  const [showModal, setShowModal] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);
  const [playerData, setPlayerData] = useState({});
  const [editingField, setEditingField] = useState({});

  useEffect(() => {
    const cachedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
    const initializedData = playerIds.reduce((acc, playerId) => {
      const player = players[playerId];
      const cacheItem = cachedPlayers.find(item => item.playerId === playerId);

      // Format date for display (remove year) but keep the original date for cache
      const formattedDate = cacheItem?.date ? formatDate(cacheItem.date) : null;

      acc[playerId] = {
        prediction: '27',
        probability: '63',
        odds: '1.91',
        propType: cacheItem?.propType || 'unknown',
        line: cacheItem?.line,
        pick: cacheItem?.lineType || 'Over', // Initial pick value from cache
        opponentName: cacheItem?.opponentName || player?.opponentName || 'Opponent',
        name: player?.name || 'Unknown Player',
        date: formattedDate,
        time: cacheItem?.time || 'N/A', // Assuming time is stored in cache
      };
      return acc;
    }, {});
    setPlayerData(initializedData);
  }, [playerIds]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) return 'Invalid Date';
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Display without year
  };

  const handleDeleteClick = (playerId) => {
    setPlayerToRemove(playerId);
    setShowModal(true);
  };

  const confirmRemovePlayer = () => {
    removeFromSelectedPlayers(playerToRemove);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setPlayerToRemove(null);
  };

  const handleInputChange = (playerId, field, value) => {
    setPlayerData(prevData => ({
      ...prevData,
      [playerId]: {
        ...prevData[playerId],
        [field]: value,
      },
    }));
  };

  const handleFieldClick = (playerId, field) => {
    setEditingField({ playerId, field });
  };

  const handleBlur = () => {
    setEditingField({});
  };

  return (
    <div className="selected-players-container">
      <h3 className="selected-players-title">Selected Players</h3>
      {playerIds.length > 0 ? (
        <table className="selected-players-table">
          <thead>
            <tr>
              <th>Players</th>
              <th>Opponent</th>
              <th>Prediction</th>
              <th>Probability</th>
              <th>Bookie</th>
              <th>Odds</th>
              <th>Pick</th>
              <th>Date/Time</th>
              <th>Add/Remove</th>
            </tr>
          </thead>
          <tbody>
            {playerIds.map((playerId, index) => {
              const player = players[playerId];
              const playerFields = playerData[playerId];
              if (!player || !playerFields) return null;

              return (
                <tr key={index}>
                  <td className="selected-players-player-info">
                    <div
                      className="selected-players-logo-container"
                      style={{ backgroundColor: player.teamColors[0] }}
                    >
                      <img src={player.imageSrc} alt={player.name} className="selected-players-player-logo" />
                    </div>
                    <div className="selected-players-details">
                      <span className="selected-players-name">{player.name}</span>
                      <br />
                      <span className="selected-players-points">{playerFields.propType} {playerFields.line}</span>
                    </div>
                  </td>
                  <td>{playerFields.opponentName}<br />{player.home_or_away === 1 ? "Home" : "Away"}</td>
                  <td>{playerFields.prediction}</td>
                  <td>{playerFields.probability}</td>
                  <td>
                    <img src="/bookie_logos/draftkings.png" alt="DraftKings" className="selected-players-bookie-logo" />
                  </td>
                  <td>
                    {editingField.playerId === playerId && editingField.field === 'odds' ? (
                      <input
                        type="text"
                        value={playerFields.odds}
                        onChange={(e) => handleInputChange(playerId, 'odds', e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-input"
                      />
                    ) : (
                      <span onClick={() => handleFieldClick(playerId, 'odds')}>
                        {playerFields.odds}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingField.playerId === playerId && editingField.field === 'pick' ? (
                      <select
                        value={playerFields.pick}
                        onChange={(e) => handleInputChange(playerId, 'pick', e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-select"
                      >
                        <option value="Over">Over</option>
                        <option value="Under">Under</option>
                      </select>
                    ) : (
                      <span onClick={() => handleFieldClick(playerId, 'pick')}>
                        {playerFields.pick}
                      </span>
                    )}
                  </td>
                  <td>
                    {playerFields.date} {playerFields.time} {/* Display date and time */}
                  </td>
                  <td className="selected-players-add-remove-buttons">
                    <button
                      className="selected-players-add-btn"
                      onClick={() => addToParlayBuilder({ ...player, ...playerFields })}
                    >
                      <FaPlus />
                    </button>
                    <button 
                      className="selected-players-remove-btn"
                      onClick={() => handleDeleteClick(playerId)}
                    >
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No selected players available.</p>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure you want to remove this player?</h3>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={confirmRemovePlayer}>Yes</button>
              <button className="cancel-btn" onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedPlayers;
