import { teamsDict } from './TeamInfo';
import schedule from '../matchup_dashboard_components/data/nba_schedule.json'; // Import raw JSON file

// Helper function to generate random picks and top picks
const generateMockPicks = () => {
  return {
    picks: Math.floor(Math.random() * 60) + 30, // Random picks between 30 and 90
    topPicks: Math.floor(Math.random() * 15) + 5 // Random top picks between 5 and 20
  };
};

// Helper function to generate a matchup object
const generateMatchup = (homeTeam, awayTeam, date, time, picks, topPicks) => {
  return {
    matchup: `${awayTeam} @ ${homeTeam}`, // Away team vs Home team format
    date,
    time,
    picks,
    topPicks
  };
};

// Function to convert "hh:mm am/pm ET" format to 24-hour format (e.g., "22:00")
const convertTo24HourFormat = (timeString) => {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier.toLowerCase() === 'pm' && hours !== '12') {
    hours = String(parseInt(hours, 10) + 12);
  } else if (modifier.toLowerCase() === 'am' && hours === '12') {
    hours = '00';
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};

// Function to extract the first occurrence of each team's matchup for today's date
const extractFirstMatchupsForToday = (schedule) => {
  const seenTeams = new Set();
  const matchups = [];

  // Get today's date in the expected format (e.g., "2024-11-07" for November 7th, 2024)
  const today = new Date().toISOString().split('T')[0];

  schedule.forEach(game => {
    const { 'Home Team': homeTeam, 'Away Team': awayTeam, Date: date, 'Tip-off Time': time } = game;

    // Skip games not on today's date or with "TBD" time
    if (date !== today || time === 'TBD') {
      return;
    }

    // Only add the first occurrence of a matchup for each team
    if (!seenTeams.has(homeTeam) && !seenTeams.has(awayTeam)) {
      seenTeams.add(homeTeam);
      seenTeams.add(awayTeam);

      const { picks, topPicks } = generateMockPicks(); // Generate mock picks and top picks
      const time24Hour = convertTo24HourFormat(time);
      matchups.push(generateMatchup(homeTeam, awayTeam, date, time24Hour, picks, topPicks));
    }
  });

  // Sort the matchups by time (earliest first)
  return matchups.sort((a, b) => a.time.localeCompare(b.time));
};

// Generate today's matchups
export const matchups = extractFirstMatchupsForToday(schedule);
