import React, { useState, useEffect, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import './PerformanceTracker.css';
import fakeBetData from './data/fakeBetData'; // Import your fake data

// Utility function to aggregate data by day, week, or month
const aggregateBetData = (data, view, filters) => {
  const aggregatedData = {};

  // Filter data based on the filters passed from the selector
  const filteredData = data.filter((bet) => {
    const matchesSportsbook = filters.sportsbook ? bet.sportsbook === filters.sportsbook : true;
    const matchesTeam = filters.team ? bet.team === filters.team : true;
    const matchesDateRange = filters.dateRange.startDate && filters.dateRange.endDate
      ? (new Date(bet.date) >= new Date(filters.dateRange.startDate) && new Date(bet.date) <= new Date(filters.dateRange.endDate))
      : true;

    return matchesSportsbook && matchesTeam && matchesDateRange;
  });

  filteredData.forEach((bet) => {
    const betDate = new Date(bet.date);
    let key;

    // Generate the appropriate key based on the selected view (daily/weekly/monthly)
    if (view === 'weekly') {
      const startOfWeek = new Date(betDate.setDate(betDate.getDate() - betDate.getDay()));
      key = startOfWeek.toISOString().split('T')[0]; // Get the start date of the week
    } else if (view === 'monthly') {
      key = `${betDate.getFullYear()}-${(betDate.getMonth() + 1).toString().padStart(2, '0')}`; // Year-Month format
    } else {
      key = betDate.toISOString().split('T')[0]; // Use day for daily aggregation
    }

    // Initialize the key if it doesn't exist
    if (!aggregatedData[key]) {
      aggregatedData[key] = { youWins: 0, youTotal: 0, modelWins: 0, modelTotal: 0 };
    }

    // Count the total bets and wins for "you" and "model"
    if (bet.bet_type === 'you') {
      aggregatedData[key].youTotal += 1;
      if (bet.result === 'win') {
        aggregatedData[key].youWins += 1;
      }
    } else if (bet.bet_type === 'model') {
      aggregatedData[key].modelTotal += 1;
      if (bet.result === 'win') {
        aggregatedData[key].modelWins += 1;
      }
    }
  });

  // Convert aggregated data into arrays for the chart
  const dates = Object.keys(aggregatedData);
  const youData = dates.map((date) =>
    aggregatedData[date].youTotal > 0 ? (aggregatedData[date].youWins / aggregatedData[date].youTotal) * 100 : 0
  );
  const modelData = dates.map((date) =>
    aggregatedData[date].modelTotal > 0 ? (aggregatedData[date].modelWins / aggregatedData[date].modelTotal) * 100 : 0
  );



  return { dates, youData, modelData };
};



const PerformanceTracker = ({ filters }) => {
  const [view, setView] = useState('daily'); // State for toggling between views

  // Filter and aggregate data based on the view (daily, weekly, monthly) and filters
  const getData = useMemo(() => {
    // Apply filters for sportsbook, team, and date range
    const filteredData = fakeBetData.filter((bet) => {
      const betDate = new Date(bet.date);
      const startDate = filters?.dateRange?.startDate ? new Date(filters.dateRange.startDate) : new Date('2024-03-01');
      const endDate = filters?.dateRange?.endDate ? new Date(filters.dateRange.endDate) : new Date('2024-08-31');

      const isWithinDateRange = betDate >= startDate && betDate <= endDate;
      const matchesSportsbook = !filters?.sportsbook || bet.sportsbook === filters.sportsbook;
      const matchesTeam = !filters?.team || bet.team === filters.team;

      return isWithinDateRange && matchesSportsbook && matchesTeam;
    });

    return aggregateBetData(filteredData, view, filters);
  }, [view, filters]);

  // Create the chart options based on filters and view
  const option = useMemo(() => {
    const data = getData;

    let series = [
      {
        name: 'Profit Line',
        type: 'line',
        data: Array(data.dates.length).fill(54.5), // Set the profit line to 54.5% for all dates
        lineStyle: {
          color: '#AAAAAA', // Light vertical line color
          width: 2,
          type: 'solid',
        },
        smooth: true, // Make the line a spline (curved)
        symbol: 'none', // Remove points
      },
    ];

    if (filters.view === 'you' || filters.view === 'both') {
      series.push({
        name: 'You',
        type: 'bar',
        data: data.youData,
        itemStyle: {
          color: '#FF00FF', // Set color for the "You" bars
        },
      });
    }

    if (filters.view === 'model' || filters.view === 'both') {
      series.push({
        name: 'Model',
        type: 'bar',
        data: data.modelData,
        itemStyle: {
          color: '#0000FF', // Set color for the "Model" bars
        },
      });
    }

    return {
      xAxis: {
        type: 'category',
        data: data.dates,
        axisLine: {
          lineStyle: {
            color: '#666', // Axis color
          },
        },
        axisLabel: {
          interval: 'auto', // Equal spacing of bars on the x-axis
          rotate: 45, // Rotate labels for better readability
        },
      },
      yAxis: {
        type: 'value',
        min: 0, // Adjust minimum value based on your data
        interval: 10, // Adjust interval to your needs
        axisLine: {
          lineStyle: {
            color: '#666', // Axis color
          },
        },
        splitLine: {
          show: true, // Show grid lines
          lineStyle: {
            color: '#ddd', // Grid line color
          },
        },
      },
      series, // Use the series created dynamically
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        show: true,
        top: '5%', // Move legend closer to the top
        textStyle: {
          color: '#333', // Using dark text color
        },
        icon: 'circle', // Change legend symbol to dots
        itemWidth: 10, // Adjust dot size
        itemHeight: 10,
        itemGap: 15,
      },
      color: ['#AAAAAA', '#FF00FF', '#0000FF'], // Set colors explicitly for legend and lines
      grid: {
        top: '20%', // Adjust grid to give more space for the legend and title
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true,
      },
    };
  }, [filters.view, getData]);

  return (
    <div className="model-performance-performance-tracker">
      <h2 className="model-performance-performance-tracker-title">Performance Tracker</h2>

      <div className="view-toggle-buttons">
        <button className={view === 'daily' ? 'active' : ''} onClick={() => setView('daily')}>Daily</button>
        <button className={view === 'weekly' ? 'active' : ''} onClick={() => setView('weekly')}>Weekly</button>
        <button className={view === 'monthly' ? 'active' : ''} onClick={() => setView('monthly')}>Monthly</button>
      </div>

      <ReactECharts
        key={`${filters.view}-${view}`} // Key forces re-render and reinitialization
        option={option}
        opts={{ renderer: 'svg', devicePixelRatio: 2 }}
        className="model-performance-echarts"
      />
    </div>
  );
};

export default PerformanceTracker;
