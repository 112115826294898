import React from 'react';
import './RecentTrends.css';

const ROIBox = ({ value, label, isPositive, dollarValue }) => {
  return (
    <div className={`model-performance-roi-box ${isPositive ? 'positive' : 'negative'}`}>
      <div className="model-performance-roi-label">{label}</div>
      <div className="model-performance-roi-value">{value}%</div>
      <div className="model-performance-dollar-value">{dollarValue}</div> {/* New dollar value section */}
    </div>
  );
};

const RecentTrends = ({ aggregates }) => {
  const modelIsPositive = aggregates.modelROI >= 0;
  const youIsPositive = aggregates.youROI >= 0;

  return (
    <div className="model-performance-recent-trends">
      <ROIBox
        value={aggregates.modelROI.toFixed(2)}
        label="ROI Model"
        isPositive={modelIsPositive}
        dollarValue={`${modelIsPositive ? '$' : '-$'}${Math.abs(aggregates.modelROI * 100).toFixed(2)}`} // Assume some scaling for dollar value
      />
      <ROIBox
        value={aggregates.youROI.toFixed(2)}
        label="ROI You"
        isPositive={youIsPositive}
        dollarValue={`${youIsPositive ? '$' : '-$'}${Math.abs(aggregates.youROI * 100).toFixed(2)}`}
      />
    </div>
  );
};

export default RecentTrends;
