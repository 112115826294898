import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const Signup = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) {
      window.location.href = keycloak.createRegisterUrl();
    }
  }, [keycloak]);

  return keycloak.authenticated ? <div>Loading...</div> : <div>Redirecting to signup...</div>;
};

export default Signup;
