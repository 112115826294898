import React, { useEffect, useState } from 'react';
import { fetchData } from '../api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TicketViewer.css';
import { getPlayerId } from '../components/PlayerLookup';
import { teamNameToAbbreviation } from '../components/TeamDictionary';

const TicketViewer = ({ token, keycloakId }) => {
  const [tickets, setTickets] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [endDate, setEndDate] = useState(new Date());
  const [results, setResults] = useState({});

  const fetchTickets = async () => {
    try {
      const start = startDate.toISOString().split('T')[0];
      const end = endDate.toISOString().split('T')[0];

      const queryParams = new URLSearchParams({
        start_date: start,
        end_date: end,
        keycloak_id: keycloakId,
      });

      const url = `user-picks/filter_tickets_view/?${queryParams.toString()}`;
      const response = await fetchData(url, token, 'GET');
      console.log("Fetched tickets:", response);

      if (response && response.tickets) {
        setTickets(response.tickets);
        fetchResultsForTickets(response.tickets);
      } else {
        console.error('No tickets found in the response.');
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const fetchResultsForTickets = async (tickets) => {
    const resultsData = {};

    await Promise.all(
      tickets.map(async (ticket) => {
        const playerId = getPlayerId(ticket.player_bet_on);
        const metrics = ["pts"];
        const opponent = teamNameToAbbreviation[ticket.opponent];

        const queryParams = new URLSearchParams({
          player_id: playerId,
          metrics: metrics.join(","),
          x: 1,
          opponent: opponent,
        });

        const url = `players/filter_by_player/?${queryParams.toString()}`;
        const resultData = await fetchData(url, token, 'GET');

        if (resultData && resultData.length > 0) {
          resultsData[ticket.id] = resultData[0].pts;
        } else {
          resultsData[ticket.id] = "DNP"; // Default message if no result found
        }
      })
    );

    setResults(resultsData);
  };

  const groupTicketsByTicketId = (tickets) => {
    return tickets.reduce((acc, ticket) => {
      if (!acc[ticket.ticket_id]) {
        acc[ticket.ticket_id] = [];
      }
      acc[ticket.ticket_id].push(ticket);
      return acc;
    }, {});
  };

  const groupedTickets = groupTicketsByTicketId(tickets);

  const getResultStyle = (ticket, result) => {
    if (result === "DNP") return { color: "--medium-text-color" };

    const isOver = ticket.over_under === "Over";
    const isWin = isOver ? result < ticket.threshold : result > ticket.threshold;

    return {
      color: isWin ? "green" : "red",
    };
  };

  return (
    <div className="ticket-viewer-container">
      <h2>Your Tickets</h2>

      <div className="date-picker-container">
        <label>Start Date:</label>
        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
        <label>End Date:</label>
        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        <button className="apply-filter-btn" onClick={fetchTickets}>Apply Filter</button>
      </div>

      <div className="ticket-cards-container">
        {Object.keys(groupedTickets).length > 0 ? (
          Object.keys(groupedTickets).map((ticketId) => {
            const ticketGroup = groupedTickets[ticketId];

            return (
              <div key={ticketId} className="ticket-card">
                <h3>Ticket ID: {ticketId}</h3>
                <table className="ticket-table">
                  <thead>
                    <tr>
                      <th>Player</th>
                      <th>Opponent</th>
                      <th>Date</th>
                      <th>Odds</th>
                      <th>Threshold</th>
                      <th>Pick</th>
                      <th>Prediction</th>
                      <th>Probability</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketGroup.map((ticket) => {
                      const result = results[ticket.id] || "Loading...";
                      return (
                        <tr key={ticket.id}>
                          <td>{ticket.player_bet_on}</td>
                          <td>{ticket.opponent}</td>
                          <td>{ticket.game_date}</td>
                          <td>{ticket.odds}</td>
                          <td>{ticket.threshold}</td>
                          <td>{ticket.over_under}</td>
                          <td>{ticket.prediction}</td>
                          <td>{ticket.probability}%</td>
                          <td style={getResultStyle(ticket, result)}>
                            {result}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })
        ) : (
          <p>No tickets found for the selected date range.</p>
        )}
      </div>
    </div>
  );
};

export default TicketViewer;
