// TeamDashboard.js
import React, { useState, useEffect } from 'react';
import TeamCard from '../team_dashboard_components/TeamCard';
import { TeamColorProvider } from "../team_dashboard_components/TeamColorContext";
import NavigationMenu from '../components/NavigationMenu';
import TeamVersus from '../main_dashboard_components/TeamVersus';
import InjuryReportTable from '../main_dashboard_components/InjuryReportTable';
import TeamPointsDrilldown from '../team_dashboard_components/TeamPointsDrilldown';
import TeamStatsTable from '../team_dashboard_components/TeamStatsTable';
import VersusTeamChart from '../team_dashboard_components/TeamVersusTeamChart';

import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import ShootingChart from '../main_dashboard_components/RollingFeature';
import PlayerShare from '../team_dashboard_components/PlayerShare';
import TeamLogs from '../team_dashboard_components/TeamLogs';
import SimilarTeamPerformance from '../team_dashboard_components/SimilarTeams';
import OpponentDefense from '../team_dashboard_components/OpponentDefense';
import DefenseTable from '../team_dashboard_components/DefenseTable';
import './TeamDashboard.css';
import TeamDefense from '../team_dashboard_components/TeamDefense';
import PositionDefense from '../main_dashboard_components/PositionDefense';

const TeamDashboard = ({ showSum }) => {
  const { setSelectedPlayer } = usePlayerColors();
  const [yAxisValues, setYAxisValues] = useState({});
  const [selectedFeature, setSelectedFeature] = useState('Total');

  const handleTeamSelect = (teamId) => {
    setSelectedPlayer(teamId);
  };

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };

  const features = ['Total', 'AST', 'REB', 'Pts+Ast', 'Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb', 'BLK', 'STL'];

  useEffect(() => {
    // Initialize Application Insights tracking
    (function () {
      const methods = ["trackEvent", "trackException", "trackMetric", "trackPageView", "trackTrace", "setAuthenticatedUserContext", "clearAuthenticatedUserContext"];
      const appInsights = window.appInsights || function (config) {
        function trackFunc(func) {
          return function () {
            const args = arguments;
            appInsights.queue.push(function () { appInsights[func].apply(appInsights, args); });
          };
        }
        const appInsights = { config: config }, d = document, scriptElem = d.createElement("script");
        function setup() { for (let i = 0; i < methods.length; i++) appInsights[methods[i]] = trackFunc(methods[i]); }
        scriptElem.src = config.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js";
        d.getElementsByTagName("head")[0].appendChild(scriptElem);
        setup();
        appInsights.queue = [];
        appInsights.version = "1.0";
        return appInsights;
      }({
        instrumentationKey: "c7343360-3bcb-41b7-bc98-d73910686819"
      });
      window.appInsights = appInsights;

      // Track a page view event for TeamDashboard
      appInsights.trackPageView({
        name: "TeamDashboard",
        properties: { component: "TeamDashboard" }
      });
    })();
  }, []);

  return (
    <div className="team-dashboard-container">
      <div className="team-dashboard-content">
        <div className="team-dashboard-row">
          <div className="team-dashboard-square-container">
            <TeamColorProvider>
              <TeamCard onTeamSelect={handleTeamSelect} />
            </TeamColorProvider>
          </div>
          <div className="team-dashboard-tonights-game-container">
            <InjuryReportTable />
          </div>
          <div className="team-dashboard-square-container">
            <TeamVersus showSum={showSum} />
          </div>
        </div>

        <div className="team-dashboard-feature-menu">
          {features.map(feature => (
            <div
              key={feature}
              className="team-dashboard-feature-item"
              onClick={() => handleFeatureClick(feature)}
            >
              {feature}
            </div>
          ))}
        </div>

        <div className="team-dashboard-row-higher">
          <div className="team-points-drilldown">
            <TeamPointsDrilldown selectedFeature={selectedFeature} />
          </div>
          <div className="team-stats-table">
            <TeamStatsTable selectedFeature={selectedFeature} />
          </div>
        </div>
        <div className="team-dashboard-row-higher">
          <div className="team-dashboard-versus-team-chart">
            <VersusTeamChart selectedFeature={selectedFeature} />
          </div>
          <div className="team-dashboard-player-share">
            <PlayerShare selectedFeature={selectedFeature} />
          </div>
        </div>

        <div className="team-dashboard-row-higher">
          <div className="team-dashboard-opponent-defense">
            <TeamDefense selectedFeature={selectedFeature} yAxisValues={yAxisValues} />
          </div>
          <div className="team-dashboard-opponent-defense">
            <OpponentDefense selectedFeature={selectedFeature} setYAxisValues={setYAxisValues} />
          </div>
        </div>

        <div className="team-dashboard-row-higher">
          <div className="team-logs-historical-dashboard-container">
            <TeamLogs selectedFeature={selectedFeature} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDashboard;
