import React from 'react';
import MatchupInjuryReportTable from '../../matchup_dashboard_components//matchup_info_components/MatchupInjuryReportTable';
import TeamVsTeamTable from '../../matchup_dashboard_components/matchup_info_components/TeamVsTeamTable';
import Schedule from '../../matchup_dashboard_components/Schedule';
import TopPicks from '../../matchup_dashboard_components/matchup_info_components/TopPicks';

const MatchupInformation = ({ matchup, homeTeamAbbr, awayTeamAbbr }) => {
  return (
    <div className="columns-container">
      {/* Left Column */}
      <div className="left-column">
        <div className="left-container top-left">
          <TeamVsTeamTable matchup={matchup} />
        </div>
      </div>

      {/* Middle Column */}
      <div className="middle-column">
        <div className="middle-container middle-top-1">
          <MatchupInjuryReportTable
            homeTeam={homeTeamAbbr}
            awayTeam={awayTeamAbbr}
          />
        </div>
        <div className="middle-container middle-bottom">
          <TopPicks homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
        </div>
      </div>

      {/* Right Column */}
      <div className="right-column">
        <div className="right-container full-length-right">
          <Schedule awayTeamAbbr={awayTeamAbbr} homeTeamAbbr={homeTeamAbbr} />
        </div>
      </div>
    </div>
  );
};

export default MatchupInformation;
