import React, { useState, useEffect } from 'react';
import './FilterSelector.css';
import fakeBetData from './data/fakeBetData'; // Import the fakeBetData

const FilterSelector = ({ onFilterChange }) => {
  // Calculate the min and max dates from the data
  const minDate = new Date(Math.min(...fakeBetData.map((bet) => new Date(bet.date))));
  const maxDate = new Date(Math.max(...fakeBetData.map((bet) => new Date(bet.date))));

  // Calculate the default date range (last month from max date)
  const defaultEndDate = maxDate;
  const defaultStartDate = new Date(new Date(maxDate).setMonth(maxDate.getMonth() - 1));

  const [selectedSportsbook, setSelectedSportsbook] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [dateRange, setDateRange] = useState({ startDate: defaultStartDate.toISOString().split('T')[0], endDate: defaultEndDate.toISOString().split('T')[0] });
  const [selectedView, setSelectedView] = useState('both'); // Default to 'both'

  useEffect(() => {
    // Initialize with default date range and other filters
    onFilterChange({ sportsbook: selectedSportsbook, team: selectedTeam, dateRange, view: selectedView });
  }, [selectedSportsbook, selectedTeam, dateRange, selectedView]);

  const handleSportsbookChange = (e) => {
    setSelectedSportsbook(e.target.value);
    onFilterChange({ sportsbook: e.target.value, team: selectedTeam, dateRange, view: selectedView });
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
    onFilterChange({ sportsbook: selectedSportsbook, team: e.target.value, dateRange, view: selectedView });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const updatedDateRange = { ...dateRange, [name]: value };
    setDateRange(updatedDateRange);
    onFilterChange({ sportsbook: selectedSportsbook, team: selectedTeam, dateRange: updatedDateRange, view: selectedView });
  };

  const handleViewChange = (e) => {
    setSelectedView(e.target.value);
    onFilterChange({ sportsbook: selectedSportsbook, team: selectedTeam, dateRange, view: e.target.value });
  };

  return (
    <div className="filter-selector-container">
      {/* View Selector */}
      <div className="view-selector">
        <label>
          <input 
            type="radio" 
            value="model" 
            checked={selectedView === 'model'} 
            onChange={handleViewChange} 
          />
          Model
        </label>
        <label>
          <input 
            type="radio" 
            value="you" 
            checked={selectedView === 'you'} 
            onChange={handleViewChange} 
          />
          You
        </label>
        <label>
          <input 
            type="radio" 
            value="both" 
            checked={selectedView === 'both'} 
            onChange={handleViewChange} 
          />
          Both
        </label>
      </div>

      {/* Sportsbook Selector */}
      <div className="filter-item">
        <label>Sportsbook</label>
        <select value={selectedSportsbook} onChange={handleSportsbookChange}>
          <option value="">All Sportsbooks</option>
          <option value="DraftKings">DraftKings</option>
          <option value="FanDuel">FanDuel</option>
          <option value="BetMGM">BetMGM</option>
        </select>
      </div>
      
      {/* Team Selector */}
      <div className="filter-item">
        <label>Team</label>
        <select value={selectedTeam} onChange={handleTeamChange}>
          <option value="">All Teams</option>
          <option value="Lakers">Lakers</option>
          <option value="Celtics">Celtics</option>
          <option value="Warriors">Warriors</option>
        </select>
      </div>
      
      {/* Date Range Selector */}
      <div className="filter-item">
        <label>Start Date</label>
        <input 
          type="date" 
          name="startDate" 
          min={minDate.toISOString().split('T')[0]} 
          max={maxDate.toISOString().split('T')[0]} 
          value={dateRange.startDate} 
          onChange={handleDateChange} 
        />
        <label>End Date</label>
        <input 
          type="date" 
          name="endDate" 
          min={minDate.toISOString().split('T')[0]} 
          max={maxDate.toISOString().split('T')[0]} 
          value={dateRange.endDate} 
          onChange={handleDateChange} 
        />
      </div>
    </div>
  );
};

export default FilterSelector;
