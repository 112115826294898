import React from 'react';
import './MatchupPlayerInfo.css';

const MatchupPlayerInfo = ({ playerData, color1, color2, color3 }) => {
  // Default values for when playerData is incomplete or missing
  const defaultName = 'N/A';
  const defaultPosition = 'N/A';
  const defaultNumber = '00';
  const defaultImageSrc = '/api/placeholder/200/200';
  const defaultStats = '0.0';

  // Function to abbreviate the name
  const abbreviateName = (name) => {
    if (!name) return defaultName;
    
    // Split the name by spaces and dashes
    const nameParts = name.split(/[\s-]+/);
    
    if (nameParts.length === 1) {
      // If there's only one part, return the first two characters
      return name.slice(0, 2) + '.';
    } else {
      // Get the first letter of each part except the last
      const initials = nameParts.slice(0, -1).map(part => part[0].toUpperCase()).join('.');
      // Get the last name
      const lastName = nameParts[nameParts.length - 1];
      
      return `${initials}. ${lastName}`;
    }
  };

  // Check if playerData and playerData.name exist before trying to access them
  const abbreviatedName = playerData && playerData.name
    ? abbreviateName(playerData.name)
    : defaultName;

  return (
    <div className="matchup-players-player-info">
      <div className="matchup-players-color-split">
        {/* Position */}
        <div
          className="matchup-players-color-box"
          style={{ backgroundColor: color2, color: color1 || '#000000' }}
        >
          <span className="matchup-players-position">{playerData?.position || defaultPosition}</span>
        </div>

        {/* Number */}
        <div
          className="matchup-players-color-box"
          style={{ backgroundColor: color3, color: color1 || '#000000' }}
        >
          <span className="matchup-players-number">#{playerData?.number || defaultNumber}</span>
        </div>

        <img
          src={playerData?.imageSrc || defaultImageSrc}
          alt={playerData?.name || 'Player'}
          className="matchup-players-player-image"
        />
      </div>

      {/* Abbreviated Name */}
      <div className="matchup-players-player-name" style={{ backgroundColor: color1 || '#000000' }}>
        {abbreviatedName}
      </div>

      {/* Stats */}
      <div className="matchup-players-player-stats" style={{ backgroundColor: color1 || '#000000' }}>
        <div className="matchup-players-stat-label">PPG</div>
        <div className="matchup-players-stat-value">{playerData?.ppg || defaultStats}</div>
        <div className="matchup-players-stat-label">APG</div>
        <div className="matchup-players-stat-value">{playerData?.apg || defaultStats}</div>
        <div className="matchup-players-stat-label">RPG</div>
        <div className="matchup-players-stat-value">{playerData?.rpg || defaultStats}</div>
      </div>
    </div>
  );
};

export default MatchupPlayerInfo;