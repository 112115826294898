import React from 'react';
import { Link } from 'react-router-dom';
import './PremiumFeature.css';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { Lock } from 'lucide-react';

const PremiumFeature = () => {
  const { teamColors, selectedPlayer } = usePlayerColors();
  const primaryColor = teamColors[0] || '#4f46e5';
  const secondaryColor = teamColors[1] || '#818cf8';
  const playerName = 'Top Picks';

  const data = [
    { bet: 'Locked', pick: 'Locked', date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }), probability: 0.8 },
    { bet: 'Locked', pick: 'Locked', date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }), probability: 0.6 },
    { bet: 'Locked', pick: 'Locked', date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }), probability: 0.56 },
    { bet: 'Locked', pick: 'Locked', date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }), probability: 0.9 },
    { bet: 'Locked', pick: 'Locked', date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }), probability: 0.65 },
  ];

  return (
    <div className="premium-feature-container">
      <div className="premium-feature-header">
        <h2>{playerName}</h2>
        <span className="premium-feature-subtext">(buy premium to unlock)</span>
        <Link to="/premium" className="premium-feature-premium" style={{ backgroundColor: secondaryColor }}>Unlock</Link>
      </div>
      <table className="premium-feature-table">
        <thead>
          <tr>
            <th>Bet</th>
            <th>Pick</th>
            <th>Date</th>
            <th>Probability</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td><Lock size={16} color={secondaryColor} /> {row.bet}</td>
              <td><Lock size={16} color={secondaryColor} /> {row.pick}</td>
              <td>{row.date}</td>
              <td>
                <div className="premium-feature-probability-bar">
                  <div 
                    className="premium-feature-probability-bar-inner" 
                    style={{ width: `${row.probability * 100}%`, backgroundColor: primaryColor }}
                  ></div>
                  <span className="premium-feature-probability-tooltip">{(row.probability * 100).toFixed(1)}%</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PremiumFeature;