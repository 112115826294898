// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useKeycloak, KeycloakProvider } from './KeycloakProvider';
import PlayerDashboard from './dashboards/PlayerDashboard';
import TeamDashboard from './dashboards/TeamDashboard';
import PlayerID from './main_dashboard_components/PlayerID';
import { PlayerColorProvider } from './main_dashboard_components/PlayerColorContext';
import Leaderboard from './Leaderboard';
import Login from './authentication_components/Login';
import Signup from './authentication_components/Signup';
import HomePage from './HomePage';
import SelectedPlayers from './SelectedPlayers';
import HeatMap from './PlayerHeatmap';
import TicketWatcher from './dashboards/TicketWatcher';
import TicketAnalyzer from './dashboards/TicketAnalyzer';
import ModelPerformance from './dashboards/ModelPerformance';
import MatchupDashboard from './dashboards/MatchupDashboard';
import './App.css';
import MatchupMenu from './components/MatchupMenu';
import NavigationMenu from './components/NavigationMenu';
import TicketsPage from './dashboards/TicketsPage';

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { authenticated, loading } = useKeycloak();

  if (loading) {
    return <div>Loading...</div>;
  }

  return authenticated ? children : <Navigate to="/login" replace />;
};

// Main App component
const App = () => {
  const [showMatchupMenu, setShowMatchupMenu] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setShowMatchupMenu(window.scrollY <= 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <KeycloakProvider>
      <PlayerColorProvider>
        <Router>
          <div className="App">
            <MatchupMenu visible={showMatchupMenu} />
            <NavigationMenu />

            <Routes>
              {/* Public route */}
              <Route path="/" element={<HomePage />} />

              {/* Protected routes */}
              <Route
                path="/PlayerDashboard"
                element={
                  <ProtectedRoute>
                    <PlayerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/MatchupDashboard"
                element={
                  <ProtectedRoute>
                    <MatchupDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PlayerDashboard/:playerName"
                element={
                  <ProtectedRoute>
                    <PlayerID />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TeamDashboard"
                element={
                  <ProtectedRoute>
                    <TeamDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Leaderboard"
                element={
                  <ProtectedRoute>
                    <Leaderboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/ModelPerformance"
                element={
                  <ProtectedRoute>
                    <ModelPerformance />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketWatcher"
                element={
                  <ProtectedRoute>
                    <TicketWatcher />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketAnalyzer"
                element={
                  <ProtectedRoute>
                    <TicketAnalyzer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/SelectedPlayers"
                element={
                  <ProtectedRoute>
                    <SelectedPlayers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/HeatMap"
                element={
                  <ProtectedRoute>
                    <HeatMap />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Tickets"
                element={
                  <ProtectedRoute>
                    <TicketsPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </PlayerColorProvider>
    </KeycloakProvider>
  );
};

export default App;
