import React from 'react';
import './TopPicks.css';
import { topPicksData } from '../data/topPicksData';
import { abbreviationToTeamName } from '../../components/TeamDictionary';
import { players } from '../../components/PlayerInfo';

const TopPicks = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const homeTeamName = abbreviationToTeamName[homeTeamAbbr];
  const awayTeamName = abbreviationToTeamName[awayTeamAbbr];

  const matchPicks = topPicksData.filter(pick => 
    pick.team === homeTeamName || pick.team === awayTeamName
  );

  if (matchPicks.length === 0) {
    return <div>No top picks available for this matchup.</div>;
  }

  const sortedPicks = matchPicks.sort((a, b) => b.probability - a.probability);
  const top8Picks = sortedPicks.slice(0, 8);

  const formatPlayerName = (name) => {
    if (name.length > 15) {
      const [firstName, ...lastNames] = name.split(' ');
      return `${firstName.charAt(0)}. ${lastNames.join(' ')}`;
    }
    return name;
  };

  const renderPropString = (prop) => {
    return `${prop.line} ${prop.pick.toUpperCase()}`;
  };

  const renderPickRow = (pick) => {
    const propTypes = ['points', 'rebounds', 'assists'];
    const randomPropType = propTypes[Math.floor(Math.random() * propTypes.length)];
    const prop = pick.props[randomPropType];
    const playerInfo = players[pick.player_id];
    const logoBackgroundColor = playerInfo ? playerInfo.teamColors[0] : '#000000';

    return (
      <tr key={`${pick.team}-${pick.playerName}`}>
        <td>
          <div className="top-picks-player">
            <div className="player-logo-container" style={{ backgroundColor: logoBackgroundColor }}>
              <img
                src={`/player_logos/${pick.player_id}.png`}
                alt={pick.playerName}
                className="player-logo"
              />
            </div>
            <div className="player-info">
              <span className="player-name">{formatPlayerName(pick.playerName)}</span>
              <span className="player-prop">{renderPropString(prop)} {randomPropType.charAt(0).toUpperCase() + randomPropType.slice(1)}</span>
            </div>
          </div>
        </td>
        <td>{prop.prediction}</td>
        <td>{(Number(pick.probability) * 100).toFixed(0)}%</td>
        <td>{pick.odds}</td>
      </tr>
    );
  };

  return (
    <div className="top-picks-container">
      <h2 style={{marginLeft:'5px'}}>Top Picks</h2>
      <div className="top-picks-table-container">
        <table className="top-picks-table">
          <thead>
            <tr>
              <th>Player</th>
              <th>Prediction</th>
              <th>Probability</th>
              <th>Odds</th>
            </tr>
          </thead>
          <tbody>
            {top8Picks.map(renderPickRow)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPicks;