import React, { useEffect, useRef, useState, useMemo } from 'react';
import * as echarts from 'echarts';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import useFetchTeamChartData from './data/PlayerShareData';
import './PlayerShare.css';

const StackedAreaChart = ({ selectedFeature }) => {
  const { selectedTeam, teamColors } = usePlayerColors();
  const chartRef = useRef(null);
  const metrics = useMemo(() => ['pts', 'reb', 'ast', 'blk', 'stl', 'min'], []);
  const [showStarters, setShowStarters] = useState(true);

  const { chartData, loading } = useFetchTeamChartData(selectedTeam, metrics, 15);

  const primaryColor = teamColors?.[0] || '#5470C6';

  const colors = useMemo(() => {
    const generateColorShades = (baseColor) => {
      if (!baseColor) return ['#5470C6', '#5470C6', '#5470C6'];
      const shades = [];
      const r = parseInt(baseColor.slice(1, 3), 16);
      const g = parseInt(baseColor.slice(3, 5), 16);
      const b = parseInt(baseColor.slice(5, 7), 16);
      for (let i = 0; i < 3; i++) {
        const alpha = 1 - (i + 1) / 4;
        shades.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
      }
      return shades;
    };

    const primaryShades = generateColorShades(teamColors?.[0]);
    const secondaryShades = generateColorShades(teamColors?.[1]);
    const tertiaryShades = generateColorShades(teamColors?.[2]);

    return [
      teamColors?.[0], teamColors?.[1], teamColors?.[2],
      ...primaryShades, ...secondaryShades, ...tertiaryShades
    ].filter(Boolean);
  }, [teamColors]);

  const featureMapping = {
    'Total': 'pts',
    'Pts+Ast': 'pts+ast',
    'Pts+Reb': 'pts+reb',
    'Ast+Reb': 'ast+reb',
    'Pts+Ast+Reb': 'pts+ast+reb'
  };

  const mappedFeature = featureMapping[selectedFeature] || selectedFeature.toLowerCase();

  const processedChartData = useMemo(() => {
    if (!chartData.seriesData[mappedFeature]) return null;

    let processedSeriesData = chartData.seriesData[mappedFeature];
    if (showStarters) {
      const starters = processedSeriesData.slice(0, 5);
      const benchPlayers = processedSeriesData.slice(5);

      const benchSeries = {
        name: 'Other',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: { focus: 'series' },
        data: chartData.dates.map((date, index) => 
          benchPlayers.reduce((sum, player) => sum + player.data[index], 0)
        )
      };

      processedSeriesData = [...starters, benchSeries];
    }

    const totalPointsPerDate = chartData.dates.map((date, dateIndex) => 
      processedSeriesData.reduce((sum, series) => sum + (series.data[dateIndex] || 0), 0)
    );

    const totalPointsOverall = totalPointsPerDate.reduce((a, b) => a + b, 0);

    const totalPointsPerSeries = processedSeriesData.map(series => ({
      name: series.name,
      totalPoints: series.data.reduce((sum, val) => sum + val, 0)
    })).sort((a, b) => b.totalPoints - a.totalPoints);

    const sortedSeriesData = totalPointsPerSeries.map(series => 
      processedSeriesData.find(s => s.name === series.name)
    );

    const sortedColors = totalPointsPerSeries.map(series => 
      colors[processedSeriesData.findIndex(s => s.name === series.name)]
    );

    return {
      processedSeriesData,
      totalPointsPerDate,
      totalPointsOverall,
      totalPointsPerSeries,
      sortedSeriesData,
      sortedColors
    };
  }, [chartData, mappedFeature, showStarters, colors]);

  useEffect(() => {
    if (!loading && processedChartData) {
      const myChart = echarts.init(chartRef.current);
      const { sortedSeriesData, sortedColors, totalPointsPerDate, totalPointsOverall, totalPointsPerSeries } = processedChartData;

      const option = {
        color: sortedColors,
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          textStyle: { color: '#FFFFFF' },
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            const sortedParams = params.sort((a, b) => b.value - a.value);
            const totalPointsForDate = totalPointsPerDate[chartData.dates.indexOf(params[0].axisValue)];
            let tooltip = `${params[0].axisValue}<br/>`;
            sortedParams.forEach(param => {
              const percentage = ((param.value / totalPointsForDate) * 100).toFixed(2);
              tooltip += `${param.marker} ${param.seriesName}: ${param.value} (${percentage}%)<br/>`;
            });
            return tooltip;
          }
        },
        legend: {
          data: totalPointsPerSeries.reverse().map(series => ({
            name: series.name,
            icon: 'circle'
          })),
          left: '1.5%',
          top: 'center',
          orient: 'vertical',
          textStyle: {
            color: `var(--dark-text-color)`,
            fontSize: 14
          },
          formatter: (name) => {
            const series = sortedSeriesData.find(series => series.name === name);
            const totalPointsForSeries = series?.data.reduce((sum, point) => sum + point, 0) || 0;
            const percentage = ((totalPointsForSeries / totalPointsOverall) * 100).toFixed(2);
            return `${name} (${percentage}%)`;
          }
        },
        grid: {
          left: '28%',
          right: '0%',
          top:'15%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: chartData.dates,
          axisLabel: {
            rotate: 45,
            formatter: (value) => new Date(value).toLocaleDateString(),
            textStyle: { color: '#FFFFFF' }
          }
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: { color: '#FFFFFF' }
          }
        }],
        series: sortedSeriesData.map((series, index) => ({
          ...series,
          itemStyle: { color: sortedColors[index] },
          symbol: 'none',
          emphasis: {
            focus: 'series',
            itemStyle: {
              opacity: 1,
              borderColor: '#000',
              borderWidth: 1.5
            },
            label: { show: false }
          }
        }))
      };
      
      myChart.setOption(option);

      const addHighlightListener = (eventName, actionType) => {
        myChart.on(eventName, (params) => {
          const legendElement = document.querySelector(`.echarts-legend-item[data-name="${params.seriesName || params.name}"]`);
          if (legendElement) {
            legendElement.classList[actionType === 'highlight' ? 'add' : 'remove']('legend-highlight');
          }
          myChart.dispatchAction({
            type: actionType,
            seriesName: params.seriesName || params.name
          });
        });
      };

      addHighlightListener('mouseover', 'highlight');
      addHighlightListener('mouseout', 'downplay');
      addHighlightListener('legendItemOver', 'highlight');
      addHighlightListener('legendItemOut', 'downplay');

      return () => {
        myChart.dispose();
      };
    }

  }, [processedChartData, loading, chartData.dates]);

  const toggleStarters = () => {
    setShowStarters(prev => !prev);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="player-share-chart-wrapper">
      <div 
        className="player-share-chart-title" 
        style={{ color: `var(--dark-text-color)`, marginTop:'20px' }}
      >
        Stacked Area Chart
      </div>
      <div ref={chartRef} className="player-share-chart-container" style={{ width: '95%', height: '105%' }} />
      <div className="player-share-toggle-container">
        <label className="player-share-switch" style={{ '--primary-color': primaryColor }}>
          <input type="checkbox" checked={showStarters} onChange={toggleStarters} />
          <span className="player-share-slider player-share-round"></span>
        </label>
        <span>{showStarters ? 'Show All Players' : 'Show Top 5 Players'}</span>
      </div>
    </div>
  );
};

export default StackedAreaChart;