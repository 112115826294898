// TonightMatchups.js
import { players } from '../../components/PlayerInfo';

// Function to group players by team
const groupPlayersByTeam = () => {
  const playersByTeam = {};
  
  Object.values(players).forEach(player => {
    if (!playersByTeam[player.teamName]) {
      playersByTeam[player.teamName] = [];
    }
    playersByTeam[player.teamName].push(player);
  });
  
  return playersByTeam;
};

// Group players by team
const playersByTeam = groupPlayersByTeam();

// Create tonightMatchups object
export const tonightMatchups = {
  'Timberwolves': playersByTeam['Timberwolves'] || [],
  'Lakers': playersByTeam['Lakers'] || [],
  'Hawks': playersByTeam['Hawks'] || [],
  'Bulls': playersByTeam['Bulls'] || [],
  'Nuggets': playersByTeam['Nuggets'] || [],
  'Rockets': playersByTeam['Rockets'] || [],
  'Pelicans': playersByTeam['Pelicans'] || [],
  'Raptors': playersByTeam['Raptors'] || [],
  'Clippers': playersByTeam['Clippers'] || [],
  'Nets': playersByTeam['Nets'] || [],
  'Magic': playersByTeam['Magic'] || [],
  'Pacers': playersByTeam['Pacers'] || [],
  'Hornets': playersByTeam['Hornets'] || [],
  'Wizards': playersByTeam['Wizards'] || [],
  'Grizzlies': playersByTeam['Grizzlies'] || [],
  'Heat': playersByTeam['Heat'] || [],
  'Thunder': playersByTeam['Thunder'] || [],
  'Celtics': playersByTeam['Celtics'] || [],
  'Cavaliers': playersByTeam['Cavaliers'] || [],
  'Warriors': playersByTeam['Warriors'] || []
};

