const generateRandomRecord = () => {
  const wins = Math.floor(Math.random() * 50) + 10;
  const losses = Math.floor(Math.random() * 30);
  return `${wins}-${losses}`;
};

const generateRandomRank = () => {
  return `${Math.floor(Math.random() * 10) + 1}th`;
};

const generateRandomStreak = () => {
  const outcomes = ['W', 'L'];
  return Array.from({ length: 5 }, () => outcomes[Math.floor(Math.random() * outcomes.length)]);
};

export const teamsDict = {
  Hawks: {
    logo: 'team_logos/hawks.png',
    colors: ['#C8102E', '#FDB927', '#A1A1A1', '#000000', '#FF6600'],
    venue: 'State Farm Arena',
    location: 'Atlanta',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612737
  },
  Celtics: {
    logo: 'team_logos/celtics.png',
    colors: ['#28A76F', '#BA9653', '#963821', '#000000', '#FFFFFF'],
    venue: 'TD Garden',
    location: 'Boston',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612738
  },
  Nets: {
    logo: 'team_logos/nets.png',
    colors: ['#000000', '#777D84', '#CD1041', '#F9781B', '#FFFFFF'],
    venue: 'Barclays Center',
    location: 'Brooklyn',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612751
  },
  Hornets: {
    logo: 'team_logos/hornets.png',
    colors: ['#00788C', '#1D1160', '#A1A1A4', '#F9423A'],
    venue: 'Spectrum Center',
    location: 'Charlotte',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612766
  },
  Bulls: {
    logo: 'team_logos/bulls.png',
    colors: ['#CE1141', '#000000', '#CCCCCC', '#115DCE'],
    venue: 'United Center',
    location: 'Chicago',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612741
  },
  Cavaliers: {
    logo: 'team_logos/cavaliers.png',
    colors: ['#AD2331', '#FDBB30', '#000000', '#041E42'],
    venue: 'Rocket Mortgage FieldHouse',
    location: 'Cleveland',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612739
  },
  Mavericks: {
    logo: 'team_logos/mavericks.png',
    colors: ['#007DC5', '#2B3D65', '#B8C4CA', '#000000'],
    venue: 'American Airlines Center',
    location: 'Dallas',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612742
  },
  Nuggets: {
    logo: 'team_logos/nuggets.png',
    colors: ['#0A233F', '#FFC52F', '#8A2433', '#000000'],
    venue: 'Ball Arena',
    location: 'Denver',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612743
  },
  Pistons: {
    logo: 'team_logos/pistons.png',
    colors: ['#C8102E', '#1D42BA', '#BEC0C2', '#041E42'],
    venue: 'Little Caesars Arena',
    location: 'Detroit',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612765
  },
  Warriors: {
    logo: 'team_logos/warriors.png',
    colors: ['#1D428A', '#FFC72C', '#26282A', '#BE3A34'],
    venue: 'Chase Center',
    location: 'San Francisco',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612744
  },
  Rockets: {
    logo: 'team_logos/rockets.png',
    colors: ['#CE1141', '#000000', '#4DB8DA', '#A1ADBC'],
    venue: 'Toyota Center',
    location: 'Houston',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612745
  },
  Pacers: {
    logo: 'team_logos/pacers.png',
    colors: ['#002D62', '#FDBB30', '#BEC0C2', '#000000'],
    venue: 'Gainbridge Fieldhouse',
    location: 'Indianapolis',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612754
  },
  Clippers: {
    logo: 'team_logos/clippers.png',
    colors: ['#1D428A', '#C8102E', '#BEC0C2', '#000000'],
    venue: 'Staples Center',
    location: 'Los Angeles',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612746
  },
  Lakers: {
    logo: 'team_logos/lakers.png',
    colors: ['#552583', '#FDB927', '#007DC5', '#000000'],
    venue: 'Staples Center',
    location: 'Los Angeles',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612747
  },
  Grizzlies: {
    logo: 'team_logos/grizzlies.png',
    colors: ['#5D76A9', '#12173F', '#BED4E9', '#F5B112'],
    venue: 'FedExForum',
    location: 'Memphis',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612763
  },
  Heat: {
    logo: 'team_logos/heat.png',
    colors: ['#98002E', '#F9A01B', '#A25BAA', '#000000'],
    venue: 'FTX Arena',
    location: 'Miami',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612748
  },
  Bucks: {
    logo: 'team_logos/bucks.png',
    colors: ['#00471B', '#EEE1C6', '#8B8D8F', '#0077C0'],
    venue: 'Fiserv Forum',
    location: 'Milwaukee',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612749
  },
  Timberwolves: {
    logo: 'team_logos/timberwolves.png',
    colors: ['#4C536A', '#6691B5', '#BCC0C0', '#A5D96E'],
    venue: 'Target Center',
    location: 'Minneapolis',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612750
  },
  Pelicans: {
    logo: 'team_logos/pelicans.png',
    colors: ['#002A5C', '#B5985A', '#E51937', '#6C7574'],
    venue: 'Smoothie King Center',
    location: 'New Orleans',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612740
  },
  Knicks: {
    logo: 'team_logos/knicks.png',
    colors: ['#006BB6', '#F58426', '#BEC0C2', '#000000'],
    venue: 'Madison Square Garden',
    location: 'New York',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612752
  },
  Thunder: {
    logo: 'team_logos/thunder.png',
    colors: ['#007AC1', '#EF3B24', '#002D62', '#FDBB30'],
    venue: 'Paycom Center',
    location: 'Oklahoma City',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612760
  },
  Magic: {
    logo: 'team_logos/magic.png',
    colors: ['#0077C0', '#C4CED4', '#002D62', '#000000'],
    venue: 'Amway Center',
    location: 'Orlando',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612753
  },
  '76ers': {
    logo: 'team_logos/76ers.png',
    colors: ['#006BB6', '#ED174C', '#002B5C', '#525050'],
    venue: 'Wells Fargo Center',
    location: 'Philadelphia',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612755
  },
  Suns: {
    logo: 'team_logos/suns.png',
    colors: ['#1D1160', '#E56020', '#63727A', '#000000'],
    venue: 'Footprint Center',
    location: 'Phoenix',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612756
  },
  Blazers: {
    logo: 'team_logos/blazers.png',
    colors: ['#E03A3E', '#000000', '#4DB8DA', '#A1ADBC'],
    venue: 'Moda Center',
    location: 'Portland',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612757
  },
  Kings: {
    logo: 'team_logos/kings.png',
    colors: ['#5A2D81', '#63727A', '#000000', '#29CCB9'],
    venue: 'Golden 1 Center',
    location: 'Sacramento',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612758
  },
  Spurs: {
    logo: 'team_logos/spurs.png',
    colors: ['#BEC6C9', '#121213', '#EF426F', '#00B2A9'],
    venue: 'AT&T Center',
    location: 'San Antonio',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612759
  },
  Raptors: {
    logo: 'team_logos/raptors.png',
    colors: ['#CE1141', '#000000', '#A1A1A4', '#B4975A'],
    venue: 'Scotiabank Arena',
    location: 'Toronto',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612761
  },
  Jazz: {
    logo: 'team_logos/jazz.png',
    colors: ['#002B5C', '#F9A01B', '#00471B', '#954E4C'],
    venue: 'Vivint Arena',
    location: 'Salt Lake City',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612762
  },
  Wizards: {
    logo: 'team_logos/wizards.png',
    colors: ['#002B5C', '#E31837', '#C4CED4', '#000000'],
    venue: 'Capital One Arena',
    location: 'Washington, D.C.',
    record: generateRandomRecord(),
    rank: generateRandomRank(),
    streak: generateRandomStreak(),
    team_id: 1610612764
  }
};


  
  
  // Add more teams as needed
  
  const generateInjuryReport = (teamName, players) => {
    const statusOptions = ['Day–To–Day', 'Out'];
    const impactOptions = [
      `${teamName} + 6.5 points`,
      `${teamName} allow 3.2 points less `,
      `${teamName} score 6.5 points more`,
      `${teamName} allow 3.2 points less `
    ];
    const injuryOptions = [
      'Knee 3–6 weeks',
      'Shoulder 2–4 weeks',
      'Ankle 1–2 weeks',
      'Back 4–6 weeks'
    ];
    const statusColors = {
      'Day–To–Day': 'yellow',
      'Out': 'red'
    };
  
    return players.map(player => {
      const status = statusOptions[Math.floor(Math.random() * statusOptions.length)];
      return {
        name: player,
        status: status,
        impact: impactOptions[Math.floor(Math.random() * impactOptions.length)],
        injury: injuryOptions[Math.floor(Math.random() * injuryOptions.length)],
        statusColor: statusColors[status],
        team: teamName
      };
    });
  };
  
  export const injuryReport = {
    'Nuggets': generateInjuryReport('Nuggets', ['N. Jokic', 'M. Porter', 'N. Trikic','Testing McDet']),
    'Timberwolves': generateInjuryReport('Timberwolves', ['A. Edwards', 'K. Towns', 'N. Reid']),
    'Lakers': generateInjuryReport('Lakers', ['L. James', 'A. Davis', 'D. Russell','H.Testing']),
    'Celtics': generateInjuryReport('Celtics', ['J. Tatum', 'J. Brown', 'M. Smart']),
    'Nets': generateInjuryReport('Nets', ['K. Durant', 'J. Harden', 'K. Irving']),
    'Warriors': generateInjuryReport('Warriors', ['S. Curry', 'K. Thompson', 'D. Green']),
    'Bucks': generateInjuryReport('Bucks', ['G. Antetokounmpo', 'K. Middleton', 'J. Holiday']),
    '76ers': generateInjuryReport('76ers', ['J. Embiid', 'B. Simmons', 'T. Harris']),
    'Heat': generateInjuryReport('Heat', ['J. Butler', 'B. Adebayo', 'T. Herro']),
    'Clippers': generateInjuryReport('Clippers', ['K. Leonard', 'P. George', 'R. Jackson']),
    'Suns': generateInjuryReport('Suns', ['D. Booker', 'C. Paul', 'D. Ayton']),
    'Mavericks': generateInjuryReport('Mavericks', ['L. Doncic', 'K. Porzingis', 'T. Hardaway Jr.']),
    'Hawks': generateInjuryReport('Hawks', ['T. Young', 'J. Collins', 'C. Capela']),
    'Knicks': generateInjuryReport('Knicks', ['J. Randle', 'R. Barrett', 'D. Rose']),
    'Raptors': generateInjuryReport('Raptors', ['F. VanVleet', 'P. Siakam', 'O. Anunoby']),
    'Pelicans': generateInjuryReport('Pelicans', ['Z. Williamson', 'B. Ingram', 'J. Valanciunas']),
    'Grizzlies': generateInjuryReport('Grizzlies', ['J. Morant', 'J. Jackson Jr.', 'D. Brooks']),
    'Spurs': generateInjuryReport('Spurs', ['D. Murray', 'D. White', 'K. Johnson']),
    'Kings': generateInjuryReport('Kings', ['D. Fox', 'T. Haliburton', 'B. Hield']),
    'Pistons': generateInjuryReport('Pistons', ['C. Cunningham', 'J. Grant', 'S. Bey']),
    'Magic': generateInjuryReport('Magic', ['C. Anthony', 'M. Bamba', 'J. Suggs']),
    'Thunder': generateInjuryReport('Thunder', ['S. Gilgeous-Alexander', 'L. Dort', 'D. Bazley']),
    'Wizards': generateInjuryReport('Wizards', ['B. Beal', 'R. Hachimura', 'K. Kuzma']),
    'Blazers ': generateInjuryReport('Blazers', ['D. Lillard', 'C. McCollum', 'J. Nurkic']),
    'Hornets': generateInjuryReport('Hornets', ['L. Ball', 'G. Hayward', 'T. Rozier']),
    'Pacers': generateInjuryReport('Pacers', ['D. Sabonis', 'M. Brogdon', 'C. LeVert']),
    'Bulls': generateInjuryReport('Bulls', ['Z. LaVine', 'D. DeRozan', 'N. Vucevic']),
    'Rockets': generateInjuryReport('Rockets', ['J. Wall', 'C. Wood', 'K. Porter Jr.']),
    'Jazz': generateInjuryReport('Jazz', ['D. Mitchell', 'R. Gobert', 'M. Conley']),
  };
  

  