import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { fetchData } from '../../api';  // Assuming fetchData is in your API utilities
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak for authentication

export const fetchDefenseData = async (teamName, token, x = 15) => {
  if (!teamName) {
    throw new Error('Team name is required');
  }

  const teamAbbreviation = teamNameToAbbreviation[teamName];
  if (!teamAbbreviation) {
    throw new Error(`No abbreviation found for team: ${teamName}`);
  }

  try {
    // Use fetchData from your API utility to get the team data with token
    const teamData = await fetchData(`teams/filter_by_team/?team_abbreviation=${teamAbbreviation}&x=${x}`, token);

    // Check if the response is valid and is an array
    if (!Array.isArray(teamData)) {
      throw new Error(`Expected an array, but received: ${typeof teamData}`);
    }

    const processFetchedData = (data) => {
      const dates = data.map(item => {
        const date = new Date(item.game_date);
        return `${String(date.getFullYear()).slice(-2)}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      }).reverse();

      const opponents = data.map(item => item.opponent).reverse();

      const processedData = {
        dates,
        opponents,
        values: {},
        rollingAvg: {},
      };

      const metrics = [
        'team_pts_allowed',
        'team_ast_allowed',
        'team_reb_allowed',
        'team_blk_allowed',
        'team_stl_allowed',
        'Pts+Ast',
        'Pts+Reb',
        'Ast+Reb',
        'Pts+Ast+Reb'
      ];

      metrics.forEach(metric => {
        let values, rollingAvg;
        if (metric === 'Pts+Ast') {
          values = data.map(item => item.team_pts_allowed + item.team_ast_allowed).reverse();
          rollingAvg = data.map(item => item.team_pts_allowed_mean_rolling_15_games + item.team_ast_allowed_mean_rolling_15_games).reverse();
        } else if (metric === 'Pts+Reb') {
          values = data.map(item => item.team_pts_allowed + item.team_reb_allowed).reverse();
          rollingAvg = data.map(item => item.team_pts_allowed_mean_rolling_15_games + item.team_reb_allowed_mean_rolling_15_games).reverse();
        } else if (metric === 'Ast+Reb') {
          values = data.map(item => item.team_ast_allowed + item.team_reb_allowed).reverse();
          rollingAvg = data.map(item => item.team_ast_allowed_mean_rolling_15_games + item.team_reb_allowed_mean_rolling_15_games).reverse();
        } else if (metric === 'Pts+Ast+Reb') {
          values = data.map(item => item.team_pts_allowed + item.team_ast_allowed + item.team_reb_allowed).reverse();
          rollingAvg = data.map(item => item.team_pts_allowed_mean_rolling_15_games + item.team_ast_allowed_mean_rolling_15_games + item.team_reb_allowed_mean_rolling_15_games).reverse();
        } else {
          values = data.map(item => item[metric]).reverse();
          rollingAvg = data.map(item => item[`${metric}_mean_rolling_15_games`]).reverse();
        }

        processedData.values[metric] = values;
        processedData.rollingAvg[metric] = rollingAvg;
      });

      return processedData;
    };

    const processedData = processFetchedData(teamData);

    // Calculate yAxis values
    const yAxisValues = {};
    Object.keys(processedData.values).forEach(metric => {
      const values = processedData.values[metric];
      const minValue = Math.max(0, Math.floor(Math.min(...values) / 10) * 10 - 5);
      const maxValue = Math.ceil(Math.max(...values) / 10) * 10 + 5;
      yAxisValues[metric] = { min: minValue, max: maxValue };
    });

    return {
      teamData: processedData,
      yAxisValues
    };

  } catch (error) {
    console.error('Error fetching team data:', error);
    throw new Error('Failed to fetch data from the backend');
  }
};
