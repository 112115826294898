import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { matchups } from './MatchupInfo';
import { teamsDict } from './TeamInfo';
import { teamNameToAbbreviation } from './TeamDictionary';
import Matchup from './matchup_menu_components/Matchup';
import './MatchupMenu.css';

const MatchupMenu = () => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const navigate = useNavigate(); // Initialize navigate for navigation

  useEffect(() => {
    // Set the current date in DD.MM format
    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const containerWidth = document.querySelector('.matchup-menu').offsetWidth;
      const itemWidth = 190; // Adjusted width of each matchup
      const newItemsPerPage = Math.floor(containerWidth / itemWidth);
      setItemsPerPage(newItemsPerPage || 1); // Ensure at least one item is displayed
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const handleNext = () => {
    if (currentStartIndex + itemsPerPage < matchups.length) {
      setCurrentStartIndex(currentStartIndex + itemsPerPage);
    }
  };

  const handlePrevious = () => {
    if (currentStartIndex - itemsPerPage >= 0) {
      setCurrentStartIndex(currentStartIndex - itemsPerPage);
    }
  };

  const handleMatchupClick = (matchup) => {
    const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
    const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
    const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

    // Navigate to MatchupDashboard with team abbreviations
    navigate(`/MatchupDashboard`, {
      state: {
        matchup: {
          awayTeamAbbr,
          homeTeamAbbr,
          time: matchup.time,
          picks: matchup.picks,
          topPicks: matchup.topPicks,
        },
      },
    });
  };

  return (
    <div className="matchup-menu-wrapper">
      <div className="matchup-menu-date-box">
        <span className="matchup-menu-date">{currentDate}</span> {/* Display current date */}
      </div>
      <div className="matchup-menu-container">
        <div className="matchup-menu">
          {matchups.slice(currentStartIndex, currentStartIndex + itemsPerPage).map((matchup, index) => {
            const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
            const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
            const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

            return (
              <div
                key={index}
                className="matchup-menu-link"
                onClick={() => handleMatchupClick(matchup)} // Use handleMatchupClick for navigation
              >
                <Matchup
                  team1Logo={teamsDict[homeTeam]?.logo || '/default-logo.png'}
                  team2Logo={teamsDict[awayTeam]?.logo || '/default-logo.png'}
                  team1Name={homeTeamAbbr}
                  team2Name={awayTeamAbbr}
                  time={matchup.time}
                  picks={matchup.picks}
                  topPicks={matchup.topPicks}
                  isHomeGame={true}
                />
              </div>
            );
          })}
        </div>
        <div className="arrows-container">
          <button
            className="arrow-button up"
            onClick={handleNext}
            disabled={currentStartIndex + itemsPerPage >= matchups.length}
          ></button>
          <button
            className="arrow-button down"
            onClick={handlePrevious}
            disabled={currentStartIndex === 0}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default MatchupMenu;
