const fakeBetData = [
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 17.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 25.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.09,
    "amount_bet": 10,
    "line": 29.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.1,
    "amount_bet": 10,
    "line": 25.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 10.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 29.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 18.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.66,
    "amount_bet": 10,
    "line": 28.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.1,
    "amount_bet": 10,
    "line": 22.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.96,
    "amount_bet": 10,
    "line": 29.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.7,
    "amount_bet": 10,
    "line": 13.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.05,
    "amount_bet": 10,
    "line": 14.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.78,
    "amount_bet": 10,
    "line": 23.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 12.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 16.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.53,
    "amount_bet": 10,
    "line": 14.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 12.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 15.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.58,
    "amount_bet": 10,
    "line": 17.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.01,
    "amount_bet": 10,
    "line": 18.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.29,
    "amount_bet": 10,
    "line": 28.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 16.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.15,
    "amount_bet": 10,
    "line": 26.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 27.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 19.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.13,
    "amount_bet": 10,
    "line": 19.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 21.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.91,
    "amount_bet": 10,
    "line": 17.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 22.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 25.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.19,
    "amount_bet": 10,
    "line": 27.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 19.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.76,
    "amount_bet": 10,
    "line": 11.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.02,
    "amount_bet": 10,
    "line": 16.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 21.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 29.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.89,
    "amount_bet": 10,
    "line": 22.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.38,
    "amount_bet": 10,
    "line": 20.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 27.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 20.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 13.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 27.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 11.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 14.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 27.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.79,
    "amount_bet": 10,
    "line": 19.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.44,
    "amount_bet": 10,
    "line": 29.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 17.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.86,
    "amount_bet": 10,
    "line": 11.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 12.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.69,
    "amount_bet": 10,
    "line": 17.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.35,
    "amount_bet": 10,
    "line": 11.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.8,
    "amount_bet": 10,
    "line": 14.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.84,
    "amount_bet": 10,
    "line": 11.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 16.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 26.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 19.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.01,
    "amount_bet": 10,
    "line": 20.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 28.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 21.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 16.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.95,
    "amount_bet": 10,
    "line": 13.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.59,
    "amount_bet": 10,
    "line": 12.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.04,
    "amount_bet": 10,
    "line": 27.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 25.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.04,
    "amount_bet": 10,
    "line": 28.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.23,
    "amount_bet": 10,
    "line": 26.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 12.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 15.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.81,
    "amount_bet": 10,
    "line": 23.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.24,
    "amount_bet": 10,
    "line": 14.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 20.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 24.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 11.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 28.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 26.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.05,
    "amount_bet": 10,
    "line": 15.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 15.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 19.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 17.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 44,
    "line": 14.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.02,
    "amount_bet": 52,
    "line": 20.1,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.25,
    "amount_bet": 94,
    "line": 21.1,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.14,
    "amount_bet": 47,
    "line": 26.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.68,
    "amount_bet": 89,
    "line": 10.3,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.8,
    "amount_bet": 38,
    "line": 25.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.56,
    "amount_bet": 28,
    "line": 12.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.01,
    "amount_bet": 71,
    "line": 16.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 38,
    "line": 24.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.37,
    "amount_bet": 41,
    "line": 17.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.02,
    "amount_bet": 29,
    "line": 27.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.5,
    "amount_bet": 67,
    "line": 29.6,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.49,
    "amount_bet": 67,
    "line": 19.7,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 72,
    "line": 19.7,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-01",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.02,
    "amount_bet": 52,
    "line": 29.5,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 24.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 19.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 11.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.3,
    "amount_bet": 10,
    "line": 23.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 28.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.18,
    "amount_bet": 10,
    "line": 26.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 12.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 24.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 20.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 13.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 26.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 26.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 26.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 19.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 15.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.29,
    "amount_bet": 10,
    "line": 11.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 11.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 29.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.09,
    "amount_bet": 10,
    "line": 15.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.84,
    "amount_bet": 10,
    "line": 10.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 13.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.13,
    "amount_bet": 10,
    "line": 12.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.8,
    "amount_bet": 10,
    "line": 14.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 27.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.22,
    "amount_bet": 10,
    "line": 10.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 13.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 27.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 11.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 16.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 26.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.5,
    "amount_bet": 10,
    "line": 27.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.23,
    "amount_bet": 10,
    "line": 14.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 25.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.95,
    "amount_bet": 10,
    "line": 16.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 18.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.06,
    "amount_bet": 10,
    "line": 29.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 20.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.0,
    "amount_bet": 10,
    "line": 25.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.04,
    "amount_bet": 10,
    "line": 11.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 15.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 18.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 21.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 20.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 14.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.07,
    "amount_bet": 10,
    "line": 22.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 17.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.53,
    "amount_bet": 10,
    "line": 27.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 24.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 26.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 23.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 13.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.86,
    "amount_bet": 10,
    "line": 17.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 14.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.75,
    "amount_bet": 10,
    "line": 26.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 18.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 22.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 20.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 25.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 19.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 25.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 17.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.78,
    "amount_bet": 10,
    "line": 25.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 24.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 22.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.11,
    "amount_bet": 10,
    "line": 27.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.29,
    "amount_bet": 10,
    "line": 21.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 25.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.25,
    "amount_bet": 10,
    "line": 29.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.91,
    "amount_bet": 10,
    "line": 21.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 28.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 28.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.53,
    "amount_bet": 10,
    "line": 10.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.24,
    "amount_bet": 10,
    "line": 15.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.82,
    "amount_bet": 10,
    "line": 28.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 23.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.05,
    "amount_bet": 10,
    "line": 12.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 27.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 11.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.72,
    "amount_bet": 10,
    "line": 15.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 22.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.07,
    "amount_bet": 39,
    "line": 29.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.02,
    "amount_bet": 59,
    "line": 26.9,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.29,
    "amount_bet": 50,
    "line": 14.3,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.35,
    "amount_bet": 40,
    "line": 25.9,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.42,
    "amount_bet": 88,
    "line": 11.7,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.14,
    "amount_bet": 37,
    "line": 10.8,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.85,
    "amount_bet": 68,
    "line": 22.5,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 65,
    "line": 11.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.55,
    "amount_bet": 79,
    "line": 19.0,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.43,
    "amount_bet": 92,
    "line": 15.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.19,
    "amount_bet": 56,
    "line": 10.9,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.07,
    "amount_bet": 28,
    "line": 19.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 32,
    "line": 27.8,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.62,
    "amount_bet": 18,
    "line": 16.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-02",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.53,
    "amount_bet": 49,
    "line": 27.7,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.96,
    "amount_bet": 10,
    "line": 11.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 24.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 25.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.35,
    "amount_bet": 10,
    "line": 21.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 10.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 12.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.78,
    "amount_bet": 10,
    "line": 24.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.08,
    "amount_bet": 10,
    "line": 21.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.14,
    "amount_bet": 10,
    "line": 10.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.54,
    "amount_bet": 10,
    "line": 27.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.77,
    "amount_bet": 10,
    "line": 14.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 29.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 24.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.44,
    "amount_bet": 10,
    "line": 10.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 17.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.15,
    "amount_bet": 10,
    "line": 17.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 14.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 14.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.15,
    "amount_bet": 10,
    "line": 27.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.37,
    "amount_bet": 10,
    "line": 24.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 18.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.75,
    "amount_bet": 10,
    "line": 26.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.86,
    "amount_bet": 10,
    "line": 15.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 25.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 20.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 27.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 18.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.54,
    "amount_bet": 10,
    "line": 15.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 26.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.5,
    "amount_bet": 10,
    "line": 15.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.53,
    "amount_bet": 10,
    "line": 20.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.69,
    "amount_bet": 10,
    "line": 22.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.68,
    "amount_bet": 10,
    "line": 26.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 23.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 22.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.3,
    "amount_bet": 10,
    "line": 29.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 23.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 15.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.82,
    "amount_bet": 10,
    "line": 16.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 28.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 15.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 15.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.55,
    "amount_bet": 10,
    "line": 22.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 25.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.86,
    "amount_bet": 10,
    "line": 26.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.55,
    "amount_bet": 10,
    "line": 22.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.06,
    "amount_bet": 10,
    "line": 17.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.7,
    "amount_bet": 10,
    "line": 19.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 29.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.14,
    "amount_bet": 10,
    "line": 24.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.22,
    "amount_bet": 10,
    "line": 19.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.3,
    "amount_bet": 10,
    "line": 12.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 20.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.01,
    "amount_bet": 10,
    "line": 15.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.72,
    "amount_bet": 10,
    "line": 11.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.44,
    "amount_bet": 10,
    "line": 23.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.17,
    "amount_bet": 10,
    "line": 25.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 14.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 22.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.91,
    "amount_bet": 10,
    "line": 19.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.96,
    "amount_bet": 10,
    "line": 17.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.81,
    "amount_bet": 10,
    "line": 13.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.53,
    "amount_bet": 10,
    "line": 23.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 26.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 21.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 24.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 20.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 17.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.82,
    "amount_bet": 10,
    "line": 29.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.25,
    "amount_bet": 10,
    "line": 13.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 24.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.99,
    "amount_bet": 10,
    "line": 28.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 13.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 12.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 28.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.05,
    "amount_bet": 10,
    "line": 23.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.24,
    "amount_bet": 10,
    "line": 17.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.19,
    "amount_bet": 10,
    "line": 17.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 19.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.89,
    "amount_bet": 10,
    "line": 18.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.38,
    "amount_bet": 92,
    "line": 28.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 11,
    "line": 12.2,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.72,
    "amount_bet": 71,
    "line": 20.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 25,
    "line": 23.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 35,
    "line": 18.0,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.6,
    "amount_bet": 52,
    "line": 18.3,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.63,
    "amount_bet": 47,
    "line": 13.6,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 81,
    "line": 27.5,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 27.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.81,
    "amount_bet": 11,
    "line": 22.7,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.3,
    "amount_bet": 33,
    "line": 10.8,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.08,
    "amount_bet": 45,
    "line": 15.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.13,
    "amount_bet": 100,
    "line": 14.6,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.47,
    "amount_bet": 76,
    "line": 13.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-03",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.63,
    "amount_bet": 46,
    "line": 16.2,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.22,
    "amount_bet": 10,
    "line": 29.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.58,
    "amount_bet": 10,
    "line": 25.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 18.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 23.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.86,
    "amount_bet": 10,
    "line": 17.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 20.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.63,
    "amount_bet": 10,
    "line": 24.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 11.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.37,
    "amount_bet": 10,
    "line": 25.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 16.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.5,
    "amount_bet": 10,
    "line": 13.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.63,
    "amount_bet": 10,
    "line": 25.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.92,
    "amount_bet": 10,
    "line": 12.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 12.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 10.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.99,
    "amount_bet": 10,
    "line": 24.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.36,
    "amount_bet": 10,
    "line": 28.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.17,
    "amount_bet": 10,
    "line": 19.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 21.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 14.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 28.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 17.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.83,
    "amount_bet": 10,
    "line": 12.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 10.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.14,
    "amount_bet": 10,
    "line": 19.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 18.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 26.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 22.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.7,
    "amount_bet": 10,
    "line": 13.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 19.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 11.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.68,
    "amount_bet": 10,
    "line": 11.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.18,
    "amount_bet": 10,
    "line": 20.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.49,
    "amount_bet": 10,
    "line": 15.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 17.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.92,
    "amount_bet": 10,
    "line": 12.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 11.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.81,
    "amount_bet": 10,
    "line": 16.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 17.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 28.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 17.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.78,
    "amount_bet": 10,
    "line": 29.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.38,
    "amount_bet": 10,
    "line": 29.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 29.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.24,
    "amount_bet": 10,
    "line": 11.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.66,
    "amount_bet": 10,
    "line": 17.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 12.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 27.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.6,
    "amount_bet": 10,
    "line": 22.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 24.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.82,
    "amount_bet": 10,
    "line": 11.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 28.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 10.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.0,
    "amount_bet": 10,
    "line": 28.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.09,
    "amount_bet": 10,
    "line": 11.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.57,
    "amount_bet": 10,
    "line": 25.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.76,
    "amount_bet": 10,
    "line": 24.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 19.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 19.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 18.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.22,
    "amount_bet": 10,
    "line": 19.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.71,
    "amount_bet": 10,
    "line": 25.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.92,
    "amount_bet": 10,
    "line": 29.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 28.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.11,
    "amount_bet": 10,
    "line": 18.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 11.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.68,
    "amount_bet": 10,
    "line": 10.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.64,
    "amount_bet": 10,
    "line": 15.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 27.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 13.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 10.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 28.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 11.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.66,
    "amount_bet": 10,
    "line": 15.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 22.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 15.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.63,
    "amount_bet": 10,
    "line": 11.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 17.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 16.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 15.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 90,
    "line": 16.7,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.57,
    "amount_bet": 96,
    "line": 18.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 66,
    "line": 18.6,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.6,
    "amount_bet": 62,
    "line": 11.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.29,
    "amount_bet": 99,
    "line": 26.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.01,
    "amount_bet": 13,
    "line": 28.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.52,
    "amount_bet": 20,
    "line": 23.1,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.92,
    "amount_bet": 22,
    "line": 12.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.59,
    "amount_bet": 21,
    "line": 21.1,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.31,
    "amount_bet": 21,
    "line": 12.9,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.96,
    "amount_bet": 73,
    "line": 10.0,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.31,
    "amount_bet": 30,
    "line": 29.8,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 92,
    "line": 21.0,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.24,
    "amount_bet": 63,
    "line": 30.0,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-04",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.75,
    "amount_bet": 44,
    "line": 19.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 21.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.63,
    "amount_bet": 10,
    "line": 11.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 22.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 29.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 14.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 10,
    "line": 16.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 29.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 28.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.71,
    "amount_bet": 10,
    "line": 14.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.8,
    "amount_bet": 10,
    "line": 13.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 23.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 26.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.0,
    "amount_bet": 10,
    "line": 15.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 19.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.48,
    "amount_bet": 10,
    "line": 19.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.15,
    "amount_bet": 10,
    "line": 14.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.06,
    "amount_bet": 10,
    "line": 27.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 19.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.49,
    "amount_bet": 10,
    "line": 23.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 19.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 14.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.02,
    "amount_bet": 10,
    "line": 11.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 14.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 26.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.18,
    "amount_bet": 10,
    "line": 26.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 11.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.96,
    "amount_bet": 10,
    "line": 28.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 23.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.13,
    "amount_bet": 10,
    "line": 26.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 11.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 10.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 17.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 26.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 10.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 28.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.38,
    "amount_bet": 10,
    "line": 22.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 12.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 27.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.8,
    "amount_bet": 10,
    "line": 25.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.89,
    "amount_bet": 10,
    "line": 22.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 19.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.08,
    "amount_bet": 10,
    "line": 23.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 20.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 25.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.49,
    "amount_bet": 10,
    "line": 28.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 21.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 10.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.15,
    "amount_bet": 10,
    "line": 23.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.73,
    "amount_bet": 10,
    "line": 20.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.09,
    "amount_bet": 10,
    "line": 17.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 17.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.91,
    "amount_bet": 10,
    "line": 26.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.89,
    "amount_bet": 10,
    "line": 25.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.72,
    "amount_bet": 10,
    "line": 17.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.65,
    "amount_bet": 10,
    "line": 17.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 19.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.62,
    "amount_bet": 10,
    "line": 23.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.25,
    "amount_bet": 10,
    "line": 13.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 28.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 15.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.77,
    "amount_bet": 10,
    "line": 20.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.72,
    "amount_bet": 10,
    "line": 14.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.18,
    "amount_bet": 10,
    "line": 22.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.66,
    "amount_bet": 10,
    "line": 22.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.55,
    "amount_bet": 10,
    "line": 18.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.58,
    "amount_bet": 10,
    "line": 26.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.14,
    "amount_bet": 10,
    "line": 28.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 17.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.1,
    "amount_bet": 10,
    "line": 18.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.01,
    "amount_bet": 10,
    "line": 22.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.78,
    "amount_bet": 10,
    "line": 25.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 26.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.69,
    "amount_bet": 10,
    "line": 24.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 22.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 19.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.45,
    "amount_bet": 10,
    "line": 25.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.57,
    "amount_bet": 10,
    "line": 18.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 15.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 12.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.38,
    "amount_bet": 10,
    "line": 29.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.43,
    "amount_bet": 38,
    "line": 19.3,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.46,
    "amount_bet": 53,
    "line": 20.6,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 39,
    "line": 20.8,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 71,
    "line": 26.3,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.9,
    "amount_bet": 77,
    "line": 22.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.7,
    "amount_bet": 99,
    "line": 10.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.83,
    "amount_bet": 98,
    "line": 12.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.0,
    "amount_bet": 55,
    "line": 26.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.29,
    "amount_bet": 71,
    "line": 20.0,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.82,
    "amount_bet": 59,
    "line": 14.3,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.41,
    "amount_bet": 25,
    "line": 22.3,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.21,
    "amount_bet": 96,
    "line": 10.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.55,
    "amount_bet": 74,
    "line": 15.5,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.33,
    "amount_bet": 89,
    "line": 23.1,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-05",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.02,
    "amount_bet": 11,
    "line": 28.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 24.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.14,
    "amount_bet": 10,
    "line": 11.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.59,
    "amount_bet": 10,
    "line": 27.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.87,
    "amount_bet": 10,
    "line": 20.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.79,
    "amount_bet": 10,
    "line": 15.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.97,
    "amount_bet": 10,
    "line": 20.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.76,
    "amount_bet": 10,
    "line": 10.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 24.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 19.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 16.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.84,
    "amount_bet": 10,
    "line": 17.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 15.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 17.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 24.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.08,
    "amount_bet": 10,
    "line": 24.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 15.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.73,
    "amount_bet": 10,
    "line": 24.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 23.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.27,
    "amount_bet": 10,
    "line": 20.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 12.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.31,
    "amount_bet": 10,
    "line": 18.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 23.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 14.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 21.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.63,
    "amount_bet": 10,
    "line": 27.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.24,
    "amount_bet": 10,
    "line": 19.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.93,
    "amount_bet": 10,
    "line": 20.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.68,
    "amount_bet": 10,
    "line": 17.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 28.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.03,
    "amount_bet": 10,
    "line": 29.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.6,
    "amount_bet": 10,
    "line": 10.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.73,
    "amount_bet": 10,
    "line": 23.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.81,
    "amount_bet": 10,
    "line": 12.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.68,
    "amount_bet": 10,
    "line": 28.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 29.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.33,
    "amount_bet": 10,
    "line": 29.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.08,
    "amount_bet": 10,
    "line": 13.5,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 10,
    "line": 23.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 27.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.4,
    "amount_bet": 10,
    "line": 22.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.0,
    "amount_bet": 10,
    "line": 10.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 19.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.32,
    "amount_bet": 10,
    "line": 16.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.11,
    "amount_bet": 10,
    "line": 23.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 14.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.59,
    "amount_bet": 10,
    "line": 10.9,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.39,
    "amount_bet": 10,
    "line": 23.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 29.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 24.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.89,
    "amount_bet": 10,
    "line": 23.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.2,
    "amount_bet": 10,
    "line": 29.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 12.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.28,
    "amount_bet": 10,
    "line": 20.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.13,
    "amount_bet": 10,
    "line": 16.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 21.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.16,
    "amount_bet": 10,
    "line": 28.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.22,
    "amount_bet": 10,
    "line": 15.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.84,
    "amount_bet": 10,
    "line": 24.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.13,
    "amount_bet": 10,
    "line": 26.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 14.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.17,
    "amount_bet": 10,
    "line": 13.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 10,
    "line": 12.7,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.98,
    "amount_bet": 10,
    "line": 23.3,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 11.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.67,
    "amount_bet": 10,
    "line": 14.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.79,
    "amount_bet": 10,
    "line": 19.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.08,
    "amount_bet": 10,
    "line": 16.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.79,
    "amount_bet": 10,
    "line": 21.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 12.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.74,
    "amount_bet": 10,
    "line": 12.9,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 23.4,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.42,
    "amount_bet": 10,
    "line": 28.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.84,
    "amount_bet": 10,
    "line": 20.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.12,
    "amount_bet": 10,
    "line": 19.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.54,
    "amount_bet": 10,
    "line": 20.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.51,
    "amount_bet": 10,
    "line": 12.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.56,
    "amount_bet": 10,
    "line": 26.6,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.26,
    "amount_bet": 10,
    "line": 17.8,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 10,
    "line": 10.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.75,
    "amount_bet": 10,
    "line": 11.6,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-06",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.53,
    "amount_bet": 71,
    "line": 29.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.87,
    "amount_bet": 76,
    "line": 18.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.54,
    "amount_bet": 31,
    "line": 25.0,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.32,
    "amount_bet": 79,
    "line": 16.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.43,
    "amount_bet": 19,
    "line": 11.9,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Ast+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.76,
    "amount_bet": 33,
    "line": 16.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.13,
    "amount_bet": 93,
    "line": 17.5,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.87,
    "amount_bet": 84,
    "line": 14.2,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.53,
    "amount_bet": 37,
    "line": 26.3,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.12,
    "amount_bet": 66,
    "line": 25.6,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.73,
    "amount_bet": 23,
    "line": 26.0,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.73,
    "amount_bet": 98,
    "line": 17.8,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.69,
    "amount_bet": 87,
    "line": 24.4,
    "result": "win",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.05,
    "amount_bet": 48,
    "line": 27.4,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-06",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.91,
    "amount_bet": 79,
    "line": 28.1,
    "result": "lose",
    "bet_type": "you"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "FanDuel",
    "prop_type": "Blk",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 25.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast+Reb",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 11.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.34,
    "amount_bet": 10,
    "line": 15.2,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.06,
    "amount_bet": 10,
    "line": 23.4,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "LeBron James",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.52,
    "amount_bet": 10,
    "line": 29.2,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 30.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.5,
    "amount_bet": 10,
    "line": 29.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Alex Caruso",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.23,
    "amount_bet": 10,
    "line": 14.3,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Stephen Curry",
    "team": "Celtics",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.71,
    "amount_bet": 10,
    "line": 10.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "FanDuel",
    "prop_type": "Ast",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.21,
    "amount_bet": 10,
    "line": 16.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jokic",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb+Ast",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 2.41,
    "amount_bet": 10,
    "line": 24.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Jaylen Brown",
    "team": "Warriors",
    "sportsbook": "DraftKings",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 2.43,
    "amount_bet": 10,
    "line": 14.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Stephen Curry",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.94,
    "amount_bet": 10,
    "line": 13.1,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jovic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.91,
    "amount_bet": 10,
    "line": 27.5,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "LeBron James",
    "team": "Lakers",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 2.44,
    "amount_bet": 10,
    "line": 17.7,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jokic",
    "team": "Celtics",
    "sportsbook": "FanDuel",
    "prop_type": "Reb",
    "bet_direction": "under",
    "result_direction": "under",
    "odds": 1.85,
    "amount_bet": 10,
    "line": 19.8,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Alex Caruso",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Stl",
    "bet_direction": "over",
    "result_direction": "over",
    "odds": 1.9,
    "amount_bet": 10,
    "line": 18.0,
    "result": "win",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Jaylen Brown",
    "team": "Celtics",
    "sportsbook": "DraftKings",
    "prop_type": "Pts",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 2.46,
    "amount_bet": 10,
    "line": 23.0,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jokic",
    "team": "Warriors",
    "sportsbook": "BetMGM",
    "prop_type": "Pts+Reb",
    "bet_direction": "under",
    "result_direction": "over",
    "odds": 1.61,
    "amount_bet": 10,
    "line": 15.1,
    "result": "lose",
    "bet_type": "model"
  },
  {
    "date": "2024-03-07",
    "player": "Nikola Jovic",
    "team": "Lakers",
    "sportsbook": "DraftKings",
    "prop_type": "Blk",
    "bet_direction": "over",
    "result_direction": "under",
    "odds": 1.88,
    "amount_bet": 10,
    "line": 15.4,
    "result": "lose",
    "bet_type": "model"
  },
];

export default fakeBetData;