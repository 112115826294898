// api.js - Utility file to make authenticated requests to your backend

import { useKeycloak } from './KeycloakProvider';

export const fetchData = async (endpoint, token, method = "GET", body = null) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : null,  // Only include body if it's provided
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch data from the backend: ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
