import React, { useEffect, useRef, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchVersusTeamChartData from './data/VersusTeamChartData';
import './VersusTeamChart.css';

const VersusTeamChart = ({ showSum, selectedFeature, constantLine, setConstantLine }) => {
  const { teamColors, selectedPlayer, opponentName } = usePlayerColors();
  const chartData = useFetchVersusTeamChartData(selectedPlayer, opponentName);
  const chartRef = useRef(null);

  const roundUpToNext5 = (num) => Math.ceil(num / 5) * 5 + 5;

  const maxValues = useMemo(() => ({
    Total: roundUpToNext5(Math.max(...chartData.Total)),
    MIN: roundUpToNext5(Math.max(...chartData.MIN)),
    AST: roundUpToNext5(Math.max(...chartData.AST)),
    REB: roundUpToNext5(Math.max(...chartData.REB)),
    BLK: roundUpToNext5(Math.max(...chartData.BLK)),
    STL: roundUpToNext5(Math.max(...chartData.STL)),
  }), [chartData]);

  const getYAxisMax = (feature) => {
    const featureMap = {
      'AST': maxValues.AST,
      'REB': maxValues.REB,
      'BLK': maxValues.BLK,
      'STL': maxValues.STL,
      'Pts+Ast': maxValues.Total + maxValues.AST,
      'Pts+Reb': maxValues.Total + maxValues.REB,
      'Ast+Reb': maxValues.AST + maxValues.REB,
      'Pts+Ast+Reb': maxValues.Total + maxValues.AST + maxValues.REB,
    };
    return featureMap[feature] || maxValues.Total;
  };

  const createSeries = () => {
    const labelFormatter = (value) => (value > 2 ? value : '');

    const getSeriesConfig = (name, data, color, stack = null) => ({
      name,
      type: 'bar',
      stack,
      data,
      itemStyle: { color },
      label: {
        show: true,
        position: stack ? 'insideTop' : 'top',
        fontWeight: 'bold',
        fontSize: 14,
        formatter: (params) => labelFormatter(params.value),
        z: 3
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      },
      z: 1
    });

    let series = [];

    if (selectedFeature === 'Total') {
      if (showSum) {
        series.push(getSeriesConfig('Total', chartData.Total, teamColors[0]));
      } else {
        ['FT', '3PT', '2PT'].forEach((type, index) => {
          series.push(getSeriesConfig(type, chartData[type === '3PT' ? 'PT3' : type], teamColors[index] || `#${Math.floor(Math.random()*16777215).toString(16)}`, 'one'));
        });
      }
    } else if (['AST', 'REB', 'STL', 'BLK'].includes(selectedFeature)) {
      series.push(getSeriesConfig(selectedFeature, chartData[selectedFeature], teamColors[0]));
    } else if (['Pts+Ast', 'Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
      const featureMap = {
        'Pts+Ast': [['Pts', 'Total'], ['Ast', 'AST']],
        'Pts+Reb': [['Pts', 'Total'], ['Reb', 'REB']],
        'Ast+Reb': [['Ast', 'AST'], ['Reb', 'REB']],
        'Pts+Ast+Reb': [['Pts', 'Total'], ['Ast', 'AST'], ['Reb', 'REB']]
      };

      if (showSum) {
        const sumData = featureMap[selectedFeature].reduce((acc, [, dataKey]) => 
          acc.map((val, i) => val + chartData[dataKey][i]), new Array(chartData.Dates.length).fill(0));
        series.push(getSeriesConfig(selectedFeature, sumData, teamColors[0]));
      } else {
        featureMap[selectedFeature].forEach(([name, dataKey], index) => {
          series.push(getSeriesConfig(name, chartData[dataKey], teamColors[index] || `#${Math.floor(Math.random()*16777215).toString(16)}`, 'total'));
        });
      }
    }

    series.push({
      name: 'MIN',
      type: 'line',
      yAxisIndex: 1,
      showSymbol: false,
      lineStyle: {
        width: 3,
        color: teamColors[1] || '#FF0000'
      },
      itemStyle: {
        color: teamColors[1] || '#FF0000'
      },
      emphasis: {
        lineStyle: {
          width: 3
        }
      },
      data: chartData.MIN,
      z: 1
    });

    return series;
  };

  useEffect(() => {
    const series = createSeries();

    const option = {
      title: {
        text: `VS ${opponentName}`,
        left: '2%',
        top: '-2%',
        textStyle: {
          color: 'var(--dark-text-color)',
          size:'16px',
        },
      },
      legend: {
        data: series.map(serie => ({
          name: serie.name,
          icon: serie.type === 'line' ? 'circle' : 'path://M0,0 V14 H8 V0 Z'  // Vertical bar for non-line series
        })),
        left: 'center',
        top: '8%',
        textStyle: {
          color: 'var(--medium-text-color)',
        },
        itemWidth: 14,
        itemHeight: 16,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => params.reduce((tooltip, param) => 
          `${tooltip}<div>${param.marker}${param.seriesName}: ${param.value}</div>`, 
          `<div>${params[0].axisValueLabel}</div>`
        )
      },
      xAxis: {
        data: chartData.Dates,
        axisLabel: {
          rotate: 15,
          align: 'center',
          margin: 15,
          fontSize: 10,
          color: 'var(--medium-text-color)',
          formatter: (value) => value.substring(2, 10).replace(/-/g, '-')
        },
        axisLine: {
          onZero: true,
          lineStyle: { color: 'var(--light-text-color)' }
        },
        splitLine: { show: false },
        splitArea: { show: false }
      },
      yAxis: [
        {
          type: 'value',
          name: selectedFeature,
          max: getYAxisMax(selectedFeature),
          axisLabel: { color: 'var(--dark-text-color)' },
          axisLine: { lineStyle: { color: 'var(--medium-text-color)' } },
        },
        {
          type: 'value',
          name: 'Minutes',
          max: maxValues.MIN,
          position: 'right',
          axisLabel: { color: 'var(--dark-text-color)' },
          axisLine: { lineStyle: { color: 'var(--medium-text-color)' } },
          splitLine: { show: false }
        }
      ],
      grid: { bottom: 100 },
      series,
      color: teamColors
    };

    if (chartRef.current) {
      chartRef.current.getEchartsInstance().setOption(option, true);
    }
  }, [teamColors, showSum, chartData, selectedFeature, constantLine, maxValues, opponentName]);

  return (
    <div className="versus-team-chart-container">
      <ReactECharts 
        ref={chartRef} 
        className="versus-team-chart" 
        option={{}} 
        style={{ height: '90%', width: '90%' }} 
        opts={{ renderer: 'svg', devicePixelRatio: 2 }} 
      />
    </div>
  );
};

export default VersusTeamChart;