import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchRollingFeatureData from './data/RollingFeatureData';
import './RollingFeature.css';

const RollingFeature = ({ selectedFeature }) => {
  const { teamColors, selectedPlayer } = usePlayerColors();
  const chartData = useFetchRollingFeatureData(selectedPlayer, selectedFeature);
  const primaryColor = teamColors[0] || 'var(--primary-color)';
  const secondaryColor = teamColors[1] || 'var(--secondary-color)';

  const [minPoints, setMinPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(100);

  useEffect(() => {
    if (chartData && Object.keys(chartData).length > 0) {
      const dataPoints = Object.values(chartData).flat().filter(Number.isFinite);
      if (dataPoints.length > 0) {
        const minValue = Math.min(...dataPoints);
        const maxValue = Math.max(...dataPoints);
        setMinPoints(Math.max(0, Math.floor(minValue )));
        setMaxPoints(Math.ceil(maxValue));
      }
    }
  }, [chartData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear().toString().slice(-2)}`;
  };

  const getFeatureConfig = () => {
    switch (selectedFeature) {
      case 'Total':
        return {
          legendData: ['PT2', 'PT3'],
          seriesData: [
            { name: 'PT2', color: primaryColor },
            { name: 'PT3', color: secondaryColor },
          ]
        };
      case 'Pts+Ast':
      case 'Pts+Reb':
      case 'Pts+Ast+Reb':
      case 'Ast+Reb':
        return {
          legendData: ['Total'],
          seriesData: [{ name: 'Total', color: primaryColor }]
        };
      case 'AST':
        return {
          legendData: ['AST/TO', 'Passes/Touches'],
          seriesData: [
            { name: 'AST/TO', color: primaryColor },
            { name: 'Passes/Touches', color: secondaryColor }
          ]
        };
      case 'REB':
        return {
          legendData: ['OREB/Chances', 'DREB/Chances'],
          seriesData: [
            { name: 'OREB/Chances', color: primaryColor },
            { name: 'DREB/Chances', color: secondaryColor }
          ]
        };
      case 'BLK':
        return {
          legendData: ['BLK','BLKA'],
          seriesData: [
            { name: 'BLK', color: primaryColor },
            { name: 'BLKA', color: secondaryColor }
          ]
        };
      case 'STL':
        return {
          legendData: ['STL'],
          seriesData: [{ name: 'STL', color: primaryColor }]
        };
      default:
        return { legendData: [], seriesData: [] };
    }
  };

  const { legendData, seriesData } = getFeatureConfig();

  const getOption = () => ({
    title: {
      text: selectedFeature,
      left: '4%',
      top: '4%',
      textStyle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'var(--dark-text-color)',
      },
    },
    legend: {
      data: legendData,
      top: '13%',
      left: '4%',
      orient: 'horizontal',
      textStyle: {
        color: 'var(--medium-text-color)',
      },
      itemWidth: 10,
      itemHeight: 10,
      icon: 'circle',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const index = params[0].dataIndex;
        const date = chartData.Dates ? formatDate(chartData.Dates[index]) : 'N/A';
        const opponent = chartData.Opponents ? chartData.Opponents[index] : 'Unknown';

        let tooltipText = `Date: ${date}<br/>Opponent: ${opponent}<br/>`;
        params.forEach(param => {
          tooltipText += `${param.seriesName}: ${param.value.toFixed(2)}${selectedFeature === 'Total' ? '%' : ''}<br/>`;
        });
        return tooltipText;
      },
    },
    grid: {
      left: '15%',
      right: '5%',
      top: '25%',
      bottom: '10%',
    },
    xAxis: {
      type: 'category',
      data: chartData.Dates ? chartData.Dates.map(formatDate) : [],
      axisLabel: { show: false },
      axisBorder: { show: false },
      axisTick: { show: false },
    },
    yAxis: {
      min: minPoints,
      max: maxPoints,
      splitLine: {
        show: true,
        lineStyle: {
          color: 'var(--light-text-color)',
        },
      },
      axisLabel: {
        formatter: (value) => `${value.toFixed(0)}${selectedFeature === 'Total' ? '%' : ''}`,
        color: 'var(--medium-text-color)',
      },
    },
    series: seriesData.map(({ name, color }) => ({
      name,
      type: 'line',
      data: chartData[name] || [],
      showSymbol: false,
      lineStyle: {
        width: 5,
        color,
      },
      itemStyle: {
        color,
      },
      smooth: true,
    })),
  });

  return (
    <div id="chart" className="shooting-chart-container">
      <ReactEcharts
        option={getOption()}
        style={{ height: '93%', width: '100%' }}
        opts={{ renderer: 'svg', devicePixelRatio: 2 }}
      />
    </div>
  );
};

export default RollingFeature;