// KeycloakProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';

const KeycloakContext = createContext(null);

export const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [keycloakId, setKeycloakId] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    let refreshInterval;

    const keycloakInstance = new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
    });

    keycloakInstance
      .init({
        onLoad: 'login-required',
        pkceMethod: 'S256',
        checkLoginIframe: false,
      })
      .then((auth) => {
        setKeycloak(keycloakInstance);
        setAuthenticated(auth);
        setToken(keycloakInstance.token);
        setLoading(false); // Stop loading once initialized

        if (auth) {
          setKeycloakId(keycloakInstance.tokenParsed.sub);

          // Set up token refresh
          refreshInterval = setInterval(() => {
            keycloakInstance
              .updateToken(70)
              .then((refreshed) => {
                if (refreshed) {
                  setToken(keycloakInstance.token);
                }
              })
              .catch(() => {
                keycloakInstance.logout();
              });
          }, 60000);
        }
      })
      .catch((error) => {
        console.error('Keycloak initialization error:', error);
        setLoading(false); // Stop loading even if there's an error
      });

    return () => {
      if (refreshInterval) clearInterval(refreshInterval);
    };
  }, []);

  return (
    <KeycloakContext.Provider value={{ keycloak, authenticated, token, keycloakId, loading }}>
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => useContext(KeycloakContext);
