import React, { useEffect, useState, useCallback } from "react";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import { useKeycloak } from "../KeycloakProvider";  // Import Keycloak context for token
import { fetchData } from '../api';  // Import your fetchData function
import "./TeamLogs.css";
import {
  FaExpand,
  FaCompress,
  FaPlus,
  FaMinus,
  FaSort,
  FaSortUp,
  FaSortDown
} from "react-icons/fa";
import { teamNameToAbbreviation, abbreviationToTeamName } from "../components/TeamDictionary";

const featureMap = {
  Points: "team_pts",
  Assists: "team_ast",
  Rebounds: "team_reb",
  Steals: "team_stl",
  Blocks: "team_blk",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb",
};

const featureReverseMap = {
  team_pts: "Points",
  team_ast: "Assists",
  team_reb: "Rebounds",
  team_stl: "Steals",
  team_blk: "Blocks",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb",
};

const columnDisplayMap = {
  game_date: "DATE",
  versus: "VS",
  team_pts: "POINTS",
  team_reb: "REBOUNDS",
  team_ast: "ASSISTS",
  team_stl: "STEALS",
  team_blk: "BLOCKS",
  team_fgm: "FGM",
  team_fga: "FGA",
  team_fg3m: "FG3M",
  team_fg3a: "FG3A",
  team_ftm: "FTM",
  team_fta: "FTA"
};

const summationFeatures = ["Pts+Ast", "Pts+Reb", "Ast+Reb", "Pts+Ast+Reb"];

const playerStatsColumns = [
  { key: "min", display: "MIN" },
  { key: "pts", display: "PTS" },
  { key: "fgm", display: "FGM" },
  { key: "fga", display: "FGA" },
  { key: "fg_pct", display: "FG%" },
  { key: "fg3m", display: "3PM" },
  { key: "fg3a", display: "3PA" },
  { key: "fg3_pct", display: "3P%" },
  { key: "ftm", display: "FTM" },
  { key: "fta", display: "FTA" },
  { key: "ft_pct", display: "FT%" },
  { key: "oreb", display: "OREB" },
  { key: "dreb", display: "DREB" },
  { key: "reb", display: "REB" },
  { key: "ast", display: "AST" },
  { key: "stl", display: "STL" },
  { key: "blk", display: "BLK" },
  { key: "tov", display: "TOV" },
  { key: "pf", display: "PF" },
  { key: "plus_minus", display: "+/-" },
];

const TeamLogs = ({ selectedFeature, setSelectedFeature, constantLine }) => {
  const { selectedTeam, teamColors } = usePlayerColors();
  const { token } = useKeycloak();  // Get the token from Keycloak
  const [teamLogs, setTeamLogs] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [opponentFilter, setOpponentFilter] = useState("");
  const [homeOrAwayFilter, setHomeOrAwayFilter] = useState("");
  const [expandedGameData, setExpandedGameData] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const fetchTeamLogs = async () => {
      if (selectedTeam && token) {
        setIsLoading(true);
        try {
          const data = await fetchData(
            `teams/filter_by_team/?team_abbreviation=${teamNameToAbbreviation[selectedTeam]}&x=30`,
            token
          );

          const sortedData = data.sort(
            (a, b) => new Date(b.game_date) - new Date(a.game_date)
          );

          setTeamLogs(sortedData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching team logs:", error);
          setIsLoading(false);
        }
      }
    };

    fetchTeamLogs();
  }, [selectedTeam, token]);

  const toggleRow = async (index, game) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    if (!expandedRows[index] && !expandedGameData[game.game_date] && token) {
      try {
        const metrics = playerStatsColumns.map(col => col.key).join('&metrics=');
        const data = await fetchData(
          `teams/filter_by_team_and_date/?team_abbreviation=${teamNameToAbbreviation[selectedTeam]}&game_date=${game.game_date}&metrics=${metrics}`,
          token
        );
        
        setExpandedGameData((prev) => ({
          ...prev,
          [game.game_date]: data,
        }));
      } catch (error) {
        console.error("Error fetching expanded game data:", error);
      }
    }
  };

  const getFeatureValue = (game, feature) => {
    switch (feature) {
      case "Pts+Ast":
        return game.team_pts + game.team_ast;
      case "Pts+Reb":
        return game.team_pts + game.team_reb;
      case "Ast+Reb":
        return game.team_ast + game.team_reb;
      case "Pts+Ast+Reb":
        return game.team_pts + game.team_ast + game.team_reb;
      default:
        return game[feature];
    }
  };

  const formatPlayerName = (fullName) => {
    const names = fullName.split(' ');
    if (names.length > 1) {
      return `${names[0][0]}. ${names.slice(1).join(' ')}`;
    }
    return fullName;
  };

  const formatValue = (value, key) => {
    if (typeof key === 'string' && (key.includes('_pct') || key.includes('fg_pct') || key.includes('fg3_pct') || key.includes('ft_pct'))) {
      return value !== undefined && value !== null ? `${(value * 100).toFixed(1)}%` : '-';
    }
    return value !== undefined && value !== null ? value : '-';
  };

  const getTableHeaders = () => {
    const headers = [
      "team_pts",
      "team_reb",
      "team_ast",
      "team_stl",
      "team_blk",
      "team_fgm",
      "team_fga",
      "team_fg3m",
      "team_fg3a",
      "team_ftm",
      "team_fta",
    ];
    if (summationFeatures.includes(selectedFeature)) {
      return headers.filter((header) => !summationFeatures.includes(header));
    } else {
      return headers.filter((header) => header !== featureMap[selectedFeature]);
    }
  };

  const filteredLogs = teamLogs
    .filter((log) => !opponentFilter || log.opponent === opponentFilter)
    .filter(
      (log) =>
        !homeOrAwayFilter ||
        (homeOrAwayFilter === "1" ? log.home_or_away === "1" : log.home_or_away === "0")
    );

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!isFullScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const sortPlayerStats = useCallback((players, key) => {
    const sortedPlayers = [...players];
    sortedPlayers.sort((a, b) => {
      if (a[key] < b[key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
    return sortedPlayers;
  }, [sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  return (
    <div className={`team-logs-historical-container ${isFullScreen ? "full-screen" : ""}`}>
      <button className="popout-button" onClick={toggleFullScreen}>
        {isFullScreen ? <FaCompress /> : <FaExpand />}
      </button>
      <h3 className="team-logs-historical-title">Team Logs</h3>
      <div className="filters-container">
        <label>
          Opponent:
          <select
            className="filter-select"
            onChange={(e) => setOpponentFilter(e.target.value)}
            value={opponentFilter}
          >
            <option value="">All</option>
            {[...new Set(teamLogs.map((log) => log.opponent))].map(
              (opponent) => (
                <option key={opponent} value={opponent}>
                  {opponent}
                </option>
              )
            )}
          </select>
        </label>
        <label>
          Home/Away:
          <select
            className="filter-select"
            onChange={(e) => setHomeOrAwayFilter(e.target.value)}
            value={homeOrAwayFilter}
          >
            <option value="">All</option>
            <option value="1">Home</option>
            <option value="0">Away</option>
          </select>
        </label>
        <div className="feature-dropdown-container">
          <label htmlFor="feature-select">Select Filter: </label>
          <select
            id="feature-select"
            value={featureReverseMap[selectedFeature]}
            onChange={(e) => setSelectedFeature(e.target.value)}
          >
            {Object.keys(featureMap).map((feature) => (
              <option key={feature} value={feature}>
                {feature}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="team-logs-table-container">
        {isLoading ? (
          <div>Loading...</div>
        ) : filteredLogs.length === 0 ? (
          <div>No data available</div>
        ) : (
          <table className="team-logs-historical-table">
            <thead>
              <tr>
                <th></th>
                <th className="team-logs-historical-header">{columnDisplayMap["game_date"]}</th>
                <th className="team-logs-historical-header">{columnDisplayMap["versus"]}</th>
                {getTableHeaders().map((feature, index) => (
                  <th key={index} className="team-logs-historical-header">
                    {columnDisplayMap[feature] || feature}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLogs.map((game, index) => {
                const isExpanded = expandedRows[index];
                return (
                  <React.Fragment key={index}>
                    <tr className="team-logs-historical-row">
                      <td className="team-logs-historical-cell">
                        <button className="expand-button" onClick={() => toggleRow(index, game)}>
                          {isExpanded ? <FaMinus /> : <FaPlus />}
                        </button>
                      </td>
                      <td className="team-logs-historical-cell">{game.game_date}</td>
                      <td className="team-logs-historical-cell">
                        {game.home_or_away === "1" ? (
                          <>
                            <span>vs</span>
                            <img src={`/team_logos/${abbreviationToTeamName[game.opponent].toLowerCase()}.png`} alt={`${game.opponent} logo`} className="opponent-logo" />
                          </>
                        ) : (
                          <>
                            <span>@</span>
                            <img src={`/team_logos/${abbreviationToTeamName[game.opponent].toLowerCase()}.png`} alt={`${game.opponent} logo`} className="opponent-logo" />
                          </>
                        )}
                      </td>
                      {getTableHeaders().map((feature, index) => {
                        const value = getFeatureValue(game, feature);
                        return (
                          <td
                            key={index}
                            className="team-logs-historical-cell"
                          >
                            {formatValue(value, feature)}
                          </td>
                        );
                      })}
                    </tr>
                    {isExpanded && (
                      <tr className="team-logs-historical-row expanded-row">
                        <td colSpan={getTableHeaders().length + 3}>
                          <div className="expanded-content">
                            <h4>Player Stats</h4>
                            <table className="player-stats-table">
                              <thead>
                                <tr>
                                  <th className="player-name-cell">Player</th>
                                  {playerStatsColumns.map((col) => (
                                    <th 
                                      key={col.key} 
                                      onClick={() => requestSort(col.key)}
                                      className="sortable-header"
                                    >
                                      {col.display} {getSortIcon(col.key)}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {sortPlayerStats(expandedGameData[game.game_date] || [], sortConfig.key).map((player, playerIndex) => (
                                  <tr key={playerIndex}>
                                    <td className="player-name-cell">{formatPlayerName(player.player_name)}</td>
                                    {playerStatsColumns.map((col) => (
                                      <td key={col.key}>{formatValue(player[col.key], col.key)}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                              </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TeamLogs;