import React from 'react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchPositionData from './data/PositionData';
import './PositionDefense.css';

const PositionDefense = ({ selectedFeature, title }) => {
  const positionData = useFetchPositionData(selectedFeature);

  // Function to determine the text color based on rank
  const getColorForRank = (rank) => {
    if (rank >= 1 && rank <= 10) return 'red';
    if (rank >= 11 && rank <= 20) return 'black';
    return 'green';
  };

  // Function to format the rank as an ordinal number (e.g., 1st, 2nd)
  const formatRank = (rank) => {
    const j = rank % 10,
      k = rank % 100;
    if (j === 1 && k !== 11) {
      return rank + 'st';
    }
    if (j === 2 && k !== 12) {
      return rank + 'nd';
    }
    if (j === 3 && k !== 13) {
      return rank + 'rd';
    }
    return rank + 'th';
  };

  // Function to map the selected feature to its display name
  const getDisplayFeature = (feature) => {
    const featureMap = {
      'Total': 'Points',
      'Pts+Ast': 'P+A',
      'Pts+Ast+Reb': 'P+A+R',
      'Pts+Reb': 'P+R',
      // Add more mappings as needed
    };
    return featureMap[feature] || feature;
  };

  

  if (Object.keys(positionData).length === 0) return <div>Loading...</div>;

  const positions = positionData.Positions || [];
  const featureValues = positionData.FeatureValues || [];
  const ranks = positionData.Ranks || [];

  // Define the desired order of positions
  const desiredOrder = ['PG', 'SG', 'SF', 'PF', 'C'];

  // Create a sorted array of position data
  const sortedPositionData = desiredOrder.map(pos => {
    const index = positions.indexOf(pos);
    return {
      position: pos,
      featureValue: featureValues[index],
      rank: ranks[index]
    };
  });

  return (
    <div className="position-versus-stats-container">
      <div className="position-versus-table-header">
        <div className="position-versus-header-title">
          {title || (
            <div style={{ textAlign: 'left', marginLeft: '2px' }}>
              <div>Position</div>
              <div>Defense</div>
            </div>
          )}
        </div>
        <div className="info-button">
          ?
          <div className="info-text">
            This table shows the defense performance against different positions.
            <br />
            <div className="legend-item legend-hard">
              <span className="legend-color">A</span> Hard (Rank 1-10)
            </div>
            <div className="legend-item legend-neutral">
              <span className="legend-color">A</span> Neutral (Rank 11-20)
            </div>
            <div className="legend-item legend-easy">
              <span className="legend-color">A</span> Easy (Rank 21-30)
            </div>
          </div>
        </div>
      </div>
      <table className="position-stats-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>{getDisplayFeature(selectedFeature)}</th>
          </tr>
        </thead>
        <tbody>
          {sortedPositionData.map(({ position, featureValue, rank }, index) => (
            <tr key={index}>
              <td>{position}</td>
              <td>
                <span style={{ color: getColorForRank(rank) }}>
                  {featureValue?.toFixed(1)}
                  <span style={{ fontSize: '11px' }}>({formatRank(rank)})</span>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PositionDefense;