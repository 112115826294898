import React from 'react';
import './SeasonPerformance.css';

const GaugeChart = ({ percentage, label }) => {
  const radius = 50;
  const circumference = Math.PI * radius; 
  const offset = circumference - (percentage / 100) * circumference;

  const gaugeColor = percentage >= 54 ? '#00ff00' : '#ff0000'; 

  return (
    <div className="model-performance-gauge-chart">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 120 60" 
        className="model-performance-gauge-svg"
      >
        <path
          d={`M ${60 - radius}, 60 A ${radius},${radius} 0 1,1 ${60 + radius},60`}
          stroke="#e0e0e0"
          strokeWidth="10"
          fill="none"
          className="model-performance-gauge-bg"
        />
        <path
          d={`M ${60 - radius}, 60 A ${radius},${radius} 0 1,1 ${60 + radius},60`}
          stroke={gaugeColor}
          strokeWidth="10"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          className="model-performance-gauge-fill"
        />
        <text
          x="60"
          y="45"
          textAnchor="middle"
          className="model-performance-gauge-text"
        >
          {percentage}%
        </text>
      </svg>
      <div className="model-performance-gauge-label">{label}</div>
    </div>
  );
};

const SeasonPerformance = ({ aggregates }) => {
  return (
    <div className="model-performance-season-performance-container">
      <GaugeChart percentage={aggregates.modelWinRate} label="Model" />
      <GaugeChart percentage={aggregates.youWinRate} label="You" />
    </div>
  );
};

export default SeasonPerformance;
