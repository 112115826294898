// src/hooks/useAuth.js
import { useKeycloak } from '../KeycloakProvider';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const { keycloak, authenticated } = useKeycloak(); // Extract keycloak and authenticated status from context
  const [username, setUsername] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to ensure Keycloak is initialized before checking authentication

  useEffect(() => {
    if (keycloak && authenticated) {
      // Fetch user information and roles once Keycloak is authenticated
      keycloak.loadUserProfile().then((profile) => {
        setUsername(profile.username || profile.email);
      });

      const userRoles = keycloak.tokenParsed?.realm_access?.roles || [];
      setRoles(userRoles);
    }

    setLoading(false); // End loading once user info is fetched
  }, [keycloak, authenticated]);

  return [
    authenticated,   // Whether the user is authenticated
    keycloak,        // Keycloak instance
    keycloak?.token, // The current token
    username,        // Username or email
    roles,           // User roles from Keycloak
    loading,         // Loading status to avoid rendering until auth info is available
  ];
};

export default useAuth;
