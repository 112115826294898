import React, { useState, useEffect } from 'react';
import { abbreviationToTeamName } from '../../components/TeamDictionary';
import { teamsDict } from '../../components/TeamInfo';
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak context for token
import { fetchData } from '../../api';  // Import your fetchData function
import './TeamVsTeamTable.css';

const TeamVsTeamTable = ({ matchup }) => {
  const { awayTeamAbbr, homeTeamAbbr } = matchup;
  const [awayTeamStats, setAwayTeamStats] = useState(null);
  const [homeTeamStats, setHomeTeamStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useKeycloak();  // Get the token from Keycloak

  const awayTeamFullName = abbreviationToTeamName[awayTeamAbbr];
  const homeTeamFullName = abbreviationToTeamName[homeTeamAbbr];
  
  const homeTeamId = teamsDict[homeTeamFullName].team_id;
  const awayTeamId = teamsDict[awayTeamFullName].team_id;
  const homeTeamColor = teamsDict[homeTeamFullName].colors[0];
  const awayTeamColor = teamsDict[awayTeamFullName].colors[0];

  const statKeys = [
    'standings',
    'offensive_ranking',
    'defensive_ranking',
    'team_pts_season_average',
    'team_pts_allowed_season_average',
    'team_fg3m_season_average',
    'team_tov_season_average',
    'team_ast_season_average',
    'team_reb_season_average',
    'team_stl_season_average',
    'team_blk_season_average',
  ];

  useEffect(() => {
    const fetchTeamStats = async () => {
      if (!token) return;  // Ensure the token is available
      setLoading(true);
      setError(null);
      const queryString = statKeys.map(key => `metrics=${key}`).join('&');

      try {
        const [awayData, homeData] = await Promise.all([
          fetchData(`team_overall_stats/?team_id=${awayTeamId}&${queryString}`, token),
          fetchData(`team_overall_stats/?team_id=${homeTeamId}&${queryString}`, token)
        ]);

        setAwayTeamStats(Array.isArray(awayData) ? awayData[0] : awayData);
        setHomeTeamStats(Array.isArray(homeData) ? homeData[0] : homeData);
      } catch (err) {
        setError('Failed to fetch team stats');
        console.error('Error fetching team stats:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
  }, [awayTeamAbbr, homeTeamAbbr, token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!awayTeamStats || !homeTeamStats) return <div>Error: Team stats not found.</div>;

  const statLabels = [
    'Standings',
    'Offensive Ranking',
    'Defensive Ranking',
    'PPG (Scored)',
    'PPG (Allowed)',
    '3pt Per Game',
    'Turnovers Per Game',
    'Assists Per Game',
    'Rebounds Per Game',
    'Steals Per Game',
    'Blocks Per Game',
  ];

  const compareStats = (homeStat, awayStat, key) => {
    if (['standings', 'offensive_ranking', 'defensive_ranking', 'team_pts_allowed_season_average', 'team_tov_season_average'].includes(key)) {
      return parseFloat(homeStat) < parseFloat(awayStat) ? 'home' : 'away';
    }
    return parseFloat(homeStat) > parseFloat(awayStat) ? 'home' : 'away';
  };

  const getStatStyle = (homeStat, awayStat, key) => {
    const winner = compareStats(homeStat, awayStat, key);
    return {
      home: {
        color: winner === 'home' ? homeTeamColor : 'inherit',
        fontWeight: winner === 'home' ? 600 : 'normal',
      },
      away: {
        color: winner === 'away' ? awayTeamColor : 'inherit',
        fontWeight: winner === 'away' ? 600 : 'normal',
      },
    };
  };

  const getOrdinalSuffix = (number) => {
    const n = parseInt(number);
    if (isNaN(n)) return number;
    const lastDigit = n % 10;
    const lastTwoDigits = n % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${n}th`;
    }
    switch (lastDigit) {
      case 1: return `${n}st`;
      case 2: return `${n}nd`;
      case 3: return `${n}rd`;
      default: return `${n}th`;
    }
  };

  const formatStat = (stat, key) => {
    if (['standings', 'offensive_ranking', 'defensive_ranking'].includes(key)) {
      return getOrdinalSuffix(stat);
    }
    return stat;
  };

  return (
    <div className="team-vs-team-table-container">
      <h2 className="team-vs-team-table-title">Matchup Comparison</h2>
      <table className="team-vs-team-table">
        <thead>
          <tr>
            <th></th>
            <th className="team-vs-team-table-header">
              <div className="team-vs-team-table-header-team-wrapper">
                <span className="team-vs-team-table-header-team">{homeTeamAbbr}</span>
                <img
                  src={`/team_logos/${homeTeamFullName.toLowerCase()}.png`}
                  className="team-vs-team-table-team-logo"
                  alt={`${homeTeamFullName} logo`}
                />
              </div>
            </th>
            <th className="team-vs-team-table-header">
              <div className="team-vs-team-table-header-team-wrapper">
                <span className="team-vs-team-table-header-team">{awayTeamAbbr}</span>
                <img
                  src={`/team_logos/${awayTeamFullName.toLowerCase()}.png`}
                  className="team-vs-team-table-team-logo"
                  alt={`${awayTeamFullName} logo`}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {statLabels.map((label, index) => {
            const key = statKeys[index];
            const homeStat = homeTeamStats[key];
            const awayStat = awayTeamStats[key];
            const statStyle = getStatStyle(homeStat, awayStat, key);

            return (
              <tr key={index}>
                <td>{label}</td>
                <td style={statStyle.home}>{formatStat(homeStat, key)}</td>
                <td style={statStyle.away}>{formatStat(awayStat, key)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TeamVsTeamTable;
