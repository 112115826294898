import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './BettingTracker.css';
import fakeBetData from './data/fakeBetData'; // Import your fakeBetData

const BettingTracker = ({ filters }) => {
  // Map to shorten summation feature names
  const propNameMap = {
    'Pts+Ast': 'P+A',
    'Pts+Reb': 'P+R',
    'Ast+Reb': 'A+R',
    'Pts+Reb+Ast': 'P+R+A',
  };

  // Filter the fakeBetData based on filters, date range, and view
  const filteredData = useMemo(() => {
    return fakeBetData.filter((bet) => {
      const betDate = new Date(bet.date);
      const startDate = filters.dateRange.startDate ? new Date(filters.dateRange.startDate) : new Date('2024-03-01');
      const endDate = filters.dateRange.endDate ? new Date(filters.dateRange.endDate) : new Date('2024-08-31');
      const isWithinDateRange = betDate >= startDate && betDate <= endDate;

      const matchesSportsbook = !filters.sportsbook || bet.sportsbook === filters.sportsbook;
      const matchesTeam = !filters.team || bet.team === filters.team;
      const matchesView = filters.view === 'both' || bet.bet_type === filters.view;

      return isWithinDateRange && matchesSportsbook && matchesTeam && matchesView;
    });
  }, [filters]);

  return (
    <div className="model-performance-betting-tracker">
      <h2>Betting Tracker</h2>
      <div className="model-performance-betting-header">
        <div className="left-aligned">Player</div>
        <div className="left-aligned">Prop</div>
        <div className="left-aligned">Line</div>
        <div className="left-aligned">Your Pick</div>
        <div className="left-aligned">Model Pick</div>
        <div className="left-aligned">Result</div>
      </div>
      <div className="model-performance-betting-records">
        {filteredData.map((bet, index) => {
          const propName = propNameMap[bet.prop_type] || bet.prop_type;
          const resultDirection = bet.result_direction;

          // Conditional class assignment for correct/incorrect picks
          const yourPickClass = bet.bet_direction === resultDirection ? 'green-text' : 'red-text';
          const modelPickClass = bet.bet_direction === resultDirection ? 'green-text' : 'red-text';

          return (
            <div key={index} className="model-performance-betting-record">
              <span>{bet.player}</span>
              <span>{propName}</span>
              <span>{bet.line}</span>
              <span className={`model-performance-${yourPickClass}`}>
                {bet.bet_direction === 'under' ? 'Under' : 'Over'}
              </span>
              <span className={`model-performance-${modelPickClass}`}>
                {bet.bet_direction === 'over' ? 'Over' : 'Under'}
              </span>
              <span className="model-performance-green-text">
                {resultDirection === 'under' ? 'Under' : 'Over'}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BettingTracker;
