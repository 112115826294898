import React, { useEffect, useRef } from 'react';

const LoginPage = () => {
  const isRun = useRef(false);

  useEffect(() => {
    if (isRun.current) return;
    isRun.current = true;
  }, []);

  return (
    <div>
      <h1>Login Page</h1>
    </div>
  );
};

export default LoginPage;
