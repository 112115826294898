import React from 'react';
import TicketViewer from '../historical_tickets_components/TicketViewer';
import { useKeycloak } from '../KeycloakProvider';

const TicketsPage = () => {
  const { token, keycloakId } = useKeycloak();

  return (
    <div>
      <h1>My Tickets</h1>
      <TicketViewer token={token} keycloakId={keycloakId} /> {/* Pass keycloakId correctly */}
    </div>
  );
};

export default TicketsPage;
